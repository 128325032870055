import {useEffect, useState} from 'react';
import {apiAdminGetFundraisers} from '../../../api.ts';
import {FundraiserWithOrder, IFundraiser, IOrder} from '../../../types/FundraiserTypes.ts';
import {Alert, Badge, Button, Card, Table} from 'react-bootstrap';
import moment from 'moment';
import {useNavigate} from 'react-router-dom';
import {useTitle} from '../../../hooks/useTitle.ts';
import {LoadingComponent} from '../../../components/util/LoadingComponent.tsx';
import {AdminFundraiserInfoModal} from '../../../components/admin/fundraiser/AdminFundraiserInfoModal.tsx';
import {ExportSettingsModalComponent} from '../../../components/fundraiser/manage/ExportSettingsModalComponent.tsx';
import Form from 'react-bootstrap/Form';

export function AdminManageFundraisers() {
	const [loading, setLoading] = useState(true);
	const [fundraisers, setFundraisers] = useState<FundraiserWithOrder[]>([]);
	const [fundraiserInfo, setFundraiserInfo] = useState<FundraiserWithOrder>();
	const [reportFundraiser, setReportFundraiser] = useState<FundraiserWithOrder>();
	const [showCancelled, setShowCancelled] = useState(false);

	const navigate = useNavigate();
	useTitle('Manage Fundraisers');

	useEffect(() => {
		apiAdminGetFundraisers().then(response => {
			setFundraisers(response.fundraisers);
		}).finally(() => {
			setLoading(false);
		});
	}, []);

	function calculateValue(fundraiser: IFundraiser, orders: IOrder[]) {
		let value = 0;
		for (let order of orders) {
			if (order.cancelled) {
				continue;
			}
			
			for (let item of order.items) {
				const catalogItem = fundraiser.catalogItems.find(x => x._id === item.itemId);
				value += item.amount * (catalogItem?.price ?? 0)
			}
		}

		return value;
	}

	if (loading) {
		return <LoadingComponent/>
	}

	function manage(id: string) {
		navigate(id);
	}

	function viewInfo(fundraiser: FundraiserWithOrder) {
		setFundraiserInfo(fundraiser);
	}

	const fundraisersYetToStart = fundraisers.filter(x => !x.cancelled && x.startDate > new Date());
	const fundraisersAcceptingOrders = fundraisers.filter(x => !x.cancelled && x.endDate > new Date() && !fundraisersYetToStart.includes(x));
	const fundraisersAwaitingDeliveries = fundraisers.filter(x => !x.cancelled && !fundraisersAcceptingOrders.includes(x) && !fundraisersYetToStart.includes(x));

	function generateStatusBadge(fundraiser: FundraiserWithOrder) {
		if (fundraiser.cancelled) {
			return <Badge bg='dark'>Cancelled</Badge>
		}
		
		if (fundraisersYetToStart.includes(fundraiser)) {
			return <Badge bg='primary'>Upcoming</Badge>
		} else if (fundraisersAcceptingOrders.includes(fundraiser)) {
			return <Badge bg='success'>In Progress</Badge>
		} else {
			const timeSinceClose = Date.now() - fundraiser.endDate.getTime();

			if (timeSinceClose < 8.64e+7) { // less than 1 day
				return <Badge bg='warning'>Confirming</Badge>
			}
			if (fundraiser.deliveryDate > new Date()) {
				return <Badge bg='secondary'>Awaiting Delivery</Badge>
			} else {
				return <Badge bg='dark'>Closed</Badge>
			}
		}
	}
	
	function runReportsOn(fundraiser: FundraiserWithOrder) {
		setReportFundraiser(fundraiser);
	}
	
	const filtered = fundraisers.filter(x => (x.cancelled && showCancelled) || !x.cancelled).sort((a: any, b: any) => b.endDate - a.endDate);
	
	return (
		<>
			<div className='r-header'>
				<h3>Ongoing Fundraisers</h3>
				<Alert variant='info'>💰 <strong>{fundraisersYetToStart.length} fundraisers</strong> yet to
					start, <strong>{fundraisersAcceptingOrders.length} fundraisers</strong> accepting
					orders, <strong>{fundraisersAwaitingDeliveries.length} closed</strong> awaiting delivery.
				</Alert>
			</div>
			<Card className='p-4'>
				<Form.Check id='includeCancelled' label='Show Cancelled' checked={showCancelled} onChange={x => setShowCancelled(x.target.checked)} />
				<Table>
					<thead>
					<tr>
						<th>Status</th>
						<th>Fundraiser Name</th>
						<th>Order Count</th>
						<th>Order Value</th>
						<th>Fundraiser Earnings</th>
						<th>Start Date</th>
						<th>Close Date</th>
						<th>Delivery Date</th>
						<th colSpan={3} style={{width: '1%'}}>Actions</th>
					</tr>
					</thead>
					<tbody>
					{filtered.map((fundraiser) => <tr key={fundraiser._id}>
						<td>
							{generateStatusBadge(fundraiser)}
						</td>
						<td>{fundraiser.name}</td>
						<td>{fundraiser.orders.filter(x => !x.cancelled).length}</td>
						<td>${calculateValue(fundraiser, fundraiser.orders).toFixed(2)}</td>
						<td>
							${(calculateValue(fundraiser, fundraiser.orders) * fundraiser.commission).toFixed(2)}&emsp;&emsp;
							({(fundraiser.commission * 100).toFixed(0)}%)
						</td>
						<td style={{textDecoration: fundraisersYetToStart.includes(fundraiser) ? '' : 'line-through'}}>{moment(fundraiser.startDate).format('DD/MM/YYYY')}</td>
						<td style={{textDecoration: fundraisersYetToStart.includes(fundraiser) || fundraisersAcceptingOrders.includes(fundraiser) ? '' : 'line-through'}}>{moment(fundraiser.endDate).format('DD/MM/YYYY')}</td>
						<td style={{textDecoration: fundraisersYetToStart.includes(fundraiser) || fundraisersAcceptingOrders.includes(fundraiser) || fundraisersAwaitingDeliveries.includes(fundraiser) ? '' : 'line-through'}}>{moment(fundraiser.deliveryDate).format('DD/MM/YYYY')}</td>
						<td>
							<Button variant='dark' size='sm' onClick={() => viewInfo(fundraiser)}>Info</Button>
						</td>
						<td>
							<Button size='sm' onClick={() => manage(fundraiser._id)}>Manage</Button>
						</td>
						<td>
							<Button size='sm' onClick={() => runReportsOn(fundraiser)}>Reports</Button>
						</td>
					</tr>)}
					</tbody>
				</Table>
			</Card>
			{ fundraiserInfo ? <AdminFundraiserInfoModal fundraiser={fundraiserInfo} close={() => setFundraiserInfo(undefined)} /> : null }
			{ reportFundraiser ? <ExportSettingsModalComponent open={true} close={() => setReportFundraiser(undefined)} fundraiser={reportFundraiser} /> : null }
		</>
	)
}