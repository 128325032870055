import {IError} from '../types/ApiTypes.ts';

export function parseApiErrors(errors?: IError[]): { [key: string]: string | any[] } {
    const parsed: { [key: string]: string | any[] } = {}; 
    if (errors) {
        for (let error of errors) {
            if (error.path.includes('[')) {
                const s = error.path.split('.');
                const key = s[0].split('[')[0];
                if (!errors.hasOwnProperty(key)) {
                    parsed[key] = [];
                }
                
                (parsed[key] as Array<any>).push({ [s[1]]: error.msg });
            } else {
                parsed[error.path] = error.msg;
            }
        }
    }
    
    return parsed;
}