import {useEffect, useState} from "react";
import {IFundraiser, IOrder} from "../../../types/FundraiserTypes.ts";
import {apiAdminGetManageFundraiserInfo, apiGetCatalogs} from '../../../api.ts';
import {Navigate, useParams} from "react-router-dom";
import {ManageFundraiserComponent} from "../../../components/fundraiser/manage/ManageFundraiserComponent.tsx";
import {toast} from "react-toastify";
import {useTitle} from "../../../hooks/useTitle.ts";
import {LoadingComponent} from "../../../components/util/LoadingComponent.tsx";
import {ICatalog} from '../../../types/CatalogTypes.ts';

export function AdminManageFundraiser() {
	const [ fundraiser, setFundraiser ] = useState<IFundraiser & { orders: IOrder[] }>();
	const [ catalog, setCatalog ] = useState<ICatalog>();
	const [ loading, setLoading ] = useState(true);
	const { id } = useParams();
	useTitle('Manage Fundraiser');
	
	useEffect(() => {
		setLoading(true);
		
		apiAdminGetManageFundraiserInfo(id).then(response => {
			if (!response.success) {
				toast.error('Could not find fundraiser');
			} else {
				setFundraiser(response.data);
				
				apiGetCatalogs().then(catalogsResponse => {
					setCatalog(catalogsResponse.catalogs.find(x => x._id === response.data.catalog))
				})
				.finally(() => {
					setLoading(false);
				});
			}
		})
	}, []);

	if (loading) {
		return <LoadingComponent />
	}

	if (!fundraiser) {
		return <Navigate to='/admin/fundraisers' />;
		// return 'no fundraiser'
	}

	return <ManageFundraiserComponent fundraiser={fundraiser} catalog={catalog} admin={true} />
}