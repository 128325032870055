import {Outlet, useNavigate} from 'react-router-dom';
import AuthenticationService from '../../services/AuthenticationService.ts';
import {useEffect, useState} from 'react';
import {AdminNavigationComponent} from "../../components/admin/AdminNavigationComponent.tsx";

export function AdminAuthCheck() {
	const navigate = useNavigate();
	const [ready, setReady] = useState(false);
	
	useEffect(() => {
		if (!AuthenticationService.hasValidAdminToken()) {
			navigate('/admin/login');
			return;
		}
		
		setReady(true);
	}, []);
	
	return ready ? <div className='main-wrapper'><AdminNavigationComponent><Outlet /></AdminNavigationComponent></div> : null;
}