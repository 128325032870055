import {useNavigate, useParams} from 'react-router-dom';
import {IFundraiser} from "../types/FundraiserTypes.ts";
import {useEffect, useState} from 'react';
import {FundraiserOrderComponent} from "../components/fundraiser/FundraiserOrderComponent.tsx";
import {LoadingComponent} from "../components/util/LoadingComponent.tsx";
import {apiGetFundraiser} from '../api.ts';

export function Fundraiser() {
	const navigate = useNavigate();
	const { slug, demo: demoString } = useParams();
	
	const [ loading, setLoading ] = useState(true);
	const [ fundraiser, setFundraiser ] = useState<IFundraiser>();
	
	useEffect(() => {
		if (!slug) {
			return;
		}
		apiGetFundraiser(slug).then(response => {
			if (response.success) {
				setFundraiser(response.data);
				setLoading(false);
			} else {
				navigate('/'); // maybe a 404 page
			}
		})
	}, [slug]);

	const demo = demoString === 'demo';
	
	if (loading) {
		return <LoadingComponent />
	}
	
	return (
		<>
			{ demo ? <>
				<div className='sticky-top bg-warning-subtle text-center p-4 fw-bold mb-4'>Demo Mode - Your order will not be saved</div>
			</>: null }

			<FundraiserOrderComponent fundraiser={fundraiser!} demo={demo} />
		</>
	)
}