import {FieldArray, Formik, FormikHelpers, FormikProps} from 'formik';
import Form from 'react-bootstrap/Form';
import {Button, Col, FloatingLabel, Row} from 'react-bootstrap';
import * as yup from 'yup';
import {ArrayHelpers} from 'formik/dist/FieldArray';
import {ICatalog} from '../../types/CatalogTypes.ts';
import {forwardRef, useContext, useEffect, useImperativeHandle, useRef} from 'react';
import {ICreationStepRef} from './CreationOverviewComponent.tsx';
import {IStringsContext, StringsContext} from '../../contexts/StringsContext.tsx';
import {Tooltip} from '../util/Tooltip.tsx';
import {IPickup} from '../../types/FundraiserTypes.ts';
import {PickupLocationBuilderComponent} from './PickupLocationBuilderComponent.tsx';
import moment from 'moment';

interface IForm {
	catalog: string;
	pickups: IPickup[];
	cashPayment: boolean;
	transferPayment: boolean;
	cashPaymentNotes: string;
	transferPaymentNotes: string;
	transferPaymentAccount: string;
}

interface IFormTypes extends IForm {
	[key: string]: any;
}

interface IProps {
	catalogs: ICatalog[];
	values?: IFormTypes;
	update?: boolean;
	onStepComplete: (values: { [key: string]: any}) => any;
	onPrevious?: (values: { [key: string]: any}) => any;
	isAdmin?: boolean;
	deliveryDate: string;
}
export const CreationStep2Component = forwardRef<ICreationStepRef, IProps>(({ update, catalogs, values, onStepComplete, onPrevious, isAdmin, deliveryDate }, ref) => {
	const formRef = useRef<FormikProps<IForm>>(null);
	useImperativeHandle(ref, () => ({
		onUpdateClick() {
			formRef.current?.submitForm();
		}
	}));

	const {getString} = useContext<IStringsContext>(StringsContext);
	
	const initialValues: IForm = values ?? {
		catalog: '',
		pickups: [{
			_id: '',
			location: '',
			startTime: moment(deliveryDate).add(1, 'day').hour(12).minutes(0).toDate(),
			endTime: moment(deliveryDate).add(1, 'day').hour(14).minutes(0).toDate()
		}],
		cashPayment: true,
		transferPayment: true,
		cashPaymentNotes: '',
		transferPaymentNotes: '',
		transferPaymentAccount: ''
	};
	
	useEffect(() => {
		const values = formRef.current?.values;
		if (!values || !values.pickups || values.pickups[0].location) {
			return;
		}

		formRef.current.setFieldValue('pickups[0].startTime', moment(deliveryDate).add(1, 'day').hour(12).minutes(0).toDate())
		formRef.current.setFieldValue('pickups[0].endTime', moment(deliveryDate).add(1, 'day').hour(14).minutes(0).toDate())
	}, [deliveryDate]);

	function submit(values: IForm, {}: FormikHelpers<IForm>) {
		onStepComplete(values);
	}
	
	function onPreviousClick() {
		if (!onPrevious) {
			return;
		}
		
		const values = formRef.current?.values;
		onPrevious(values ?? {});
	}
	
	function onBankTransferChange(_: boolean) {
		formRef.current?.setFieldValue('transferPaymentAccount', '');
	}

	const validation = yup.object().shape({
		// catalog: yup.string().oneOf(catalogs.map(x => x._id), 'You must select a location from the list').required('You must provide your location'),
		// pickups: yup.array().of(yup.string().required('Your pickup location cannot be empty').min(5, 'Pickup locations must be 5 characters long')).min(1, 'At least one pickup location is required').required('A pickup location is required'),
		pickups: yup.array().of(yup.object({
			location: yup.string().required('A pickup location is required'),
			startTime: yup.date().required('A start time is required').test('DeliveryDateCheck', 'Pickup start date cannot be before the delivery date', value => {
				return value > new Date(deliveryDate)
			}),
			endTime: yup.date().required('An end time is required').when('startTime', ([startDate], yup) => !startDate ? yup : yup.min(startDate, 'End time cannot be before start time')),
		})),
		cashPayment: yup.boolean(),
		transferPayment: yup.boolean(),
		cashPaymentNotes: yup.string().default(''),
		transferPaymentNotes: yup.string().default(''),
		transferPaymentAccount: yup.string().matches(/^\d{2}-\d{4}-\d{7}-\d{2}$/, 'Enter a valid account number').when('transferPayment', ([transferPayment], yup) => transferPayment ? yup.required('An account number is required') : yup)
	});

	return (
		<Formik<IForm> initialValues={initialValues} onSubmit={submit} validationSchema={validation} innerRef={formRef}>
			{({ handleSubmit, handleChange, values, touched, errors }) =>
				<Form noValidate onSubmit={handleSubmit}>
					<p>
						Once you have set up your fundraiser, you will be able to send an email to your customers. This email will include information like where your customers will pick up their order from and how they pay. Please fill in the information below with this detail.
					</p>

					{ isAdmin ? <Form.Group className='pb-3'>
						<Tooltip text={getString('tooltip.fundraiser.catalog')}>
							<FloatingLabel label='Catalog'>
								<Form.Select value={values.catalog} name='catalog' id='catalog' required onChange={handleChange} isInvalid={touched.catalog && !!errors.catalog}>
									<option value=''>Select your location</option>
									{ catalogs.map(catalog => <option value={catalog._id} key={catalog._id}>{catalog.name}</option>) }
								</Form.Select>
								<Form.Control.Feedback type='invalid'>{errors.catalog}</Form.Control.Feedback>
							</FloatingLabel>
						</Tooltip>
					</Form.Group> : null }

					<div className='pt-4'>
							<FieldArray name='pickups' render={(helpers: ArrayHelpers) => <Form.Group>
								<Form.Label>Pickup Location</Form.Label>
								{ values.pickups.map((pickup, index) => 
									<PickupLocationBuilderComponent key={index} index={index} value={pickup} onChange={handleChange} errors={errors} touched={touched} remove={() => helpers.remove(index)} deliveryDate={deliveryDate} />
								)} 
								{/*<Button variant='success' className='w-100 mt-2' onClick={() => helpers.push('')}>+ Add another pickup location</Button>*/}
								<div className="mt-2">
									<Form.Text>
										The pickup location will allow your customer to be informed during their ordering process when and where they will need to pick up their order.
									</Form.Text>
								</div>
							</Form.Group> } />
					</div>

					<Form.Group className='pt-4 mt-4'>
						<h4>Payment Methods</h4>
						<Row className="mt-3">
							<Tooltip text={getString('tooltip.fundraiser.cashPayment')}>
								<Col>
									<Form.Check id='cashPayment' name='cashPayment' label='Cash Payments' checked={values.cashPayment} onChange={handleChange} feedback={errors.cashPayment} feedbackType='invalid' isInvalid={touched.cashPayment && !!errors.cashPayment} />
								</Col>
							</Tooltip>
							<Tooltip text={getString('tooltip.fundraiser.transferPayment')}>
								<Col>
									<Form.Check id='transferPayment' name='transferPayment' label='Bank Transfer Payments' checked={values.transferPayment} onChange={x => { handleChange(x); onBankTransferChange(x.target.checked) }} feedback={errors.transferPayment} feedbackType='invalid' isInvalid={touched.transferPayment && !!errors.transferPayment} />
								</Col>
							</Tooltip>
						</Row>
						<Form.Text className='d-block pb-2'>Specify how you want to have your orders paid for. This will be shown when an order is being placed.</Form.Text>

						{ values.transferPayment ? <>
							<Form.Group className='mt-2'>
								<Tooltip text={getString('tooltip.fundraiser.transferAccount')}>
									<FloatingLabel label='Bank Transfer Account Number'>
										<Form.Control name='transferPaymentAccount' placeholder='Bank Transfer Account Number' value={values.transferPaymentAccount} onChange={handleChange} isInvalid={touched.transferPaymentAccount && !!errors.transferPaymentAccount} />
										<Form.Control.Feedback type='invalid'>{errors.transferPaymentAccount}</Form.Control.Feedback>
									</FloatingLabel>
								</Tooltip>
							</Form.Group>
							<Form.Text>Enter the account number that orders will need to pay into in the format ##-####-#######-##.</Form.Text>
							
							<Form.Group className='mt-2'>
								<Tooltip text={getString('tooltip.fundraiser.transferNotes')}>
									<FloatingLabel label='Bank Transfer Payment Information'>
										<Form.Control as='textarea' name='transferPaymentNotes' placeholder='Bank Transfer Payment Information' id='transferPaymentNotes' value={values.transferPaymentNotes} disabled={!values.transferPayment} onChange={handleChange} isInvalid={touched.transferPaymentNotes && !!errors.transferPaymentNotes} style={{ height: 100 }} />
										<Form.Control.Feedback type='invalid'>{errors.transferPaymentNotes}</Form.Control.Feedback>
									</FloatingLabel>
								</Tooltip>
							</Form.Group>
						</>: null }

						{ values.cashPayment ? <Form.Group className='mt-2'>
							<Tooltip text={getString('tooltip.fundraiser.cashNotes')}>
								<FloatingLabel label='Cash Payment Information'>
									<Form.Control as='textarea' name='cashPaymentNotes' id='cashPaymentNotes' placeholder='Cash Payment Information' disabled={!values.cashPayment} value={values.cashPaymentNotes} onChange={handleChange} isInvalid={touched.cashPaymentNotes && !!errors.cashPaymentNotes} style={{ height: 100 }} />
									<Form.Control.Feedback type='invalid'>{errors.cashPaymentNotes}</Form.Control.Feedback>
								</FloatingLabel>
							</Tooltip>
						</Form.Group> : null }
						<div className="mt-2 mb-2">
							<Form.Text>Provide any information regarding payment that will be shown when creating an order.</Form.Text>
						</div>
					</Form.Group>

					{ !update ? <div className='d-flex flex-row justify-content-between'>
						<Button variant='warning' onClick={onPreviousClick}>Previous</Button>
						<Button type='submit'>Next</Button>
					</div> : null }
				</Form>
			}
		</Formik>
	)
})