import axios from 'axios';
import AuthenticationService, { ITokenData } from './services/AuthenticationService.ts';
import { ICatalog } from './types/CatalogTypes.ts';
import {
    IAdminOverviewData,
    IApiError,
    IApiGenericSuccess,
    IApiSuccess,
    IMapKitPlace,
    IMapKitSearchResult,
    IQuantitiesReport,
    IString,
    type IUserList
} from './types/ApiTypes.ts';
import { IFundraiser, IFundraiserRequest, IOrder } from './types/FundraiserTypes.ts';

export const apiUrl = import.meta.env.DEV ? 'http://localhost:8080/' : 'https://fundraiseapi.pasta.co.nz/';
const api = axios.create({
    baseURL: apiUrl
});

const isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?$/;

function isIsoDateString(value: any): boolean {
    return value && typeof value === 'string' && isoDateFormat.test(value);
}

export function handleDates(body: any) {
    if (body === null || body === undefined || typeof body !== 'object') return body;

    for (const key of Object.keys(body)) {
        const value = body[key];
        if (isIsoDateString(value)) body[key] = new Date(value);
        else if (value instanceof Array) {
            for (let i = 0; i < value.length; i++) {
                handleDates(value[i]);
            }
        } else if (typeof value === 'object') handleDates(value);
    }
}

api.interceptors.response.use((res) => {
    handleDates(res.data);
    return res;
});

export async function apiCreateFundraisingRequest(values: object): Promise<IApiSuccess | IApiError> {
    const response = await api.post('/fundraiser/create-request', values);
    return response.data;
}

export async function apiAdminLoginAttempt(values: object): Promise<IApiGenericSuccess<ITokenData> | IApiError> {
    const response = await api.postForm('/admin/login', values);
    return response.data;
}

export async function apiAdminGetOverview(): Promise<IAdminOverviewData> {
    const response = await api.get('/admin/overview', {
        headers: {
            'x-access-token': AuthenticationService.getAdminToken()
        }
    });

    return response.data;
}

export async function apiAdminGetFundraisingRequests(): Promise<{ requests: IFundraiserRequest[] }> {
    const response = await api.get('/admin/fundraising-requests', {
        headers: {
            'x-access-token': AuthenticationService.getAdminToken()
        }
    });

    return response.data;
}

export async function apiAdminGetCatalog(id: string): Promise<IApiGenericSuccess<ICatalog> | IApiError> {
    const response = await api.get(`/admin/catalogs/${id}`, {
        headers: {
            'x-access-token': AuthenticationService.getAdminToken()
        }
    });

    return response.data;
}

export async function apiAdminCreateCatalog(values: object): Promise<IApiSuccess | IApiError> {
    const response = await api.put(`/admin/catalogs/create`, values, {
        headers: {
            'x-access-token': AuthenticationService.getAdminToken()
        }
    });

    return response.data;
}

export async function apiAdminUpdateCatalog(values: object): Promise<IApiSuccess | IApiError> {
    const response = await api.patch(`/admin/catalogs`, values, {
        headers: {
            'x-access-token': AuthenticationService.getAdminToken()
        }
    });

    return response.data;
}

export async function apiAdminDeleteCatalog(id: string): Promise<IApiSuccess | IApiError> {
    const response = await api.post(
        `/admin/catalogs/delete`,
        {
            id
        },
        {
            headers: {
                'x-access-token': AuthenticationService.getAdminToken()
            }
        }
    );

    return response.data;
}

export async function apiAdminDuplicateCatalog(id: string): Promise<IApiGenericSuccess<ICatalog> | IApiError> {
    const response = await api.post(
        `/admin/catalogs/duplicate`,
        {
            id
        },
        {
            headers: {
                'x-access-token': AuthenticationService.getAdminToken()
            }
        }
    );

    return response.data;
}

export async function apiAdminGetRequests() {
    const response = await api.get('/admin/fundraising-requests', {
        headers: {
            'x-access-token': AuthenticationService.getAdminToken()
        }
    });

    return response.data;
}

export async function apiAdminGetRequest(id: string): Promise<IApiGenericSuccess<IFundraiserRequest> | IApiError> {
    const response = await api.get(`/admin/fundraising-requests/${id}`, {
        headers: {
            'x-access-token': AuthenticationService.getAdminToken()
        }
    });

    return response.data;
}

export async function apiAdminApproveRequest(values: object): Promise<IApiSuccess | IApiError> {
    const response = await api.post('/admin/accept-fundraiser-request', values, {
        headers: {
            'x-access-token': AuthenticationService.getAdminToken()
        }
    });

    return response.data;
}

export async function apiAdminDeclineRequest(id: string): Promise<IApiSuccess | IApiError> {
    const response = await api.post(
        '/admin/decline-fundraiser-request',
        {
            id
        },
        {
            headers: {
                'x-access-token': AuthenticationService.getAdminToken()
            }
        }
    );

    return response.data;
}

export async function apiAdminDeleteRequest(id: string): Promise<IApiSuccess | IApiError> {
    const response = await api.post(
        '/admin/delete-fundraiser-request',
        {
            id
        },
        {
            headers: {
                'x-access-token': AuthenticationService.getAdminToken()
            }
        }
    );

    return response.data;
}

export async function apiGetAddressSearchResults(
    search: string
): Promise<IApiGenericSuccess<IMapKitSearchResult[]> | IApiError> {
    const response = await api.get(`/address?q=${encodeURIComponent(search)}`);

    return response.data;
}

export async function apiGetFullSearchedAddress(url: string): Promise<IApiGenericSuccess<IMapKitPlace> | IApiError> {
    const response = await api.get(`/lookup-address?q=${encodeURIComponent(url)}`);

    return response.data;
}

export async function apiGetCatalogs(): Promise<{ catalogs: ICatalog[] }> {
    const response = await api.get('/catalogs');

    return response.data;
}

export async function apiFundraiserLoginAttempt(values: object): Promise<IApiGenericSuccess<ITokenData> | IApiError> {
    const response = await api.postForm('/manage-fundraiser/login', values);
    return response.data;
}

export async function apiGetManageFundraiserInfo(): Promise<IFundraiser & { orders: IOrder[] }> {
    const response = await api.get('/manage-fundraiser/info', {
        headers: {
            'x-access-token': AuthenticationService.getFundraiserToken()
        }
    });

    return response.data;
}

export async function apiUpdateFundraiserName(name: string): Promise<IApiSuccess | IApiError> {
    const response = await api.post(
        '/manage-fundraiser/change-name',
        {
            name
        },
        {
            headers: {
                'x-access-token': AuthenticationService.getFundraiserToken()
            }
        }
    );

    return response.data;
}

export async function apiUpdateFundraiserPickup(values: any): Promise<IApiSuccess | IApiError> {
    const response = await api.post('/manage-fundraiser/change-pickup', values, {
        headers: {
            'x-access-token': AuthenticationService.getFundraiserToken()
        }
    });

    return response.data;
}

export async function apiUpdateFundraiserEmail(email: string): Promise<IApiSuccess | IApiError> {
    const response = await api.post(
        '/manage-fundraiser/change-email',
        {
            email
        },
        {
            headers: {
                'x-access-token': AuthenticationService.getFundraiserToken()
            }
        }
    );

    return response.data;
}

export async function apiAdminUpdateFundraiserName(values: any): Promise<IApiSuccess | IApiError> {
    const response = await api.post('/admin/manage-fundraiser/change-name', values, {
        headers: {
            'x-access-token': AuthenticationService.getAdminToken()
        }
    });

    return response.data;
}

export async function apiAdminUpdateFundraiserPickup(values: any): Promise<IApiSuccess | IApiError> {
    const response = await api.post('/admin/manage-fundraiser/change-pickup', values, {
        headers: {
            'x-access-token': AuthenticationService.getAdminToken()
        }
    });

    return response.data;
}

export async function apiAdminUpdateFundraiserEmail(values: any): Promise<IApiSuccess | IApiError> {
    const response = await api.post('/admin/manage-fundraiser/change-email', values, {
        headers: {
            'x-access-token': AuthenticationService.getAdminToken()
        }
    });

    return response.data;
}

export async function apiGetFundraiser(slug: string): Promise<IApiGenericSuccess<IFundraiser> | IApiError> {
    const response = await api.get(`/fundraiser/info/${slug}`);

    return response.data;
}

export async function apiAdminUploadFile(file: File, type: string): Promise<IApiGenericSuccess<string> | IApiError> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('type', type);

    const response = await api.post('/admin/file-upload', formData, {
        headers: {
            'x-access-token': AuthenticationService.getAdminToken()
        }
    });

    return response.data;
}

export async function apiCreateOrder(values: object, demo?: boolean): Promise<IApiGenericSuccess<any> | IApiError> {
    const response = await api.post('/fundraiser/order', { ...values, demo: demo });

    return response.data;
}

export async function apiUpdateFundraiserOrder(values: object): Promise<IApiSuccess | IApiError> {
    const response = await api.post('/manage-fundraiser/update-order', values, {
        headers: {
            'x-access-token': AuthenticationService.getFundraiserToken()
        }
    });

    return response.data;
}

export async function apiAdminUpdateFundraiserOrder(values: object): Promise<IApiSuccess | IApiError> {
    const response = await api.post('/admin/update-order', values, {
        headers: {
            'x-access-token': AuthenticationService.getAdminToken()
        }
    });

    return response.data;
}

export async function apiCancelFundraiserOrder(orderId: string): Promise<IApiSuccess | IApiError> {
    const response = await api.post(
        '/manage-fundraiser/cancel-order',
        {
            orderId
        },
        {
            headers: {
                'x-access-token': AuthenticationService.getFundraiserToken()
            }
        }
    );

    return response.data;
}

export async function apiAdminCancelFundraiserOrder(
    orderId: string,
    fundraiserId: string
): Promise<IApiSuccess | IApiError> {
    console.log(orderId, fundraiserId);
    const response = await api.post(
        '/admin/cancel-order',
        {
            orderId,
            fundraiserId
        },
        {
            headers: {
                'x-access-token': AuthenticationService.getAdminToken()
            }
        }
    );

    return response.data;
}

export async function apiAdminGetFundraisers(): Promise<{ fundraisers: (IFundraiser & { orders: IOrder[] })[] }> {
    const response = await api.get('/admin/fundraisers', {
        headers: {
            'x-access-token': AuthenticationService.getAdminToken()
        }
    });

    return response.data;
}

export async function apiAdminGetManageFundraiserInfo(
    id?: string
): Promise<IApiGenericSuccess<IFundraiser & { orders: IOrder[] }> | IApiError> {
    const response = await api.get(`/admin/fundraisers/${id}`, {
        headers: {
            'x-access-token': AuthenticationService.getAdminToken()
        }
    });

    return response.data;
}

export async function apiAdminRefreshToken(): Promise<ITokenData> {
    const response = await api.post('/admin/refresh', {
        headers: {
            'x-access-token': AuthenticationService.getAdminToken()
        }
    });

    return response.data;
}

export async function apiFundraiserRefreshToken(): Promise<ITokenData> {
    const response = await api.post('/manage-fundraiser/refresh', {
        headers: {
            'x-access-token': AuthenticationService.getAdminToken()
        }
    });

    return response.data;
}

export async function apiForgotFundraiserPassword(username: string): Promise<IApiSuccess | IApiError> {
    const response = await api.post('/manage-fundraiser/forgot-password', {
        username
    });

    return response.data;
}

export async function apiSetNewFundraiserPassword(token: string, values: object): Promise<IApiSuccess | IApiError> {
    const response = await api.post('/manage-fundraiser/set-password', {
        token,
        ...values
    });

    return response.data;
}

export async function apiAdminUpdateConfiguration(values: object): Promise<IApiSuccess | IApiError> {
    const response = await api.post('/admin/update-config', values, {
        headers: {
            'x-access-token': AuthenticationService.getAdminToken()
        }
    });

    return response.data;
}

export async function apiAdminUpdateFundraiserDates(values: object): Promise<IApiSuccess | IApiError> {
    const response = await api.post('/admin/update-dates', values, {
        headers: {
            'x-access-token': AuthenticationService.getAdminToken()
        }
    });

    return response.data;
}

export async function apiAdminUpdateStrings(values: object): Promise<IApiSuccess | IApiError> {
    const response = await api.post('/admin/update-strings', values, {
        headers: {
            'x-access-token': AuthenticationService.getAdminToken()
        }
    });

    return response.data;
}

export async function apiGetStrings(): Promise<IApiGenericSuccess<IString[]>> {
    const response = await api.get('/strings');

    return response.data;
}

export async function apiAdminCancelFundraiser(id: string): Promise<IApiSuccess | IApiError> {
    const response = await api.post(
        `/admin/manage-fundraiser/cancel`,
        { id },
        {
            headers: {
                'x-access-token': AuthenticationService.getAdminToken()
            }
        }
    );

    return response.data;
}

export async function apiCancelFundraiser(id: string): Promise<IApiSuccess | IApiError> {
    const response = await api.post(
        `/manage-fundraiser/cancel`,
        { id },
        {
            headers: {
                'x-access-token': AuthenticationService.getAdminToken()
            }
        }
    );

    return response.data;
}

export async function apiAdminRunQuantitiesReport(
    values: object
): Promise<IApiGenericSuccess<IQuantitiesReport> | IApiError> {
    const response = await api.post(`/admin/reports/quantities`, values, {
        headers: {
            'x-access-token': AuthenticationService.getAdminToken()
        }
    });

    return response.data;
}

export async function apiAdminRunFundraisersReport(
    values: object
): Promise<IApiGenericSuccess<IFundraiser[]> | IApiError> {
    const response = await api.post(`/admin/reports/fundraisers`, values, {
        headers: {
            'x-access-token': AuthenticationService.getAdminToken()
        }
    });

    return response.data;
}

export async function apiAdminConfigureFundraiser(data: any): Promise<IApiGenericSuccess<IFundraiser> | IApiError> {
    const response = await api.post(`/admin/manage-fundraiser/configure`, data, {
        headers: {
            'x-access-token': AuthenticationService.getAdminToken()
        }
    });

    return response.data;
}

export async function apiConfigureFundraiser(data: any): Promise<IApiGenericSuccess<IFundraiser> | IApiError> {
    const response = await api.post(`/manage-fundraiser/configure`, data, {
        headers: {
            'x-access-token': AuthenticationService.getAdminToken()
        }
    });

    return response.data;
}

export async function apiAdminGetUserList(): Promise<IApiGenericSuccess<IUserList[]> | IApiError> {
    const response = await api.get(`/admin/user-list`, {
        headers: {
            'x-access-token': AuthenticationService.getAdminToken()
        }
    });

    return response.data;
}

export async function apiAdminSendPasswordReset(id: string): Promise<IApiSuccess | IApiError> {
    const response = await api.post(
        `/admin/reset-password`,
        { id },
        {
            headers: {
                'x-access-token': AuthenticationService.getAdminToken()
            }
        }
    );

    return response.data;
}
