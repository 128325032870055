import {Button, Card, Table} from 'react-bootstrap';
import {Formik, FormikHelpers} from 'formik';
import Form from 'react-bootstrap/Form';
import {LoadingComponent} from '../../../components/util/LoadingComponent.tsx';
import moment from 'moment/moment';
import {useRef, useState} from 'react';
import {toast} from 'react-toastify';
import {IFundraiser} from '../../../types/FundraiserTypes.ts';
import {apiAdminRunFundraisersReport} from '../../../api.ts';
import {parseApiErrors} from '../../../helpers/parseApiErrors.ts';
import {formatAddress} from '../../../helpers/formatAddress.ts';
import {useNavigate} from 'react-router-dom';
import {convertToCSV, downloadCSV, printContent} from '../../../helpers/convertToCSV.ts';
import {useTitle} from '../../../hooks/useTitle.ts';

interface IForm {
    startDate: string;
    endDate: string;
    includeOpen: boolean;
    includeClosed: boolean;
}

export function AdminFundraiserReport() {
    useTitle('Fundraiser Reports');
    
    const cardRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();
    
    const [loading, setLoading] = useState(false);
    const [ submittedStartDate, setSubmittedStartDate ] = useState('');
    const [ submittedEndDate, setSubmittedEndDate ] = useState('');
    const [ data, setData ] = useState<IFundraiser[]>();
    
    async function runReport(values: IForm, { setErrors }: FormikHelpers<IForm>) {
        setLoading(true);
        
        try {
            const response = await apiAdminRunFundraisersReport(values);
            if (!response.success) {
                const errors = parseApiErrors(response.errors);
                setErrors(errors);
                toast.error(response.message ?? 'Failed to generate report');
            } else {
                setSubmittedStartDate(values.startDate);
                setSubmittedEndDate(values.endDate);
                setData(response.data);
            }
        } finally {
            setLoading(false);
        }
    }

    function printTable() {
        const startDate = moment(submittedStartDate).format('DD/MM/YYYY');
        const endDate = moment(submittedEndDate).format('DD/MM/YYYY');
        
        if (!cardRef.current || !printContent(`Fundraiser Deliveries ${startDate} - ${endDate}`, cardRef.current.innerHTML)) {
            toast.error('Failed to print window');
            return;
        }
    }

    function onDownloadClick() {
        const table = cardRef.current?.querySelector('table');
        if (!table) {
            return;
        }

        const csvData = convertToCSV(table);
        downloadCSV(`fundraisers-${moment(submittedStartDate).format('DD/MM/YYYY')}-${moment(submittedEndDate).format('DD/MM/YYYY')}`, csvData);
    }
    
    function manageFundraiser(id: string) {
        navigate(`/admin/fundraisers/${id}`);
    }
    
    const initialValues: IForm = {
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().add(14, 'days').format('YYYY-MM-DD'),
        includeOpen: true,
        includeClosed: true
    }
    
    return (<>
        <div className='r-header'>
            <h3>Fundraisers Report</h3>
        </div>
        <Card className='p-4'>
            <Formik<IForm> onSubmit={runReport} initialValues={initialValues}>
                {({ handleSubmit, handleChange, values, touched, errors }) => <Form noValidate onSubmit={handleSubmit}>
                    <div className='d-flex flex-row gap-2 align-items-center'>
                        <Form.Group>
                            <Form.Label>Dispatch Date From</Form.Label>
                            <Form.Control type='date' name='startDate' value={values.startDate} isInvalid={touched.startDate && !!errors.startDate} onChange={handleChange} />
                            <Form.Control.Feedback type='invalid'>{errors.startDate}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>To</Form.Label>
                            <Form.Control type='date' name='endDate' value={values.endDate} isInvalid={touched.endDate && !!errors.endDate} onChange={handleChange} />
                            <Form.Control.Feedback type='invalid'>{errors.endDate}</Form.Control.Feedback>
                        </Form.Group>
                    </div>
                    <div className='d-flex flex-row gap-4 mt-2'>
                        <Form.Check checked={values.includeOpen} name='includeOpen' onChange={handleChange} label='Include Open Fundraisers' id='includeOpen' />
                        <Form.Check checked={values.includeClosed} name='includeClosed' onChange={handleChange} label='Include Closed Fundraisers' id='includeClosed' />
                    </div>
                    <div className='w-25 mt-4 d-flex gap-2'>
                        <Button variant='outline-success' type='submit' style={{ minWidth: 'fit-content' }}>Generate Report</Button>
                        { data && data.length ? <>
                            <Button onClick={printTable} style={{ minWidth: 'fit-content' }}>Print Report</Button>
                            <Button onClick={onDownloadClick} style={{ minWidth: 'fit-content' }}>Download Report as CSV</Button>
                        </> : null }
                    </div>
                </Form> }
            </Formik>
        </Card>
        { loading ? <LoadingComponent /> : null }
        { data !== undefined ? data.length ? <Card ref={cardRef} className='mt-4 overflow-x-scroll p-4'>
            <Table>
                <thead>
                <tr>
                    <th>Fundraiser ID</th>
                    <th>Fundraiser Name</th>
                    <th>Contact Name</th>
                    <th>Contact Phone</th>
                    <th>Contact Email</th>
                    <th>Closing Date</th>
                    <th>Dispatch Date</th>
                    <th>Status</th>
                    <th>Delivery Address</th>
                    <th data-no-csv='true' className='manage-button-col'></th>
                </tr>
                </thead>
                <tbody>
                { data.map(x => <tr key={x._id}>
                    <td>{x._id}</td>
                    <td>{x.name}</td>
                    <td>{x.contactName}</td>
                    <td>{x.phoneNumber}</td>
                    <td>{x.email}</td>
                    <td>{moment(x.endDate).format('DD/MM/YYYY')}</td>
                    <td>{moment(x.deliveryDate).format('DD/MM/YYYY')}</td>
                    <td>{!x.closed ? 'Open' : 'Closed'}</td>
                    <td style={{ whiteSpace: 'pre-line' }}>{formatAddress(x.address)}</td>
                    <td data-no-csv='true' className='manage-button-col'><Button size='sm' onClick={() => manageFundraiser(x._id)}>Manage</Button></td>
                </tr>) }
                </tbody>
            </Table>
        </Card> : 'No results' : null }
    </>);
}