import {useNavigate} from 'react-router-dom';
import {IFundraiserRequest, RequestStatus} from '../../../types/FundraiserTypes.ts';
import {Badge, Button, Table} from 'react-bootstrap';
import moment from 'moment';
import {useTitle} from '../../../hooks/useTitle.ts';
import {useEffect, useState} from 'react';
import {apiAdminDeclineRequest, apiAdminDeleteRequest, apiAdminGetFundraisingRequests} from '../../../api.ts';
import {toast} from 'react-toastify';
import update from 'immutability-helper';
import {LoadingComponent} from '../../../components/util/LoadingComponent.tsx';
import {LoadingButtonComponent} from '../../../components/util/LoadingButtonComponent.tsx';

export function AdminManageRequests() {
	const navigate = useNavigate();
	const [ loading, setLoading ] = useState(true);
	const [ requests, setRequests ] = useState<IFundraiserRequest[]>([]);
	const [ declineId, setDeclineId ] = useState('');
	useTitle('Manage Requests');
	
	useEffect(() => {
		apiAdminGetFundraisingRequests().then(response => {
			const requests = response.requests;
			requests.sort((_, b) => {
				return b.status === RequestStatus.PENDING ? 1 : -1;
			})
			
			setRequests(requests);
			setLoading(false);
		})
	}, []);
	
	async function decline(id: string) {
		setDeclineId(id);
		const response = await apiAdminDeclineRequest(id)
		if (response.success) {
			toast.success('Request declined');
			const index = requests.findIndex(x => x._id === id);
			setRequests(update(requests, {
				// $splice: [[index, 1]]
				[index]: { status: { $set: RequestStatus.DECLINED } }
			}));
		} else {
			if (response.message) {
				toast.error(response.message);
			} else if (response.errors) {
				// do something
			}
		}
		
		setDeclineId('');
	}
	
	async function deleteRequest(id: string) {
		setDeclineId(id);

		const response = await apiAdminDeleteRequest(id)
		if (response.success) {
			toast.success('Request deleted');
			const index = requests.findIndex(x => x._id === id);
			setRequests(update(requests, {
				$splice: [[index, 1]]
				// [index]: { status: { $set: RequestStatus.DECLINED } }
			}));
		} else {
			if (response.message) {
				toast.error(response.message);
			} else if (response.errors) {
				// do something
			}
		}
		
		setDeclineId('');
	}
	
	function moreInfo(id: string) {
		navigate(id);
	}
	
	if (loading) {
		return <LoadingComponent />
	}
	
	return (
		<>
		<div className="r-header">
			<h3>Pending Fundraiser Requests</h3>
		</div>
		<div className="card p-4">
			<Table>
				<thead>
					<tr>
						<th>Name</th>
						<th>Contact Email</th>
						<th>Start Date</th>
						<th>Delivery Date</th>
						<th style={{ width: '1%' }} colSpan={2}>Actions</th>
					</tr>
				</thead>
				<tbody>
					{
						requests.map(x => <tr key={x._id} className='align-middle'>
							<td>
								{x.startDate < new Date() ? <Badge bg='warning' className='me-2'>STARTED</Badge> : null }
								{x.deliveryDate < new Date() ? <Badge bg='warning' className='me-2'>EXPIRED</Badge> : null }
								{x.status === RequestStatus.DECLINED ? <Badge bg='danger' className='me-2'>DECLINED</Badge> : null}
								{x.name}
							</td>
							<td>{x.email}</td>
							<td>{moment(x.startDate).format('MMM Do YYYY')}</td>
							<td>{moment(x.deliveryDate).format('MMM Do YYYY')}</td>
							<td>
								<Button variant='secondary' size='sm' onClick={() => moreInfo(x._id)}>Manage</Button>
							</td>
							<td>
								{ x.status === RequestStatus.PENDING ? 
									<LoadingButtonComponent loading={declineId === x._id} variant='warning' size='sm' onClick={() => decline(x._id)}>Decline</LoadingButtonComponent> :
									<LoadingButtonComponent loading={declineId === x._id} variant='danger' size='sm' onClick={() => deleteRequest(x._id)}>Delete</LoadingButtonComponent>}
							</td>
						</tr>)
					}
				</tbody>
			</Table>
		</div>
		</>
	)
}