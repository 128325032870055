import React from 'react';
import ReactDOM from 'react-dom/client';
import './stylesheets/styles.scss';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import {
    createBrowserRouter,
    createRoutesFromChildren,
    matchRoutes,
    RouterProvider,
    useLocation,
    useNavigationType
} from 'react-router-dom';
import Root from './routes/Root.tsx';
import { AdminAuthCheck } from './routes/admin/AdminAuthCheck.tsx';
import { AdminLogin } from './routes/admin/AdminLogin.tsx';
import { AdminErrorElement } from './routes/admin/AdminErrorElement.tsx';
import { AdminPanel } from './routes/admin/AdminPanel.tsx';
import { AdminCatalogsList } from './routes/admin/catalog/AdminCatalogsList.tsx';
import { AdminManageCatalog } from './routes/admin/catalog/AdminManageCatalog.tsx';
import { ToastContainer } from 'react-toastify';
import { AdminManageRequests } from './routes/admin/requests/AdminManageRequests.tsx';
import { AdminManageRequest } from './routes/admin/requests/AdminManageRequest.tsx';
import { ManageFundraiserLogin } from './routes/manage-fundraiser/ManageFundraiserLogin.tsx';
import { ManageFundraiserErrorElement } from './routes/manage-fundraiser/ManageFundraiserErrorElement.tsx';
import { ManageFundraiserAuthCheck } from './routes/manage-fundraiser/ManageFundraiserAuthCheck.tsx';
import { ManageFundraiser } from './routes/manage-fundraiser/ManageFundraiser.tsx';
import { Fundraiser } from './routes/Fundraiser.tsx';
import Index from './routes/Index.tsx';
import { AdminManageFundraisers } from './routes/admin/fundraisers/AdminManageFundraisers.tsx';
import { AdminManageFundraiser } from './routes/admin/fundraisers/AdminManageFundraiser.tsx';
import { ManageFundraiserForgotPassword } from './routes/manage-fundraiser/ManageFundraiserForgotPassword.tsx';
import { AdminManageConfiguration } from './routes/admin/AdminManageConfiguration.tsx';
import { CreateFundraiserRequest } from './routes/CreateFundraiserRequest.tsx';
import { AdminStringsConfig } from './routes/admin/AdminStringsConfig.tsx';
import StringsProvider from './contexts/StringsContext.tsx';
import { AdminQuantitiesReport } from './routes/admin/reports/AdminQuantitiesReport.tsx';
import { AdminFundraiserReport } from './routes/admin/reports/AdminFundraiserReport.tsx';
import * as Sentry from '@sentry/react';
import ErrorComponent from './components/ErrorComponent.tsx';
import { AdminUsersList } from './routes/admin/users/AdminUsersList.tsx';

// @ts-ignore
window._AutofillCallbackHandler =
    // @ts-ignore
    window._AutofillCallbackHandler || function () {};

Sentry.init({
    dsn: 'https://4e054580b515ec2be97b7c068474cbe1@o4505702665027584.ingest.sentry.io/4505702666338304',
    integrations: [
        new Sentry.BrowserTracing({
            routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                React.useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes
            )
        }),
        new Sentry.BrowserProfilingIntegration(),
        new Sentry.Replay()
    ],
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', 'https://fundraise.pasta.co.nz'],
    // Performance Monitoring
    tracesSampleRate: 0.1, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.05, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([
    {
        path: '/',
        element: <Root />,
        children: [
            {
                path: '',
                element: <Index />
            },
            // {
            //     path: '/request',
            //     element: <FundraiserRequest />
            // },
            {
                path: '/request',
                element: <CreateFundraiserRequest />
            },
            {
                path: '/f/:slug/:demo?',
                element: <Fundraiser />
            },
            {
                path: '/fundraiser/login',
                element: <ManageFundraiserLogin />
            },
            {
                path: '/fundraiser/forgot-password',
                element: <ManageFundraiserForgotPassword />
            },
            {
                path: '/fundraiser',
                element: <ManageFundraiserAuthCheck />,
                errorElement: <ManageFundraiserErrorElement />,
                children: [
                    {
                        path: '',
                        element: <ManageFundraiser />
                    }
                ]
            },
            {
                path: '/admin/login',
                element: <AdminLogin />
            },
            {
                path: '/admin',
                element: <AdminAuthCheck />,
                errorElement: <AdminErrorElement />,
                children: [
                    {
                        path: '',
                        element: <AdminPanel />
                    },
                    {
                        path: 'configuration',
                        element: <AdminManageConfiguration />
                    },
                    {
                        path: 'catalogs',
                        element: <AdminCatalogsList />
                    },
                    {
                        path: 'catalogs/create',
                        element: <AdminManageCatalog creation={true} />
                    },
                    {
                        path: 'catalogs/:id',
                        element: <AdminManageCatalog creation={false} />
                    },
                    {
                        path: 'requests',
                        element: <AdminManageRequests />
                    },
                    {
                        path: 'requests/:id',
                        element: <AdminManageRequest />
                    },
                    {
                        path: 'fundraisers',
                        element: <AdminManageFundraisers />
                    },
                    {
                        path: 'fundraisers/:id',
                        element: <AdminManageFundraiser />
                    },
                    {
                        path: 'strings',
                        element: <AdminStringsConfig />
                    },
                    {
                        path: 'reports',
                        children: [
                            {
                                path: 'quantities',
                                element: <AdminQuantitiesReport />
                            },
                            {
                                path: 'fundraisers',
                                element: <AdminFundraiserReport />
                            }
                        ]
                    },
                    {
                        path: 'users',
                        element: <AdminUsersList />
                    }
                ]
            }
        ],
        errorElement: <ErrorComponent />
    }
]);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <React.StrictMode>
        <StringsProvider>
            <RouterProvider router={router} />
        </StringsProvider>
        <ToastContainer />
    </React.StrictMode>
);
