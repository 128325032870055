import Button from 'react-bootstrap/esm/Button';
import {useNavigate} from 'react-router-dom';
import {useTitle} from "../hooks/useTitle.ts";
import PVLogo from '../assets/images/pv-logo.png';

export default function Root() {
    const navigate = useNavigate();
    useTitle();

    return <>
    <div className="index-wrapper d-flex justify-content-center align-items-center">
        <div className="col-lg-4 col-md-8">
            <div className="main-box card">
                <div className="logo-wrap d-flex justify-content-center">
                <img src={PVLogo} alt="" className="PVLogo"/>
                </div>
                <div className="buttons d-flex justify-content-around">
                    <Button className="btn btn-dark" onClick={() => navigate(`/request`)}>Create a fundraiser</Button>
                    <br />
                    <Button className="btn btn-dark" onClick={() => navigate(`/fundraiser/login`)}>Manage your fundraiser</Button>
                    <br />
                    <Button className="btn btn-dark" onClick={() => navigate(`/admin/login`)}>Admin login</Button>
                </div>
            </div>
        </div>
    </div>
    </>;
}