import {Navigate} from "react-router-dom";
import {IFundraiser, IOrder} from "../../types/FundraiserTypes.ts";
import {useEffect, useState} from "react";
import {apiGetCatalogs, apiGetManageFundraiserInfo} from '../../api.ts';
import {ManageFundraiserComponent} from "../../components/fundraiser/manage/ManageFundraiserComponent.tsx";
import {useTitle} from "../../hooks/useTitle.ts";
import {LoadingComponent} from "../../components/util/LoadingComponent.tsx";
import {Container, Row} from 'react-bootstrap';
import {ICatalog} from '../../types/CatalogTypes.ts';
export function ManageFundraiser() {
	const [ fundraiser, setFundraiser ] = useState<IFundraiser & { orders: IOrder[] }>();
	const [ catalog, setCatalog ] = useState<ICatalog>();
	const [ loading, setLoading ] = useState(true);
	useTitle('Manage Fundraiser');
	
	useEffect(() => {
		setLoading(true);
		apiGetManageFundraiserInfo().then(response => {
			setFundraiser(response);

			apiGetCatalogs().then(catalogsResponse => {
				setCatalog(catalogsResponse.catalogs.find(x => x._id === response.catalog))
			})
			.finally(() => {
				setLoading(false);
			});
		});
	}, []);
	
	if (loading) {
		return <LoadingComponent />
	}
	
	if (!fundraiser) {
		return <Navigate to='/' />;
	}
	
	return <Container>
		<Row>
			<ManageFundraiserComponent fundraiser={fundraiser} catalog={catalog} admin={false} />
		</Row>
	</Container>
}