import { IAddress, IFundraiser, IPickup } from '../../../types/FundraiserTypes.ts';
import { Alert, Button, Col, FloatingLabel, Modal, Row } from 'react-bootstrap';
import { useContext, useRef } from 'react';
import { FieldArray, Formik, FormikHelpers, FormikProps } from 'formik';
import Form from 'react-bootstrap/Form';
import { Tooltip } from '../../util/Tooltip.tsx';
import { IStringsContext, StringsContext } from '../../../contexts/StringsContext.tsx';
import { ArrayHelpers } from 'formik/dist/FieldArray';
import { PickupLocationBuilderComponent } from '../../createFundraiser/PickupLocationBuilderComponent.tsx';
import moment from 'moment';
import { AddressComponent } from '../../AddressComponent.tsx';
import { calculateCloseDate } from '../../../helpers/calculateCloseDate.ts';
import { DatePickerField } from '../../util/DatePickerField.tsx';
import { subtractWorkingDays } from '../../../helpers/addWorkingDays.ts';
import { apiAdminConfigureFundraiser, apiConfigureFundraiser } from '../../../api.ts';
import { parseApiErrors } from '../../../helpers/parseApiErrors.ts';
import { toast } from 'react-toastify';

interface IProps {
    show: boolean;
    fundraiser: IFundraiser;
    isAdmin: boolean;

    onChange: (fundraiser: IFundraiser) => any;
    close: () => any;
}

interface IForm extends IAddress {
    _id: string;
    name: string;
    contactName: string;
    phoneNumber: string;
    email: string;
    startDate: string;
    endDate: string;
    deliveryDate: string;
    pickups: IPickup[];
    cashPayment: boolean;
    transferPayment: boolean;
    cashPaymentNotes: string;
    transferPaymentNotes: string;
    transferPaymentAccount: string;
}

export default function ConfigureFundraiserModalComponent(props: IProps) {
    const formRef = useRef<FormikProps<IForm>>(null);
    const { getString } = useContext<IStringsContext>(StringsContext);

    async function onFormSubmit(values: IForm, { setErrors }: FormikHelpers<IForm>) {
        const response = await (props.isAdmin ? apiAdminConfigureFundraiser(values) : apiConfigureFundraiser(values));

        if (!response.success) {
            const errors = parseApiErrors(response.errors);
            if (response.message) {
                toast.error(response.message);
            } else {
                toast.error('Failed to update fundraiser. See form for errors.');
            }
            setErrors(errors);
        } else {
            toast.success('Fundraiser updated');
            props.onChange(response.data);
            props.close();
        }
    }

    function onBankTransferChange(_: boolean) {
        formRef.current?.setFieldValue('transferPaymentAccount', '');
    }

    const initialValues: IForm = {
        // ...props.fundraiser,
        _id: props.fundraiser._id,
        name: props.fundraiser.name,
        phoneNumber: props.fundraiser.phoneNumber,
        email: props.fundraiser.email,
        contactName: props.fundraiser.contactName,
        pickups: props.fundraiser.pickups,
        startDate: moment(props.fundraiser.startDate).format('YYYY-MM-DD'),
        deliveryDate: moment(props.fundraiser.deliveryDate).format('YYYY-MM-DD'),
        endDate: moment(props.fundraiser.endDate).format('YYYY-MM-DD'),
        cashPayment: props.fundraiser.paymentMethods.cash,
        transferPayment: props.fundraiser.paymentMethods.bankTransfer,
        cashPaymentNotes: props.fundraiser.paymentNotes.cash,
        transferPaymentNotes: props.fundraiser.paymentNotes.bankTransfer,
        transferPaymentAccount: props.fundraiser.paymentNotes.bankTransferAccount,
        companyName: props.fundraiser.address.companyName,
        addressLine1: props.fundraiser.address.addressLine1,
        addressLine2: props.fundraiser.address.addressLine2,
        addressLine3: props.fundraiser.address.addressLine3,
        town: props.fundraiser.address.town,
        postcode: props.fundraiser.address.postcode,
        region: props.fundraiser.address.region
    };

    return (
        <Modal show={props.show} size='lg'>
            <Modal.Header>
                <Modal.Title>Configure Fundraiser</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik<IForm> initialValues={initialValues} onSubmit={onFormSubmit} innerRef={formRef}>
                    {({ setFieldValue, handleSubmit, handleChange, values, errors, touched }) => (
                        <Form noValidate={true} onSubmit={handleSubmit}>
                            <Form.Group className='pb-3'>
                                <Tooltip text={getString('tooltip.fundraiser.name')}>
                                    <FloatingLabel label='Fundraiser Name'>
                                        <Form.Control
                                            id='name'
                                            type='text'
                                            name='name'
                                            placeholder='Fundraiser Name'
                                            value={values.name}
                                            isInvalid={touched.name && !!errors.name}
                                            onChange={handleChange}
                                        />
                                        <Form.Control.Feedback type='invalid'>{errors.name}</Form.Control.Feedback>
                                    </FloatingLabel>
                                </Tooltip>
                            </Form.Group>

                            <Form.Group className='pb-3'>
                                <Tooltip text={getString('tooltip.contact.name')}>
                                    <FloatingLabel label='Contact Name'>
                                        <Form.Control
                                            id='contactName'
                                            type='text'
                                            name='contactName'
                                            value={values.contactName}
                                            placeholder='Contact Name'
                                            isInvalid={touched.contactName && !!errors.contactName}
                                            required
                                            onChange={handleChange}
                                        />
                                        <Form.Control.Feedback type='invalid'>
                                            {errors.contactName}
                                        </Form.Control.Feedback>
                                    </FloatingLabel>
                                </Tooltip>
                            </Form.Group>

                            <Form.Group className='pb-3'>
                                <Tooltip text={getString('tooltip.contact.phone')}>
                                    <FloatingLabel label='Contact Phone Number'>
                                        <Form.Control
                                            id='contactPhone'
                                            type='text'
                                            name='phoneNumber'
                                            value={values.phoneNumber}
                                            placeholder='Contact Phone Number'
                                            isInvalid={touched.phoneNumber && !!errors.phoneNumber}
                                            required
                                            onChange={handleChange}
                                        />
                                        <Form.Control.Feedback type='invalid'>
                                            {errors.phoneNumber}
                                        </Form.Control.Feedback>
                                    </FloatingLabel>
                                </Tooltip>

                                <Form.Text muted>
                                    This phone number will be used by us if we need to get in touch with you regarding
                                    your fundraiser and will be given out with each order placed.
                                </Form.Text>
                            </Form.Group>

                            <Form.Group className='pb-3'>
                                <Tooltip text={getString('tooltip.contact.email')}>
                                    <FloatingLabel label='Contact Email Address'>
                                        <Form.Control
                                            id='contactEmail'
                                            type='email'
                                            name='email'
                                            value={values.email}
                                            placeholder='Contact Email'
                                            isInvalid={touched.email && !!errors.email}
                                            required
                                            onChange={handleChange}
                                        />
                                        <Form.Control.Feedback type='invalid'>{errors.email}</Form.Control.Feedback>
                                    </FloatingLabel>
                                </Tooltip>

                                <Form.Text muted>
                                    Provide an email address which we can contact you on. You will receive your
                                    fundraiser login information via an email when accepted.
                                </Form.Text>
                            </Form.Group>

                            {props.isAdmin ? (
                                <Row>
                                    <Col md={4}>
                                        <Form.Group className='pb-3'>
                                            <Tooltip text={getString('tooltip.fundraiser.startDate')}>
                                                <FloatingLabel label='Fundraiser Opening Date'>
                                                    <Form.Control
                                                        id='startDate'
                                                        type='date'
                                                        name='startDate'
                                                        value={values.startDate}
                                                        placeholder='Start Date'
                                                        required
                                                        onChange={handleChange}
                                                        isInvalid={touched.startDate && !!errors.startDate}
                                                    />
                                                    <Form.Control.Feedback type='invalid'>
                                                        {errors.startDate}
                                                    </Form.Control.Feedback>
                                                </FloatingLabel>
                                            </Tooltip>
                                        </Form.Group>
                                    </Col>

                                    <Col md={4}>
                                        <Form.Group>
                                            <Form.Label>Close Date</Form.Label>
                                            {calculateCloseDate(values.deliveryDate).toDate() <
                                            moment(values.startDate).toDate() ? (
                                                <Alert variant='danger' className='d-block'>
                                                    No possible close date with the current start date and delivery date
                                                    configurations.
                                                </Alert>
                                            ) : (
                                                <div className='d-flex flex-column align-items-start gap-2'>
                                                    <Tooltip text={getString('tooltip.fundraiser.closeDate')}>
                                                        <DatePickerField
                                                            filterDate={(d) => d.getDay() === 1}
                                                            minDate={new Date(values.startDate)}
                                                            maxDate={subtractWorkingDays(
                                                                moment(values.deliveryDate),
                                                                10
                                                            ).toDate()}
                                                            value={
                                                                values.endDate
                                                                    ? moment(values.endDate).format('DD/MM/YYYY')
                                                                    : ''
                                                            }
                                                            name='endDate'
                                                        />
                                                    </Tooltip>
                                                    <Form.Group>
                                                        <Form.Control type='hidden' isInvalid={!!errors.endDate} />
                                                        <Form.Control.Feedback type='invalid'>
                                                            {errors.endDate}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                    <Button
                                                        variant='outline-primary'
                                                        size='sm'
                                                        onClick={() =>
                                                            setFieldValue(
                                                                'endDate',
                                                                calculateCloseDate(values.deliveryDate).format(
                                                                    'YYYY-MM-DD'
                                                                )
                                                            )
                                                        }
                                                    >
                                                        Set Latest Close Date
                                                    </Button>
                                                </div>
                                            )}

                                            <Form.Text>
                                                The close date must be after the start date, at least 10 days prior to
                                                the delivery date and on a Monday.
                                                <br />
                                                The start date is{' '}
                                                <strong>{moment(values.startDate).format('DD/MM/YYYY')}</strong> <br />
                                                The delivery date is{' '}
                                                <strong>{moment(values.deliveryDate).format('DD/MM/YYYY')}</strong>, the
                                                latest close date will be{' '}
                                                <strong>
                                                    {calculateCloseDate(values.deliveryDate).format('DD/MM/YYYY')}
                                                </strong>
                                            </Form.Text>
                                        </Form.Group>
                                    </Col>

                                    <Col md={4}>
                                        <Form.Group className='pb-3'>
                                            <Tooltip text={getString('tooltip.fundraiser.deliveryDate')}>
                                                <FloatingLabel label='Delivery Date'>
                                                    <Form.Control
                                                        id='deliveryDate'
                                                        type='date'
                                                        name='deliveryDate'
                                                        value={values.deliveryDate}
                                                        placeholder='Delivery Date'
                                                        required
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.deliveryDate}
                                                    />
                                                    <Form.Control.Feedback type='invalid'>
                                                        {errors.deliveryDate}
                                                    </Form.Control.Feedback>
                                                </FloatingLabel>
                                            </Tooltip>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            ) : null}

                            <FieldArray
                                name='pickups'
                                render={(helpers: ArrayHelpers) => (
                                    <Form.Group>
                                        <Form.Label>Configure Pickup</Form.Label>
                                        {values.pickups.map((pickup, index) => (
                                            <PickupLocationBuilderComponent
                                                key={index}
                                                index={index}
                                                value={pickup}
                                                onChange={handleChange}
                                                errors={errors}
                                                touched={touched}
                                                remove={() => helpers.remove(index)}
                                                deliveryDate={values.deliveryDate}
                                            />
                                        ))}
                                        {/*<Button variant='success' className='w-100 mt-2' onClick={() => helpers.push('')}>+ Add another pickup location</Button>*/}
                                        <div className='mt-2'>
                                            <Form.Text>
                                                The pickup location will allow your customer to be informed during their
                                                ordering process when and where they will need to pick up their order.
                                            </Form.Text>
                                        </div>
                                    </Form.Group>
                                )}
                            />

                            <Form.Group className='pt-4 mt-4'>
                                <h4>Payment Methods</h4>
                                <Row className='mt-3'>
                                    <Tooltip text={getString('tooltip.fundraiser.cashPayment')}>
                                        <Col>
                                            <Form.Check
                                                id='cashPayment'
                                                name='cashPayment'
                                                label='Cash Payments'
                                                checked={values.cashPayment}
                                                onChange={handleChange}
                                                feedback={errors.cashPayment}
                                                feedbackType='invalid'
                                                isInvalid={touched.cashPayment && !!errors.cashPayment}
                                            />
                                        </Col>
                                    </Tooltip>
                                    <Tooltip text={getString('tooltip.fundraiser.transferPayment')}>
                                        <Col>
                                            <Form.Check
                                                id='transferPayment'
                                                name='transferPayment'
                                                label='Bank Transfer Payments'
                                                checked={values.transferPayment}
                                                onChange={(x) => {
                                                    handleChange(x);
                                                    onBankTransferChange(x.target.checked);
                                                }}
                                                feedback={errors.transferPayment}
                                                feedbackType='invalid'
                                                isInvalid={touched.transferPayment && !!errors.transferPayment}
                                            />
                                        </Col>
                                    </Tooltip>
                                </Row>
                                <Form.Text className='d-block pb-2'>
                                    Specify how you want to have your orders paid for. This will be shown when an order
                                    is being placed.
                                </Form.Text>

                                {values.transferPayment ? (
                                    <>
                                        <Form.Group className='mt-2'>
                                            <Tooltip text={getString('tooltip.fundraiser.transferAccount')}>
                                                <FloatingLabel label='Bank Transfer Account Number'>
                                                    <Form.Control
                                                        name='transferPaymentAccount'
                                                        placeholder='Bank Transfer Account Number'
                                                        value={values.transferPaymentAccount}
                                                        disabled
                                                        onChange={handleChange}
                                                        isInvalid={
                                                            touched.transferPaymentAccount &&
                                                            !!errors.transferPaymentAccount
                                                        }
                                                    />
                                                    <Form.Control.Feedback type='invalid'>
                                                        {errors.transferPaymentAccount}
                                                    </Form.Control.Feedback>
                                                </FloatingLabel>
                                            </Tooltip>
                                        </Form.Group>
                                        <Form.Text>
                                            Modifying bank account is not possible once the funraiser has been
                                            submitted.
                                        </Form.Text>

                                        <Form.Group className='mt-2'>
                                            <Tooltip text={getString('tooltip.fundraiser.transferNotes')}>
                                                <FloatingLabel label='Bank Transfer Payment Information'>
                                                    <Form.Control
                                                        as='textarea'
                                                        name='transferPaymentNotes'
                                                        placeholder='Bank Transfer Payment Information'
                                                        id='transferPaymentNotes'
                                                        value={values.transferPaymentNotes}
                                                        disabled={!values.transferPayment}
                                                        onChange={handleChange}
                                                        isInvalid={
                                                            touched.transferPaymentNotes &&
                                                            !!errors.transferPaymentNotes
                                                        }
                                                        style={{ height: 100 }}
                                                    />
                                                    <Form.Control.Feedback type='invalid'>
                                                        {errors.transferPaymentNotes}
                                                    </Form.Control.Feedback>
                                                </FloatingLabel>
                                            </Tooltip>
                                        </Form.Group>
                                    </>
                                ) : null}

                                {values.cashPayment ? (
                                    <Form.Group className='mt-2'>
                                        <Tooltip text={getString('tooltip.fundraiser.cashNotes')}>
                                            <FloatingLabel label='Cash Payment Information'>
                                                <Form.Control
                                                    as='textarea'
                                                    name='cashPaymentNotes'
                                                    id='cashPaymentNotes'
                                                    placeholder='Cash Payment Information'
                                                    disabled={!values.cashPayment}
                                                    value={values.cashPaymentNotes}
                                                    onChange={handleChange}
                                                    isInvalid={touched.cashPaymentNotes && !!errors.cashPaymentNotes}
                                                    style={{ height: 100 }}
                                                />
                                                <Form.Control.Feedback type='invalid'>
                                                    {errors.cashPaymentNotes}
                                                </Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Tooltip>
                                    </Form.Group>
                                ) : null}
                                <div className='mt-2 mb-2'>
                                    <Form.Text>
                                        Provide any information regarding payment that will be shown when creating an
                                        order.
                                    </Form.Text>
                                </div>
                            </Form.Group>

                            <h5 className='mt-4'>Delivery Address</h5>
                            <AddressComponent
                                values={values}
                                errors={errors}
                                touched={touched}
                                formChanged={handleChange}
                            />
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='danger' onClick={props.close}>
                    Cancel Changes
                </Button>
                <Button variant='success' onClick={() => formRef.current?.submitForm()}>
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
