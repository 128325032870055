import {Link, Navigate, useRouteError} from 'react-router-dom';
import {isAxiosError} from 'axios';

export function AdminErrorElement() {
	const error = useRouteError();
	
	if (isAxiosError(error)) {
		if (error.message.includes('403')) {
			return <Navigate to='/admin/login' />
		}
		
		return <>
		<div className="index-wrapper d-flex justify-content-center align-items-center">
			<div className="col-lg-4 col-md-8">
				<div className="card">
					<h1>An error occured.</h1>
					<p>We're sorry but we ran into an error processing your request. If this continues to occur please contact an administrator and provide them with the following error message:</p>
					<small className="mb-4">{error.status}
					{JSON.stringify(error)}</small>
					<Link className='btn btn-primary' to="/">Return to homepage</Link>

				</div>
			</div>
		</div>
			
		</>
	}
	
	throw error;
}