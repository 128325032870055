import { IAdminFundraiserRequest } from '../../routes/CreateFundraiserRequest.tsx';
import Form from 'react-bootstrap/Form';
import { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { ICreationStepRef } from './CreationOverviewComponent.tsx';
import { CatalogItemsConfigTableComponent } from '../admin/catalog/CatalogItemsConfigTableComponent.tsx';
import * as yup from 'yup';
import { ICatalog, ICatalogItem } from '../../types/CatalogTypes.ts';
import moment from 'moment';
import { subtractWorkingDays } from '../../helpers/addWorkingDays.ts';
import { Alert, Button } from 'react-bootstrap';
import { calculateCloseDate } from '../../helpers/calculateCloseDate.ts';
import { Tooltip } from '../util/Tooltip.tsx';
import { IStringsContext, StringsContext } from '../../contexts/StringsContext.tsx';
import { DatePickerField } from '../util/DatePickerField.tsx';

interface IProps {
    values?: IAdminFundraiserRequest & { deliveryDate: string; startDate: string };
    onStepComplete: (values: { [key: string]: any }) => any;
    catalogs: ICatalog[];
}

interface IForm extends IAdminFundraiserRequest {
    deliveryDate: string;
    startDate: string;
}

export const CreationAdminComponent = forwardRef<ICreationStepRef, IProps>(
    ({ catalogs, values, onStepComplete }, ref) => {
        const formRef = useRef<FormikProps<IForm>>(null);
        useImperativeHandle(ref, () => ({
            onUpdateClick() {
                formRef.current?.submitForm();
            }
        }));

        const [items, setItems] = useState<ICatalogItem[]>([]);
        const { getString } = useContext<IStringsContext>(StringsContext);

        useEffect(() => {
            const catalog = catalogs.find((x) => x._id === values?.catalog);
            if (!values?.customPricing) {
                setItems(catalog?.items ?? []);
            } else {
                setItems(values.items);
            }
        }, []);

        useEffect(() => {
            if (!formRef.current) {
                return;
            }

            formRef.current.setFieldValue(
                'endDate',
                calculateCloseDate(formRef.current.values.deliveryDate).format('YYYY-MM-DD')
            );
        }, [formRef.current, formRef.current?.values.deliveryDate]);

        function onCatalogChange(catalogId: string) {
            const catalog = catalogs.find((x) => catalogId === x._id);
            if (!catalog) {
                setItems([]);
                return;
            }

            setItems(catalog.items);
        }

        const initialValues: IForm = values ?? {
            commission: 0.2,
            catalog: '',
            items: [],
            customPricing: false,
            endDate: moment().format('YYYY-MM-DD'),
            startDate: moment().format('YYYY-MM-DD'),
            deliveryDate: moment().format('YYYY-MM-DD')
        };

        function submit(values: IForm, {}: FormikHelpers<IForm>) {
            onStepComplete(values);
        }

        const schema = yup.object({
            catalog: yup
                .string()
                .oneOf(
                    catalogs.map((x) => x._id),
                    'You must select a location from the list'
                )
                .required('You must provide your location'),
            items: yup.array().of(
                yup.object().shape({
                    name: yup.string().required('Item name is required'),
                    description: yup.string().required('Item description is required'),
                    price: yup.number().required('Item price is required').min(0, 'Price cannot be less than 0'),
                    image: yup.string().required('An item image is required') //.url('Item image must be a URL')
                })
            ),
            commission: yup
                .number()
                .min(0, 'Minimum commission is 0%')
                .max(1, 'Maximum commission is 100%')
                .required('Commission is required'),
            endDate: yup
                .date()
                .required('An end date is required')
                .test('Is-Monday', 'End date must be a Monday', (value) => value.getDay() === 1)
        });

        return (
            <Formik<IForm> initialValues={initialValues} onSubmit={submit} validationSchema={schema} innerRef={formRef}>
                {({ handleSubmit, handleChange, values, touched, errors, setFieldValue }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Form.Group className='mt-3'>
                            <Form.Label>Catalog</Form.Label>
                            <Tooltip text={getString('tooltip.fundraiser.catalog')}>
                                <Form.Select
                                    value={values.catalog}
                                    onChange={(x) => {
                                        handleChange(x);
                                        onCatalogChange(x.target.value);
                                    }}
                                    name='catalog'
                                >
                                    <option value=''>Select a Catalog</option>
                                    {catalogs.map((catalog) => (
                                        <option value={catalog._id} key={catalog._id}>
                                            {catalog.name}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Tooltip>
                            <Form.Control.Feedback type='invalid'>{errors.catalog}</Form.Control.Feedback>
                        </Form.Group>

                        <Tooltip text={getString('tooltip.fundraiser.customPricing')}>
                            <Form.Check
                                name='customPricing'
                                checked={!!values.catalog && values.customPricing}
                                onChange={handleChange}
                                label='Customize catalog pricing'
                                id='customPricingEnabled'
                                className='mt-2 mb-4'
                                disabled={!values.catalog}
                            />
                        </Tooltip>
                        <span className={`${!!values.catalog && values.customPricing ? '' : 'visually-hidden'}`}>
                            <CatalogItemsConfigTableComponent
                                items={items}
                                values={values.items}
                                errors={errors?.items}
                                touched={touched?.items}
                                handleChange={handleChange}
                            />
                        </span>

                        <Form.Group className='mt-3'>
                            <Form.Label htmlFor='commission'>Commission</Form.Label>
                            <Tooltip text={getString('tooltip.fundraiser.commission')}>
                                <Form.Range
                                    name='commission'
                                    id='commission'
                                    value={values.commission}
                                    min={0}
                                    max={1}
                                    step={0.01}
                                    onChange={handleChange}
                                />
                            </Tooltip>
                            <Form.Text>
                                Fundraiser organisers will take {(values.commission * 100).toFixed(0)}% commission from
                                this fundraiser.
                            </Form.Text>
                            <Form.Control.Feedback type='invalid'>{errors.commission}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Close Date</Form.Label>
                            {calculateCloseDate(values.deliveryDate).toDate() < moment(values.startDate).toDate() ? (
                                <Alert variant='danger' className='d-block'>
                                    No possible close date with the current start date and delivery date configurations.
                                </Alert>
                            ) : (
                                <div className='d-flex flex-column align-items-start gap-2'>
                                    <Tooltip text={getString('tooltip.fundraiser.closeDate')}>
                                        <DatePickerField
                                            filterDate={(d) => d.getDay() === 1}
                                            minDate={new Date(values.startDate)}
                                            maxDate={subtractWorkingDays(moment(values.deliveryDate), 10).toDate()}
                                            value={values.endDate ? moment(values.endDate).format('DD/MM/YYYY') : ''}
                                            name='endDate'
                                        />
                                    </Tooltip>
                                    <Form.Group>
                                        <Form.Control type='hidden' isInvalid={!!errors.endDate} />
                                        <Form.Control.Feedback type='invalid'>{errors.endDate}</Form.Control.Feedback>
                                    </Form.Group>
                                    <Button
                                        variant='outline-primary'
                                        size='sm'
                                        onClick={() =>
                                            setFieldValue(
                                                'endDate',
                                                calculateCloseDate(values.deliveryDate).format('YYYY-MM-DD')
                                            )
                                        }
                                    >
                                        Set Latest Close Date
                                    </Button>
                                </div>
                            )}

                            <Form.Text>
                                The close date must be after the start date, at least 10 days prior to the delivery date
                                and on a Monday.
                                <br />
                                The start date is <strong>{moment(values.startDate).format('DD/MM/YYYY')}</strong>{' '}
                                <br />
                                The delivery date is <strong>{moment(values.deliveryDate).format('DD/MM/YYYY')}</strong>
                                , the latest close date will be{' '}
                                <strong>{calculateCloseDate(values.deliveryDate).format('DD/MM/YYYY')}</strong>
                            </Form.Text>
                        </Form.Group>
                    </Form>
                )}
            </Formik>
        );
    }
);
