import {useEffect, useState} from "react";

const useTitle = (title: string | undefined = undefined) => {
    const [docTitle, setDocTitle] = useState(title);
    
    useEffect(() => {
        if (docTitle) {
            document.title = `Pasta Vera - ${docTitle}`;
        } else {
            document.title = 'Pasta Vera';
        }
    }, [docTitle]);
    
    return { docTitle, setDocTitle }; 
}

export { useTitle }