import Form from 'react-bootstrap/Form';
import {IAddress} from '../types/FundraiserTypes.ts';
import {ChangeEvent, useContext, useEffect, useRef, useState} from 'react';
import {apiGetAddressSearchResults, apiGetFullSearchedAddress} from '../api.ts';
import {toast} from 'react-toastify';
import {IMapKitSearchResult} from '../types/ApiTypes.ts';
import {Button, Dropdown, FloatingLabel, Spinner} from 'react-bootstrap';
import DropdownMenu from 'react-bootstrap/DropdownMenu';
import {FormikErrors, FormikTouched, useFormikContext} from "formik";
import {IStringsContext, StringsContext} from '../contexts/StringsContext.tsx';
import {Tooltip} from './util/Tooltip.tsx';

interface IFormTypes extends IAddress {	
	[key: string]: any;
}

interface IProps {
	errors: FormikErrors<IFormTypes>;
	touched: FormikTouched<IFormTypes>;
	formChanged: (event: ChangeEvent<any>) => any;
	values: IFormTypes;
}

export function AddressComponent({ errors, touched, formChanged, values }: IProps) {
	const {getString} = useContext<IStringsContext>(StringsContext);
	const companyNameRef = useRef<HTMLInputElement>(null);
	const addressLine1Ref = useRef<HTMLInputElement>(null);
	const addressLine2Ref = useRef<HTMLInputElement>(null);
	const addressLine3Ref = useRef<HTMLInputElement>(null);
	const townRef = useRef<HTMLInputElement>(null);
	const postcodeRef = useRef<HTMLInputElement>(null);
	const regionRef = useRef<HTMLSelectElement>(null);

	const { setFieldValue, setErrors } = useFormikContext();

	const [ showManualEntry, setShowManualEntry ] = useState(true);
	
	const [ addressSearch, setAddressSearch ] = useState('');
	const [ suggestedAddresses, setSuggestedAddresses ] = useState<IMapKitSearchResult[]>([]);
	const [ loadingSuggestions, setLoadingSuggestions ] = useState(false);
	
	useEffect(() => {
		if (values.addressLine1) {
			setShowManualEntry(true);
		}
	}, []);
	
	useEffect(() => {
		const timeoutId = setTimeout(searchAddress, 300);
		
		return () => {
			clearTimeout(timeoutId);
		}
	}, [addressSearch]);
	
	async function searchAddress() {
		if (addressSearch.length < 5) {
			return;
		}
		
		setLoadingSuggestions(true);
		
		const response = await apiGetAddressSearchResults(addressSearch);
		if (!response.success) {
			let message = response.message;
			if (!message && response.errors) {
				message = response.errors[0].msg;
			}
			
			toast.error(message ?? 'An unknown error occurred');
			
			return;
		}
		
		setSuggestedAddresses(response.data);
		setLoadingSuggestions(false);
	}
	
	async function selectSuggestedAddress(url: string) {
		const response = await apiGetFullSearchedAddress(url);
		setAddressSearch('');
		setSuggestedAddresses([]);
		setShowManualEntry(true);
		
		if (!response.success) {
			let message = response.message;
			if (!message && response.errors) {
				message = response.errors[0].msg;
			}
			toast.error(message ?? 'An unknown error occurred');
			return;
		}
		
		companyNameRef.current!.value = '';
		addressLine1Ref.current!.value = response.data.structuredAddress.fullThoroughfare ?? '';
		addressLine2Ref.current!.value = '';
		addressLine3Ref.current!.value = '';
		postcodeRef.current!.value = response.data.structuredAddress.postCode ?? '';
		townRef.current!.value = response.data.structuredAddress.locality ?? '';
		regionRef.current!.value = response.data.structuredAddress.administrativeArea ?? '';
		
		const newErrors = await Promise.all([
			setFieldValue('companyName', ''),
			setFieldValue('addressLine1', response.data.structuredAddress.fullThoroughfare),
			setFieldValue('addressLine2', ''),
			setFieldValue('addressLine3', ''),
			setFieldValue('postcode', response.data.structuredAddress.postCode),
			setFieldValue('town', response.data.structuredAddress.locality),
			setFieldValue('region', response.data.structuredAddress.administrativeArea),
		]);
		
		const existingErrors = Object.keys(errors).filter(x => !['companyName', 'addressLine1', 'addressLine2', 'addressLine3', 'postcode', 'town', 'region'].includes(x));
		const toAdd: FormikErrors<any> = {};
		for (let existing of existingErrors) {
			toAdd[existing] = errors[existing];
		}
		
		setErrors({ ...newErrors, ...toAdd });
	}
	
	// function updateField(e: ChangeEvent<HTMLInputElement>) {
	// 	if (onChange && address) {
	// 		onChange(update(address, {
	// 			[`${e.target.name}`]: { $set: e.target.value }
	// 		}));
	// 	}
	// }
	
	// function updateRegion(e: ChangeEvent<HTMLSelectElement>) {
	// 	if (onChange && address) {
	// 		onChange(update(address, {
	// 			[`${e.target.name}`]: { $set: e.target.value }
	// 		}));
	// 	}
	// }
	
	const hasAddressErrors = !!(errors.addressLine1 || errors.addressLine2 || errors.addressLine3 || errors.region || errors.town || errors.companyName || errors.postcode);
	
	return (
		<>
			<Form.Label htmlFor='addressLookup'>Look up your address</Form.Label>
			<Tooltip text={getString('tooltip.address.search')}>
				<Form.Group className='position-relative'>
					{ loadingSuggestions ? <Spinner animation='border' role='status' className='position-absolute' style={{ right: 10, top: 3 }}>
						<span className='visually-hidden'>Loading...</span>
					</Spinner> : null }
					<Form.Control id='addressLookup' type='text' placeholder='Start typing your address..' value={addressSearch} onChange={x => setAddressSearch(x.target.value)} isInvalid={hasAddressErrors && !showManualEntry} />
					<Form.Control.Feedback type='invalid'>You must provide an address</Form.Control.Feedback>
				</Form.Group>
			</Tooltip>
			<div className='position-relative'>
				<DropdownMenu show={true} className={`w-100 address-drop ${suggestedAddresses.length > 0 ? '' : 'visually-hidden'}`}>
					{ suggestedAddresses.map(x => <>
						<Dropdown.Item key={x.completionUrl} onClick={() => selectSuggestedAddress(x.completionUrl)} style={{ whiteSpace: 'break-spaces' }}>
							{x.displayLines.join(', ')}
						</Dropdown.Item>
						<Dropdown.Divider />
					</>) }
				</DropdownMenu>
			</div>
			<Form.Text>
				Start typing your address to auto-fill an address below. 
			</Form.Text>
			
			<hr />

			{ !showManualEntry ? <Button variant='link' size='sm' onClick={() => setShowManualEntry(true)}>Prefer Manual Entry</Button> : null }
			
			<div className={showManualEntry ? '' : 'visually-hidden'}>
				<Tooltip text={getString('tooltip.address.companyName')}>
					<Form.Group className="mt-3">
						<FloatingLabel label='Company Name'>
							<Form.Control id='companyName' type='text' name='companyName' placeholder='Company Name' autoComplete='organization' value={values.companyName} onChange={formChanged} ref={companyNameRef} isInvalid={touched.companyName && !!errors.companyName} />
							<Form.Control.Feedback type='invalid'>{errors.companyName}</Form.Control.Feedback>
						</FloatingLabel>
					</Form.Group>
				</Tooltip>

				<Tooltip text={getString('tooltip.address.addressLine1')}>
					<Form.Group className="mt-3">
						<FloatingLabel label='Address Line 1'>
							<Form.Control id='addressLine1' type='text' name='addressLine1' placeholder='Address Line 1' autoComplete='address-line-1' required value={values.addressLine1} onChange={formChanged} ref={addressLine1Ref} isInvalid={touched.addressLine1 && !!errors.addressLine1} />
							<Form.Control.Feedback type='invalid'>{errors.addressLine1}</Form.Control.Feedback>
						</FloatingLabel>
					</Form.Group>
				</Tooltip>

				<Tooltip text={getString('tooltip.address.addressLine2')}>
					<Form.Group className="mt-3">
						<FloatingLabel label='Address Line 2'>
							<Form.Control id='addressLine2' type='text' name='addressLine2' placeholder='Address Line 2' autoComplete='address-line-2' value={values.addressLine2} onChange={formChanged} ref={addressLine2Ref} isInvalid={touched.addressLine2 && !!errors.addressLine2} />
							<Form.Control.Feedback type='invalid'>{errors.addressLine2}</Form.Control.Feedback>
						</FloatingLabel>
					</Form.Group>
				</Tooltip>

				<Tooltip text={getString('tooltip.address.addressLine3')}>
					<Form.Group className="mt-3">
						<FloatingLabel label='Address Line 3'>
							<Form.Control id='addressLine3' type='text' name='addressLine3' placeholder='Address Line 3' autoComplete='address-line-3' value={values.addressLine3} onChange={formChanged} ref={addressLine3Ref} isInvalid={touched.addressLine3 && !!errors.addressLine3} />
							<Form.Control.Feedback type='invalid'>{errors.addressLine3}</Form.Control.Feedback>
						</FloatingLabel>
					</Form.Group>
				</Tooltip>

				<Tooltip text={getString('tooltip.address.town')}>
					<Form.Group className="mt-3">
						<FloatingLabel label='Town'>
							<Form.Control id='town' type='text' name='town' placeholder='Town' autoComplete='' required value={values.town} onChange={formChanged} ref={townRef} isInvalid={touched.town && !!errors.town} />
							<Form.Control.Feedback type='invalid'>{errors.town}</Form.Control.Feedback>
						</FloatingLabel>
					</Form.Group>
				</Tooltip>

				<Tooltip text={getString('tooltip.address.postcode')}>
					<Form.Group className="mt-3">
						<FloatingLabel label='Postcode'>
							<Form.Control id='postcode' type='text' name='postcode' placeholder='Postcode' autoComplete='postal-code' value={values.postcode} required onChange={formChanged} ref={postcodeRef} isInvalid={touched.postcode && !!errors.postcode} />
							<Form.Control.Feedback type='invalid'>{errors.postcode}</Form.Control.Feedback>
						</FloatingLabel>
					</Form.Group>
				</Tooltip>

				<Tooltip text={getString('tooltip.address.region')}>
					<Form.Group className="mt-3 mb-3">
						<FloatingLabel label='Region'>
							<Form.Select id='region' name='region' required onChange={formChanged} value={values.region} ref={regionRef} isInvalid={touched.region && !!errors.region}>
								<option value='Please Select' disabled>Please Select</option>
								<option value='Auckland'>Auckland</option>
								<option value='Bay of Plenty'>Bay of Plenty</option>
								<option value='Canterbury'>Canterbury</option>
								<option value='Gisborne'>Gisborne</option>
								<option value="Hawke's Bay">Hawke's Bay</option>
								<option value='Manawatu-Wanganui'>Manawatu-Wanganui</option>
								<option value='Marlborough'>Marlborough</option>
								<option value='Nelson'>Nelson</option>
								<option value='Northland'>Northland</option>
								<option value='Otago'>Otago</option>
								<option value='Southland'>Southland</option>
								<option value='Taranaki'>Taranaki</option>
								<option value='Tasman'>Tasman</option>
								<option value='Waikato'>Waikato</option>
								<option value='Wellington'>Wellington</option>
								<option value='West Coast'>West Coast</option>
							</Form.Select>
							<Form.Control.Feedback type='invalid'>{errors.region}</Form.Control.Feedback>
						</FloatingLabel>
					</Form.Group>
				</Tooltip>
			</div>
		</>
	)
}