import {useTitle} from '../hooks/useTitle.ts';
import {useEffect, useState} from 'react';
import {Card, Col} from 'react-bootstrap';
import {CreationStep1Component} from '../components/createFundraiser/CreationStep1Component.tsx';
import {CreationStep2Component} from '../components/createFundraiser/CreationStep2Component.tsx';
import {apiGetCatalogs} from '../api.ts';
import {ICatalog, ICatalogItem} from '../types/CatalogTypes.ts';
import {CreationStep3Component} from '../components/createFundraiser/CreationStep3Component.tsx';
import {CreationOverviewComponent} from '../components/createFundraiser/CreationOverviewComponent.tsx';
import {IAddress, IPickup} from '../types/FundraiserTypes.ts';
import moment from 'moment/moment';
import {Link, NavLink} from 'react-router-dom';
import update from 'immutability-helper';

export interface ISubmissionForm extends IAddress {
	name: string;
	contactName: string;
	phoneNumber: string;
	email: string;
	startDate: string;
	deliveryDate: string;
	catalog: string;
	pickups: IPickup[];
	cashPayment: boolean;
	transferPayment: boolean;
	cashPaymentNotes: string;
	transferPaymentNotes: string;
	transferPaymentAccount: string;
}

export interface IAdminFundraiserRequest {
	catalog: string;
	customPricing: boolean;
	items: ICatalogItem[];
	commission: number;
	endDate: string;
}

export function CreateFundraiserRequest() {
	const [stage, setStage] = useState(1);
	const [catalogs, setCatalogs] = useState<ICatalog[]>([]);
	const [created, setCreated] = useState(false);
	const [ request, setRequest ] = useState<ISubmissionForm>({
		name: '',
		email: '',
		phoneNumber: '',
		contactName: '',
		startDate: moment().format('YYYY-MM-DD'),
		deliveryDate: moment().add('40', 'days').format('YYYY-MM-DD'),
		companyName: '',
		addressLine1: '',
		addressLine2: '',
		addressLine3: '',
		town: '',
		postcode: '',
		region: '',
		catalog: '',
		pickups: [{
			location: '',
			_id: '',
			startTime: new Date(),
			endTime: new Date()
		}],
		cashPayment: true,
		transferPayment: true,
		cashPaymentNotes: '',
		transferPaymentNotes: 'Online banking payments only. Please make your payment at the time of your order. Please include your name and phone number in the reference. Thank you for supporting our fundraiser!',
		transferPaymentAccount: '',
	});

	useEffect(() => {
		apiGetCatalogs().then(response => {
			setCatalogs(response.catalogs);
		});
	}, []);
	
	useEffect(() => {
		// if (request.catalog) {
		// 	return;
		// }
		const sortedCatalogs = [...catalogs];
		sortedCatalogs.sort((a, b) => a.priority - b.priority);
		// console.log(sortedCatalogs);
		if (request.town || request.region) {
			let locatedCatalog: ICatalog | undefined = undefined;
			
			for (let cata of sortedCatalogs) {
				for (let config of cata.autoAssignConfig) {
					if (config.values.find(x => x.toLowerCase() === request.town.toLowerCase()) || config.values.find(x => x.toLowerCase() === request.region.toLowerCase())) {
						locatedCatalog = cata;
						break;
					}
				}
				
				if (locatedCatalog) {
					break;
				}
			}
			
			if (locatedCatalog) {
				setRequest(update(request, {
					catalog: { $set: locatedCatalog._id }
				}));
			}
		}

		// if (!request.pickups[0].location) {
			// request.pickups[0].startTime = moment(request.deliveryDate).add(1, 'day').hour(12).minutes(0).toDate();
			// request.pickups[0].endTime = moment(request.deliveryDate).add(1, 'day').hour(14).minutes(0).toDate();
		// }
	}, [ request ]);
	
	useTitle('Create a Fundraiser Request');
	
	function onStepComplete(data: { [key: string]: any }) {
		setRequest(req => {
			const newReq = { ...req };
			for (let key in data) {
				// @ts-ignore
				newReq[key] = data[key];
			}
			
			return newReq;
		});
		
		if (stage >= 4) {
			return;
		}
		
		setStage(stage + 1);
		window.scrollTo({ top: 0 });
	}
	
	function onPrevious(data: { [key: string]: any }) {
		setRequest(req => {
			const newReq = { ...req };
			for (let key in data) {
				// @ts-ignore
				newReq[key] = data[key];
			}

			return newReq;
		});
		
		if (stage <= 1) {
			return;
		}
		
		setStage(stage - 1);
		window.scrollTo({ top: 0 });
	}
	
	function onRequestCreated() {
		setCreated(true)
	}
	
	function getStep() {
		switch (stage) {
			case 1:
				return <CreationStep1Component onStepComplete={onStepComplete} values={request} />;
				
			case 2:
				return <CreationStep2Component catalogs={catalogs} onStepComplete={onStepComplete} values={request} onPrevious={onPrevious} deliveryDate={request.deliveryDate} />;
			
			case 3:
				return <CreationStep3Component onStepComplete={onStepComplete} values={request} onPrevious={onPrevious} />;
				
			case 4:
				return <CreationOverviewComponent catalogs={catalogs} request={request} onStepComplete={onStepComplete} onRequestCreated={onRequestCreated} onPrevious={onPrevious} />
				
			default: 
				return 'Unknown creation step';
		}
	}

	if (created) {
		return <>
			<div className='index-wrapper d-flex justify-content-center align-items-center'>
				<div className='col-lg-4 col-md-8'>
					<div className='card text-center p-4'>
						<h4>Request Created!</h4>
						<p>We will be in touch shortly - please check your emails for an email from us!</p>
						<Link className='btn btn-primary' to='/'>Return to homepage</Link>
					</div>
				</div>
			</div>
		</>;
	}
	
	return (
		<div className='index-wrapper d-flex justify-content-center align-items-center'>
			<Col md={4}>
				<div className='steps d-flex align-items-center justify-content-between'>
					<h3>Submit a Fundraiser Request</h3>
					<h5>Step {stage}</h5>
				</div>
				<Card>
					{ getStep() }
					
				</Card>
				<div className="mt-2">
					<NavLink to='/' className="p-2">Cancel</NavLink>
				</div>
			</Col>
			
		</div>
	);
}