import {useState} from "react";
import {Button} from "react-bootstrap";
import {Formik, FormikHelpers} from "formik";
import * as yup from 'yup';
import Form from "react-bootstrap/Form";
import {parseApiErrors} from '../../../helpers/parseApiErrors.ts';
import {toast} from 'react-toastify';
import {IPickup} from '../../../types/FundraiserTypes.ts';
import {formatPickupLocation} from '../../../helpers/formatPickupLocation.ts';
import {PickupLocationBuilderComponent} from '../../createFundraiser/PickupLocationBuilderComponent.tsx';
import {LoadingButtonComponent} from '../../util/LoadingButtonComponent.tsx';
import {apiAdminUpdateFundraiserPickup, apiUpdateFundraiserPickup} from '../../../api.ts';

interface IProps {
    id: string;
    pickup: IPickup;
    deliveryDate: Date;
    index: number;
    admin: boolean;
    onPickupChange: (pickup: IPickup) => any;
    cancelled: boolean;
}

interface IForm extends IPickup {
    id: string;
    index: number;
    pickupId: string;
}

export function ChangeFundraiserPickupComponent({ id, pickup, deliveryDate, index, onPickupChange, admin }: IProps) {
    const [ changing, setChanging ] = useState(false);

    async function submitForm(values: IForm, { setErrors }: FormikHelpers<IForm>) {
        const response = await (admin ? apiAdminUpdateFundraiserPickup(values) : apiUpdateFundraiserPickup(values));

        if (response.success) {
            onPickupChange(values);
            setChanging(false);
            toast.success('Pickup updated');
        } else {
            if (response.errors) {
                const errors = parseApiErrors(response.errors);

                setErrors(errors);
            }
        }
    }

    const schema = yup.object().shape({
        location: yup.string().required('A pickup location is required'),
        startTime: yup.date().required('A start time is required').test('DeliveryDateCheck', 'Pickup start date cannot be before the delivery date', value => {
            return value > new Date(deliveryDate)
        }),
        endTime: yup.date().required('An end time is required').when('startTime', ([startDate], yup) => !startDate ? yup : yup.min(startDate, 'End time cannot be before start time')),
    });
    
    const initialValues: IForm = {
        id: id,
        _id: pickup._id,
        pickupId: pickup._id,
        index: index,
        location: pickup.location,
        startTime: pickup.startTime,
        endTime: pickup.endTime
    }

    return <div className='d-flex flex-row justify-content-between align-items-center my-2'>
        { changing ?
            <Formik<IForm> initialValues={initialValues} onSubmit={submitForm} validationSchema={schema}>
                {({ handleSubmit, handleChange, values, touched, errors, isSubmitting }) => (
                    <>
                        <Form noValidate onSubmit={handleSubmit} className='w-100 d-flex flex-row justify-content-between align-items-center my-2'>
                            <PickupLocationBuilderComponent value={values} onChange={handleChange} touched={touched} errors={errors} remove={() => {}} index={index} deliveryDate={deliveryDate.toString()} asSingle={true} />
                            <div className='d-flex flex-row gap-2'>
                                <Button onClick={() => setChanging(false)} variant='danger'>Cancel</Button>
                                <LoadingButtonComponent loading={isSubmitting} type='submit'>Update</LoadingButtonComponent>
                            </div>
                        </Form>
                        <br />
                        <small>Note: If you change the location, date or time, you'll need to let the customers know that have previously ordered.</small>
                    </>
                )}
            </Formik> : formatPickupLocation(pickup)
        }
    </div>
}