import {ICatalogAutoAssignConfig} from '../../../types/CatalogTypes.ts';
import Form from 'react-bootstrap/Form';
import {FieldArray, FormikErrors, FormikTouched, useFormikContext} from 'formik';
import {ChangeEvent} from 'react';
import {ArrayHelpers} from 'formik/dist/FieldArray';
import {CatalogAutoAssignValuesComponent} from './CatalogAutoAssignValuesComponent.tsx';

interface IProps {
	enabled: boolean;
	values: ICatalogAutoAssignConfig[];
	errors?: FormikErrors<ICatalogAutoAssignConfig>[] | string[] | string;
	touched?: FormikTouched<ICatalogAutoAssignConfig>[] | string[] | string;
	handleChange: (e: ChangeEvent<any>) => any;
}

export function CatalogAutoAssignConfigComponent({enabled, values, errors, touched, handleChange}: IProps) {
	const { setFieldValue } = useFormikContext();
	
	function onEnableChange(value: boolean) {
		if (!value) {
			setFieldValue('autoAssignConfig', []);
		} else {
			setFieldValue('autoAssignConfig', [{
				field: 'town',
				values: ['Christchurch']
			}])
		}
	}
	
	function onFieldChange(index: number) {
		setFieldValue(`autoAssignConfig[${index}].values`, ['']);
	}
	
	const errs = Array.isArray(errors) && typeof errors[0] !== 'string' ? errors as FormikErrors<ICatalogAutoAssignConfig>[] : null; 
	
	return (
		<>
			<Form.Check checked={enabled} onChange={x => { handleChange(x); onEnableChange(x.target.checked) }} id='autoAssignEnabled' label='Enable Catalog Auto Assign' name='autoAssignEnabled' />
			<Form.Text>Set up how this catalog will be auto assigned to fundraisers based on their delivery address</Form.Text>
			{ enabled ? 
				<FieldArray name='autoAssignConfig' render={(_: ArrayHelpers) => <>
					{ values.map((config, index) => <div key={`config${index}`} className='d-flex flex-row gap-2 w-50'>
						<Form.Group className='flex-fill'>
							<Form.Label>Address Field</Form.Label>
							<Form.Select name={`autoAssignConfig[${index}].field`} value={config.field} onChange={x => {handleChange(x); onFieldChange(index)}} isInvalid={!!(errs && errs[index]?.field)}>
								<option value='town'>Town</option>
								<option value='region'>Region</option>
							</Form.Select>
							<Form.Control.Feedback type='invalid'>{ errs ? errs[index].field : null }</Form.Control.Feedback>
						</Form.Group>
						<div className='flex-fill w-50'>
							<CatalogAutoAssignValuesComponent index={index} type={config.field} config={config} handleChange={handleChange} errors={errors} touched={touched} />
						</div>

					</div>) }


					{/*<Button onClick={() => configHelpers.push<string>('')}>Add Config</Button>*/}
				</>} />
			 : null }
		</>
	)
}