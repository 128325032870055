import {useContext, useEffect, useState} from 'react';
import {LoadingComponent} from '../../components/util/LoadingComponent.tsx';
import {IString} from '../../types/ApiTypes.ts';
import {apiAdminUpdateStrings, apiGetStrings} from '../../api.ts';
import {toast} from 'react-toastify';
import {Button, Table} from 'react-bootstrap';
import {FieldArray, Formik, FormikHelpers} from 'formik';
import Form from 'react-bootstrap/Form';
import {ArrayHelpers} from 'formik/dist/FieldArray';
import * as yup from 'yup';
import {LoadingButtonComponent} from '../../components/util/LoadingButtonComponent.tsx';
import {parseApiErrors} from '../../helpers/parseApiErrors.ts';
import {IStringsContext, StringsContext} from '../../contexts/StringsContext.tsx';

interface IForm {
	strings: IString[]
}

export function AdminStringsConfig() {
	const [loading, setLoading] = useState(true);
	const { setStrings, strings } = useContext<IStringsContext>(StringsContext);

	useEffect(() => {
		apiGetStrings().then(response => {
			if (response.success) {
				setStrings(response.data);
			} else {
				toast.error('Failed to load strings');
			}
		}).finally(() => setLoading(false));
	}, []);

	if (loading) {
		return <LoadingComponent/>
	}

	const initialData: IForm = {
		strings: strings
	}

	async function onSubmit(values: IForm, {setErrors}: FormikHelpers<IForm>) {
		const response = await apiAdminUpdateStrings(values);
		if (response.success) {
			toast.success('Strings updated');
		} else {
			const errors = parseApiErrors(response.errors);
			if (response.message) {
				toast.error(response.message);
			}
			
			setErrors(errors);
		}
	}

	const validation = yup.object({
		strings: yup.array().of(yup.object({
			key: yup.string().required('A key is required'),
			friendlyName: yup.string().required('A friendly name is required'),
			value: yup.string().required('A value is required')
		}))
	});

	return (
		<Formik<IForm> initialValues={initialData} onSubmit={onSubmit} validationSchema={validation}>
			{({handleChange, handleSubmit, values, errors, touched, isSubmitting}) => <Form noValidate
																							onSubmit={handleSubmit}>
				<FieldArray name='strings' render={(helpers: ArrayHelpers) =>
					<>
						<Table>
							<thead>
							<tr>
								<th>Key</th>
								<th>Friendly Name</th>
								<th>Value</th>
								<th style={{ width: '1%' }}>Options</th>
							</tr>
							</thead>
							<tbody>

								{values.strings.map((data, index) => 
									<tr key={index}>
										<td>
											<Form.Group>
												<Form.Control name={`strings[${index}].key`} placeholder='some.unique.key'
															  value={data.key} onChange={handleChange}
													isInvalid={touched.strings?.[index] && touched.strings[index].key && Boolean((errors.strings?.[index] as IString)?.key)}
												/>
												<Form.Control.Feedback
													type='invalid'>{(errors.strings?.[index] as IString)?.key}</Form.Control.Feedback>
											</Form.Group>
										</td>
										<td>
											<Form.Group>
												<Form.Control name={`strings[${index}].friendlyName`}
															  placeholder='A little description' value={data.friendlyName}
															  onChange={handleChange}
													isInvalid={touched.strings?.[index] && touched.strings[index].friendlyName && Boolean((errors.strings?.[index] as IString)?.friendlyName)}
												/>
												<Form.Control.Feedback
													type='invalid'>{(errors.strings?.[index] as IString)?.friendlyName}</Form.Control.Feedback>
											</Form.Group>
										</td>
										<td>
											<Form.Group>
												<Form.Control name={`strings[${index}].value`} value={data.value}
															  placeholder='A string value' onChange={handleChange}
													isInvalid={touched.strings?.[index] && touched.strings[index].value && Boolean((errors.strings?.[index] as IString)?.value)}
												/>
												<Form.Control.Feedback
													type='invalid'>{(errors.strings?.[index] as IString)?.value}</Form.Control.Feedback>
											</Form.Group>
										</td>
										<td>
											<Button variant='danger' size='sm' onClick={() => helpers.remove(index)}>Delete</Button>
										</td>
									</tr>
								)}
							</tbody>
						</Table>
						<Button variant='success'
								onClick={() => helpers.push({key: '', value: '', friendlyName: ''})}>Add</Button>
					</>
				}/>
				<LoadingButtonComponent variant='success' type='submit' className='w-10 d-block mt-4' loading={isSubmitting}>Save</LoadingButtonComponent>
			</Form>}
		</Formik>
	)
}