import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

export function LoadingComponent() {
	const admin = window.location.href.includes('admin');
	
	return (
		<div 
			className='d-flex flex-column justify-content-center align-items-center top-0 bottom-0 start-0 end-0 position-absolute user-select-none' 
			style={{ marginLeft: admin ? 320 : 0, opacity: 0.4 }}
		>
			<h4><FontAwesomeIcon icon={faSpinner} spin={true} /></h4>
			<h4>Loading</h4>
		</div>
	)
}