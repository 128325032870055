import {Button, Modal} from "react-bootstrap";
import {ReactNode} from "react";
import {ButtonVariant} from 'react-bootstrap/types';

interface IProps {
	title: string | ReactNode;
	description: string | ReactNode;
	
	cancelText?: string;
	confirmText?: string;
	
	cancelVariant?: ButtonVariant;
	confirmVariant?: ButtonVariant;
	
	confirm: () => any;
	cancel: () => any;
	
	show?: boolean;
}

export function ConfirmationModal({ title, description, confirm, cancel, cancelText, confirmText, show, cancelVariant, confirmVariant } : IProps) {
	return (
		<Modal show={show !== undefined ? show : true} centered={true}>
			<Modal.Header>
				<Modal.Title>{ title }</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{ description }
			</Modal.Body>
			<Modal.Footer>
				<Button variant={cancelVariant ?? 'dark'} onClick={cancel}>{ cancelText ?? 'Cancel' }</Button>
				<Button variant={confirmVariant ?? 'primary'} onClick={confirm}>{ confirmText ?? 'Confirm' }</Button>
			</Modal.Footer>
		</Modal>
	)
}