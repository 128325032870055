import Form from "react-bootstrap/Form";
import {Link, Navigate, NavLink, useNavigate, useSearchParams} from 'react-router-dom';
import {apiFundraiserLoginAttempt} from "../../api.ts";
import AuthenticationService from "../../services/AuthenticationService.ts";
import {Formik, FormikHelpers, FormikProps} from 'formik';
import {LoadingButtonComponent} from "../../components/util/LoadingButtonComponent.tsx";
import * as yup from "yup";
import {useTitle} from "../../hooks/useTitle.ts";
import {parseApiErrors} from '../../helpers/parseApiErrors.ts';
import {useEffect, useRef} from 'react';

interface IForm {
	username: string;
	password: string;
}

export function ManageFundraiserLogin() {
	const navigate = useNavigate();
	const formRef = useRef<FormikProps<IForm>>(null);
	const [params] = useSearchParams();

	useTitle('Fundraiser Login');
	
	if (AuthenticationService.hasValidFundraiserToken()) {
		return <Navigate to={'/fundraiser'} />
	}
	
	useEffect(() => {
		if (params.get('u') && params.get('p')) {
			formRef.current?.submitForm();
		}
	}, [params]);

	async function submitForm(values: IForm, { setErrors }: FormikHelpers<IForm>) {
		const response = await apiFundraiserLoginAttempt(values);

		if (response.success) {
			AuthenticationService.storeFundraiserToken(response.data);
			navigate('/fundraiser');
		} else {
			const errors = parseApiErrors(response.errors);

			if (response.message) {
				errors.password = response.message;
			}

			setErrors(errors);
		}
	}

	const schema = yup.object().shape({
		username: yup.string().required('Enter your username'),
		password: yup.string().required('Enter your password')
	});

	return (
		<div className="index-wrapper d-flex justify-content-center align-items-center">
				<div className="col-lg-4 col-md-8">
					<div className="head-wrap d-flex justify-content-between">
						<h4>Fundraiser Login</h4>
						<NavLink to='/'>Back</NavLink>
					</div>

					<div className="card">
						<Formik<IForm> initialValues={{ username: params.get('u') ?? '', password: params.get('p') ?? '' }} onSubmit={submitForm} validationSchema={schema} innerRef={formRef}>
							{({ handleSubmit, handleChange, values, touched, errors, isSubmitting }) => (
								<Form noValidate onSubmit={handleSubmit}>
									<Form.Group>
										<Form.Label htmlFor='username'>Username</Form.Label>
										<Form.Control id='username' name='username' placeholder='Username' value={values.username} onChange={handleChange} isInvalid={touched.username && !!errors.username} />
										<Form.Control.Feedback type='invalid'>{errors.username}</Form.Control.Feedback>
									</Form.Group>

									<Form.Group>
										<Form.Label htmlFor='password'>Password</Form.Label>
										<Form.Control type='password' id='password' name='password' placeholder='Password' value={values.password} onChange={handleChange} isInvalid={touched.password && !!errors.password} />
										<Form.Control.Feedback type='invalid'>{errors.password}</Form.Control.Feedback>
									</Form.Group>
									<Link to={'/fundraiser/forgot-password'}>Forgot Password?</Link>

									<LoadingButtonComponent className='mt-4 w-100' loading={isSubmitting} type='submit'>Login</LoadingButtonComponent>
								</Form>
							)}
						</Formik>
					</div>
			</div>
		</div>
	)
}