import {Button, Container, Navbar, NavbarBrand} from "react-bootstrap";
import AuthenticationService from "../../services/AuthenticationService.ts";
import {useNavigate} from "react-router-dom";

export function FundraiserNavigationComponent() {
	const navigate = useNavigate();
	function logout() {
		AuthenticationService.logoutFundraiser();
		navigate('/');
	}
	
	return (
		<Navbar>
			<Container>
				<NavbarBrand>Manage your Fundraiser</NavbarBrand>
				<Navbar.Collapse className="justify-content-end">
					<Navbar.Text>
						<Button variant='link' onClick={logout}>Logout</Button>
					</Navbar.Text>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	)
}