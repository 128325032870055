export function convertToCSV(table: HTMLTableElement) {
    const csvData: string[] = [];
    
    const tableRows = table.querySelectorAll('tr');
    for (let row of tableRows) {
        const cols = row.querySelectorAll<HTMLElement>('td,th');
        
        const rowData: string[] = [];
        for (let col of cols) {
            if (col.dataset.noCsv) {
                continue;
            }
            
            rowData.push(`"${col.innerText}"`);
        }
        
        csvData.push(rowData.join(','));
    }
    
    return csvData.join('\n');
}

export function downloadCSV(name: string, csv: string) {
    let csvContent = `data:text/csv;charset=utf-8,${encodeURIComponent(`\uFEFF${csv}`)}`;
    let link = document.createElement('a');
    link.setAttribute('href', csvContent);
    link.setAttribute('download', `${name}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export function printContent(title: string, content: any) {
    const printWindow = window.open('', 'PRINT', 'height=400,width=600');
    if (!printWindow) {
        return false;
    }
    

    printWindow.document.write(`<html><head><title>${title}</title><style>body {font-family: sans-serif;} .manage-button-col { display: none; border: none !important; padding: 0 !important; } table, th, td {\n` +
        '  border: 1px solid black;\n' +
        '  border-collapse: collapse;\n' +
        '} td, th { padding: 2px } </style></head><body >');
    printWindow.document.write(`<h2>${title}</h2>`);
    printWindow.document.write(content);
    printWindow.document.write('</body></html>');

    printWindow.document.close(); // necessary for IE >= 10
    printWindow.focus(); // necessary for IE >= 10*/

    printWindow.print();
    printWindow.close();
    
    return true;
}