import moment, { Moment } from 'moment';

export function addWorkingDays(date: Moment | Date, amount: number): Moment {
    return moment(date).add(amount, 'days');
    // let target = moment(date);

    // while (amount > 0) {
    //     target = target.add(1, 'days');
    //     // decrease "days" only if it's a weekday.
    //     if (target.isoWeekday() !== 6 && target.isoWeekday() !== 7) {
    //         amount -= 1;
    //     }
    // }
    // return target;
}

export function subtractWorkingDays(date: Moment | Date, amount: number): Moment {
    return moment(date).subtract(amount, 'days');
    // let target = moment(date);

    // while (amount > 0) {
    //     target = target.subtract(1, 'days');
    //     // decrease "days" only if it's a weekday.
    //     if (target.isoWeekday() !== 6 && target.isoWeekday() !== 7) {
    //         amount -= 1;
    //     }
    // }
    // return target;
}
