import {IFundraiser, IOrder, IPickup} from '../../../types/FundraiserTypes.ts';
import {useNavigate} from 'react-router-dom';
import {useMemo, useRef, useState} from 'react';
import update from 'immutability-helper';
import {ManageOrderModalComponent} from './ManageOrderModalComponent.tsx';
import {Button, Row, Table} from 'react-bootstrap';
import {ExportSettingsModalComponent} from './ExportSettingsModalComponent.tsx';
import {ManageFundraiserDatesComponent} from './ManageFundraiserDatesComponent.tsx';
import {formatAddress} from '../../../helpers/formatAddress.ts';
import {ChangeFundraiserEmailComponent} from './ChangeFundraiserEmailComponent.tsx';
import {LoadingButtonComponent} from '../../util/LoadingButtonComponent.tsx';
import {ConfirmationModal} from '../../util/ConfirmationModal.tsx';
import {apiAdminCancelFundraiser, apiCancelFundraiser, apiUrl} from '../../../api.ts';
import {toast} from 'react-toastify';
import {parseApiErrors} from '../../../helpers/parseApiErrors.ts';
import {convertToCSV, downloadCSV, printContent} from '../../../helpers/convertToCSV.ts';
import {ChangeFundraiserPickupComponent} from './ChangeFundraiserPickupComponent.tsx';
import {ICatalog} from '../../../types/CatalogTypes.ts';
import ConfigureFundraiserModalComponent from './ConfigureFundraiserModalComponent.tsx';

interface IProps {
	fundraiser: IFundraiser & { orders: IOrder[] };
	catalog?: ICatalog;
	admin: boolean;
}

export function ManageFundraiserComponent(props: IProps) {
	const navigate = useNavigate();
	const [ managedOrder, setManagedOrder ] = useState<IOrder>();
	const [ fundraiser, setFundraiser ] = useState(props.fundraiser);
	const [ showingExportModal, setShowingExportModal ] = useState(false);
	const [ cancelling, setCancelling ] = useState(false);
	const [ showCancelConfirm, setShowCancelConfirm ] = useState(false);
	const [ configuring, setConfiguring ] = useState(false);
	const tableRef = useRef<HTMLTableElement>(null);

	const totalRaised = useMemo(() => {

		let earned = 0;

		for (let order of fundraiser.orders) {
			if (order.cancelled) {
				continue;
			}
			
			for (let item of order.items) {
				const catalogItem = fundraiser.catalogItems.find(x => x._id === item.itemId);
				if (!catalogItem) {
					continue;
				}

				earned += item.amount * catalogItem.price;
			}
		}

		return earned;
	}, [fundraiser.orders, fundraiser.catalogItems]);

	function countOrder(order: IOrder) {
		let cost = 0;
		for (let item of order.items) {
			const cataItem = fundraiser!.catalogItems.find(x => x._id === item.itemId);
			cost += (cataItem?.price ?? 0) * item.amount;
		}

		return cost;
	}

	function onEmailChange(email: string) {
		setFundraiser(update(fundraiser, {
			email: { $set: email }
		}));
	}

	function onPickupChange(pickup: IPickup) {
		setFundraiser(update(fundraiser, {
			pickups: {[0]: { $set: pickup } }
		}));
	}
	
	function updateOrder(newOrder: IOrder) {
		if (!fundraiser || !managedOrder) {
			return;
		}

		const index = fundraiser.orders.indexOf(managedOrder);
		setFundraiser(update(fundraiser, {
			orders: {
				[index]: { $set: newOrder }
			}
		}));
	}
	
	function updateDates(startDate: Date, endDate: Date, deliveryDate: Date) {
		setFundraiser(update(fundraiser, {
			startDate: { $set: startDate },
			endDate: { $set: endDate },
			deliveryDate: { $set: deliveryDate },
		}))
	}
	
	async function cancelFundraiser() {
		setCancelling(true);
		setShowCancelConfirm(false);
		try {
			const response = await (props.admin ? apiAdminCancelFundraiser(fundraiser._id) : apiCancelFundraiser(fundraiser._id));

			if (response.success) {
				toast.success('Fundraiser Cancelled');
				
				setFundraiser(update(fundraiser, {
					cancelled: { $set: true }
				}));
			} else {
				const errors = parseApiErrors(response.errors);
				toast.error(errors.id ?? response.message ?? 'Failed to cancel fundraiser');
			}
		} finally {
			setCancelling(false);
		}
	}
	
	function printTable() {
		if (!tableRef.current || !printContent(fundraiser.name, tableRef.current.outerHTML)) {
			toast.error('Failed to print window');
			return;
		}
	}
	
	function downloadTable() {
		if (!tableRef.current) {
			return;
		}

		const csvData = convertToCSV(tableRef.current);
		downloadCSV(fundraiser.name, csvData);
	}

	return (
		<>
			<ExportSettingsModalComponent open={showingExportModal} close={() => setShowingExportModal(false)} fundraiser={fundraiser} />
			<ConfirmationModal 
				show={showCancelConfirm}
				title='Cancel Fundraiser' 
				description='Are you sure you want to cancel this fundraiser?' 
				confirm={cancelFundraiser} 
				cancel={() => setShowCancelConfirm(false)}
				cancelText='Keep Fundraiser' 
				confirmText='Cancel Fundraiser'
				confirmVariant='danger'
			/>
			{ props.admin ? <ConfigureFundraiserModalComponent show={configuring} fundraiser={fundraiser} onChange={updated => setFundraiser({ ...updated, orders: fundraiser.orders})} close={() => setConfiguring(false)} isAdmin={props.admin} /> : null }
			<div className='row'>
				<div className='col-md-12'>
					{/*<ChangeFundraiserNameComponent name={fundraiser.name} onNameChange={onNameChange} id={fundraiser._id} admin={props.admin} cancelled={fundraiser.cancelled} />*/}
					<div className='d-flex flex-row justify-content-between align-items-center my-4'>
						<h2>{fundraiser.name}</h2>
						{ !fundraiser.cancelled && props.admin ? <Button variant='secondary' onClick={() => setConfiguring(true)}>Edit Fundraiser</Button> : null }
					</div>
					<div className='card p-4'>
						{ managedOrder ? <ManageOrderModalComponent
							order={managedOrder}
							catalogItems={fundraiser.catalogItems}
							show={!!managedOrder}
							close={() => setManagedOrder(undefined)}
							updateOrder={updateOrder}
							admin={props.admin}
							locked={fundraiser.locked}
						/> : null }

						{ !fundraiser.cancelled ? <>
							<div className='d-flex flex-row justify-content-between align-items-center'>
								<span>Your Fundraiser URL: <a href={`/f/${fundraiser.slug}`} target='_blank' rel='noreferrer'>
									{window.location.hostname}/f/{fundraiser.slug}
								</a></span>
								
								<div className='d-flex gap-2'>
									<Button variant='warning' onClick={() => navigate(`/f/${fundraiser.slug}/demo`)}>Test Order</Button>
									{ fundraiser.closed && !props.admin ? null : <LoadingButtonComponent loading={cancelling} onClick={() => setShowCancelConfirm(true)} variant='danger'>Cancel Fundraiser</LoadingButtonComponent> }
								</div>
							</div>
						</> : null }
						<div className='mt-2'>
						<h5>Contact Info</h5>
						<p>{ fundraiser.contactName }</p>
						<ChangeFundraiserEmailComponent email={fundraiser.email} onEmailChange={onEmailChange} id={fundraiser._id} admin={props.admin} cancelled={fundraiser.cancelled} />
						<p>{ fundraiser.phoneNumber }</p>
						<h5>Delivery Address</h5>
						<span style={{ whiteSpace: 'pre' }}>{ formatAddress(fundraiser.address) }</span>
					</div>
						
						<div className='mt-4'>
							<h5>Order Pickup Location</h5>
							<ChangeFundraiserPickupComponent 
								id={fundraiser._id} 
								pickup={fundraiser.pickups[0]}
								deliveryDate={fundraiser.deliveryDate} 
								index={0} admin={props.admin}
								onPickupChange={onPickupChange}
								cancelled={fundraiser.cancelled} />
						</div>

						{ props.catalog ? <div className='mt-4'>
							<h5>Catalog</h5>
							{props.catalog.name}<br />
							{ props.catalog.pdf ? <a href={`${apiUrl}${props.catalog.pdf}`} target='_blank' rel='noreferrer'>View Catalog Flyer</a> : null } 
						</div> : null }
					</div>
				</div>
			</div>
			
			<ManageFundraiserDatesComponent fundraiser={fundraiser} updateDates={updateDates} />
			
			<Row className='mt-4'>
				{ fundraiser.locked ? <div className='col-md-3'>
						<div className='card p-4'>
							<h4>Fundraiser Locked</h4>
							No further changes to orders can be made
						</div>
					</div> :
					fundraiser.closed ? <div className='col-md-3'>
						<div className='card p-4'>
							<h4>Fundraiser closed</h4>
							No further orders can be accepted. Changes can still be made.
						</div>
					</div> : null }
				
				<div className='col-md-3'>
					<div className='card p-4'>
						<h4>Total Orders</h4>
						{ fundraiser.orders.filter(x => !x.cancelled).length }
					</div>
				</div>
				<div className='col-md-3'>
					<div className='card p-4'>
						<h4>Total Order Value</h4>
						${ totalRaised.toFixed(2) }
					</div>
				</div>

				<div className='col-md-3'>
					<div className='card p-4'>
						<h4>Fundraiser Earned</h4>
						${ (totalRaised * fundraiser.commission).toFixed(2) } ({(fundraiser.commission * 100).toFixed(2)}%)
					</div>
				</div>
			</Row>
			
			<div className='card my-4 p-4'>
				<div className='d-flex flex-row justify-content-between align-items-center mb-2'>
					<h5>Orders</h5>
					<div className='d-flex flex-row gap-2'>
						<Button variant='primary' size='sm' onClick={printTable}>Print Table</Button>
						<Button variant='primary' size='sm' onClick={downloadTable}>Download as CSV</Button>
						<Button variant='secondary' size='sm' onClick={() => setShowingExportModal(true)}>Run another Report</Button>
					</div>
				</div>
				<Table ref={tableRef}>
					<thead>
					<tr>
						<th>ID</th>
						<th>Name</th>
						<th>Order Ref</th>
						<th>Order Value</th>
						<th>Paid Status</th>
						<th>Notes</th>
						<th data-no-csv='true' className='manage-button-col'>Actions</th>
					</tr>
					</thead>
					<tbody>
					{ fundraiser.orders.map(order => <tr key={order._id}>
						<td className={order.cancelled ? 'text-black-50' : ''}>{order.orderReference}</td>
						<td className={order.cancelled ? 'text-black-50' : ''}>{order.name}{` ${order.lastName ? order.lastName : ''}`}</td>
						<td className={order.cancelled ? 'text-black-50' : ''}>{order.referral ?? 'N/A'}</td>
						<td className={order.cancelled ? 'text-black-50' : ''}>${countOrder(order).toFixed(2)}</td>
						<td className={order.cancelled ? 'text-black-50' : ''}>{order.cancelled ? 'Cancelled' : order.paidStatus ? 'Paid' : 'Not Paid'}</td>
						<td className={order.cancelled ? 'text-black-50' : ''}>{order.paidNotes ?? 'N/A'}</td>
						<td data-no-csv='true' className='manage-button-col'>{ !order.cancelled && !fundraiser.cancelled ? <Button variant='primary' size='sm' onClick={() => setManagedOrder(order)}>Manage</Button> : null }</td>
					</tr>) }
					</tbody>
				</Table>
			</div>
		</>
	)
}