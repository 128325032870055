import {CatalogEditableItemComponent} from "../../catalog/CatalogEditableItemComponent.tsx";
import {Button, Table} from "react-bootstrap";
import {ICatalogItem} from "../../../types/CatalogTypes.ts";
import {FieldArray, FormikErrors, FormikTouched, useFormikContext} from "formik";
import {ArrayHelpers} from "formik/dist/FieldArray";
import {ChangeEvent, useEffect} from "react";


interface IProps {
	items: ICatalogItem[];
	values: ICatalogItem[];
	errors?: FormikErrors<ICatalogItem>[] | string[] | string;
	touched?: FormikTouched<ICatalogItem>[] | string[] | string;
	handleChange: (e: ChangeEvent<any>) => any;
}

export function CatalogItemsConfigTableComponent({ items, errors, values, touched, handleChange }: IProps) {
	const {setFieldValue} = useFormikContext();
	useEffect(() => {
		onItemsChange();
	}, []);

	useEffect(() => {
		onItemsChange()
	}, [items]);

	async function onItemsChange() {
		await setFieldValue("items", items);
	}

	return (
		<FieldArray name="items" render={(helpers: ArrayHelpers) => <>
			<Table>
				<thead>
				<tr>
					<th>Internal Name</th>
					<th>Name</th>
					<th>Description</th>
					<th>Image</th>
					<th>Price</th>
					<th></th>
				</tr>
				</thead>
				<tbody>
				{values.map((value, index) =>
					<CatalogEditableItemComponent key={index} index={index} values={value}
												  touched={touched && touched[index]} errors={errors && errors[index]}
												  handleChange={handleChange} deleteItem={() => helpers.remove(index)}/>
				)}
				</tbody>
			</Table>
			<Button onClick={() => helpers.push<ICatalogItem>({_id: '', internalName: '', name: '', description: '', image: '', price: 0})}
					variant='primary' className='w-100 mb-2'>Add Item</Button>
		</>
		}/>
	);
}
