import { Formik, FormikHelpers, FormikProps } from 'formik';
import Form from 'react-bootstrap/Form';
import { Alert, Button, Col, FloatingLabel, Row } from 'react-bootstrap';
import * as yup from 'yup';
import moment from 'moment/moment';
import { forwardRef, useContext, useImperativeHandle, useRef } from 'react';
import { ICreationStepRef } from './CreationOverviewComponent.tsx';
import { calculateCloseDate } from '../../helpers/calculateCloseDate.ts';
import { Tooltip } from '../util/Tooltip.tsx';
import { IStringsContext, StringsContext } from '../../contexts/StringsContext.tsx';
import { PhoneRegex } from '../../util/Regex.ts';

interface IForm {
    name: string;
    email: string;
    startDate: string;
    deliveryDate: string;
    contactName: string;
    phoneNumber: string;
}

interface IFormTypes extends IForm {
    [key: string]: any;
}

interface IProps {
    values?: IFormTypes;
    update?: boolean;
    onStepComplete: (values: { [key: string]: any }) => any;
}

export const CreationStep1Component = forwardRef<ICreationStepRef, IProps>(
    ({ update, values, onStepComplete }, ref) => {
        const formRef = useRef<FormikProps<IForm>>(null);
        const { getString } = useContext<IStringsContext>(StringsContext);
        useImperativeHandle(ref, () => ({
            onUpdateClick() {
                formRef.current?.submitForm();
            }
        }));

        const initialValues: IForm = values ?? {
            name: '',
            email: '',
            contactName: '',
            phoneNumber: '',
            startDate: moment().format('YYYY-MM-DD'),
            deliveryDate: moment().add('60', 'days').format('YYYY-MM-DD')
        };

        function submit(values: IForm, {}: FormikHelpers<IForm>) {
            onStepComplete(values);
        }

        const validation = yup.object().shape({
            name: yup
                .string()
                .required('A fundraiser name is required')
                .min(5, 'Your fundraiser name must be at least 5 characters'),
            email: yup
                .string()
                .email('You must provide a valid email address')
                .required('A contact email address is required'),
            contactName: yup.string().required('A contact name is required'),
            phoneNumber: yup
                .string()
                .required('A contact phone number is required')
                .matches(PhoneRegex, 'A valid phone number is required'),
            startDate: yup
                .date()
                .min(moment().subtract(1, 'day').toDate(), 'Your start date cannot be before today')
                .required('A start date is required'),
            deliveryDate: yup
                .date()
                .required('A delivery date is required')
                .when('startDate', (startDate, yup) =>
                    moment(startDate[0]).isValid()
                        ? // yup.min(addWorkingDays(moment(startDate[0]), 10).toDate(), 'Delivery date must be at least 10 working days after your start date') :
                          yup.test(
                              'Is valid date',
                              'Your latest close date is before your fundraiser starts',
                              (value) => {
                                  const latestCloseDate = calculateCloseDate(value.toDateString());
                                  return latestCloseDate.toDate() > startDate[0];
                              }
                          )
                        : yup
                )
        });

        return (
            <Formik<IForm>
                initialValues={initialValues}
                onSubmit={submit}
                validationSchema={validation}
                innerRef={formRef}
            >
                {({ handleSubmit, handleChange, values, touched, errors }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <p>Let's start by getting some information about your fundraiser.</p>

                        <Form.Group className='pb-3'>
                            <Tooltip text={getString('tooltip.fundraiser.name')}>
                                <FloatingLabel label='Fundraiser Name'>
                                    <Form.Control
                                        id='name'
                                        type='text'
                                        name='name'
                                        placeholder='Fundraiser Name'
                                        value={values.name}
                                        isInvalid={touched.name && !!errors.name}
                                        onChange={handleChange}
                                    />
                                    <Form.Control.Feedback type='invalid'>{errors.name}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Tooltip>
                        </Form.Group>

                        <Form.Group className='pb-3'>
                            <Tooltip text={getString('tooltip.contact.name')}>
                                <FloatingLabel label='Contact Name'>
                                    <Form.Control
                                        id='contactName'
                                        type='text'
                                        name='contactName'
                                        value={values.contactName}
                                        placeholder='Contact Name'
                                        isInvalid={touched.contactName && !!errors.contactName}
                                        required
                                        onChange={handleChange}
                                    />
                                    <Form.Control.Feedback type='invalid'>{errors.contactName}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Tooltip>
                        </Form.Group>

                        <Form.Group className='pb-3'>
                            <Tooltip text={getString('tooltip.contact.phone')}>
                                <FloatingLabel label='Contact Phone Number'>
                                    <Form.Control
                                        id='contactPhone'
                                        type='text'
                                        name='phoneNumber'
                                        value={values.phoneNumber}
                                        placeholder='Contact Phone Number'
                                        isInvalid={touched.phoneNumber && !!errors.phoneNumber}
                                        required
                                        onChange={handleChange}
                                    />
                                    <Form.Control.Feedback type='invalid'>{errors.phoneNumber}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Tooltip>

                            <Form.Text muted>
                                This phone number will be used by us if we need to get in touch with you regarding your
                                fundraiser and will be given out with each order placed.
                            </Form.Text>
                        </Form.Group>

                        <Form.Group className='pb-3'>
                            <Tooltip text={getString('tooltip.contact.email')}>
                                <FloatingLabel label='Contact Email Address'>
                                    <Form.Control
                                        id='contactEmail'
                                        type='email'
                                        name='email'
                                        value={values.email}
                                        placeholder='Contact Email'
                                        isInvalid={touched.email && !!errors.email}
                                        required
                                        onChange={handleChange}
                                    />
                                    <Form.Control.Feedback type='invalid'>{errors.email}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Tooltip>

                            <Form.Text muted>
                                Provide an email address which we can contact you on. You will receive your fundraiser
                                login information via an email when accepted.
                            </Form.Text>
                        </Form.Group>

                        <Row>
                            <Col md={6}>
                                <Form.Group className='pb-3'>
                                    <Tooltip text={getString('tooltip.fundraiser.startDate')}>
                                        <FloatingLabel label='Fundraiser Opening Date'>
                                            <Form.Control
                                                id='startDate'
                                                type='date'
                                                name='startDate'
                                                value={values.startDate}
                                                placeholder='Start Date'
                                                required
                                                onChange={handleChange}
                                                isInvalid={touched.startDate && !!errors.startDate}
                                            />
                                            <Form.Control.Feedback type='invalid'>
                                                {errors.startDate}
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Tooltip>
                                </Form.Group>
                            </Col>

                            <Col md={6}>
                                <Form.Group className='pb-3'>
                                    <Tooltip text={getString('tooltip.fundraiser.deliveryDate')}>
                                        <FloatingLabel label='Delivery Date'>
                                            <Form.Control
                                                id='deliveryDate'
                                                type='date'
                                                name='deliveryDate'
                                                value={values.deliveryDate}
                                                placeholder='Delivery Date'
                                                required
                                                onChange={handleChange}
                                                isInvalid={!!errors.deliveryDate}
                                            />
                                            <Form.Control.Feedback type='invalid'>
                                                {errors.deliveryDate}
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Tooltip>
                                    <div className='mt-2'>
                                        <Form.Text>
                                            The fundraiser closing date will be approximately 10 days prior to the
                                            delivery date.{' '}
                                            {values.deliveryDate ? (
                                                <>
                                                    <br />
                                                    Estimated closing date:{' '}
                                                    <strong>
                                                        {calculateCloseDate(values.deliveryDate).format('DD/MM/YYYY')}
                                                    </strong>
                                                </>
                                            ) : null}
                                        </Form.Text>
                                    </div>
                                </Form.Group>
                            </Col>

                            <Alert variant='info' className='pb-3'>
                                Before continuing, please familiarise yourself with our{' '}
                                <a
                                    href='https://pasta.co.nz/fundraising/fundraising-conditions-of-delivery/'
                                    target='_blank'
                                >
                                    Fundraising Conditions of Delivery
                                </a>
                                .
                            </Alert>

                            {calculateCloseDate(values.deliveryDate).toDate() < new Date(values.startDate) ? (
                                <Alert variant='danger'>
                                    Invalid start date and delivery date configuration. <br />
                                    Estimated close date of{' '}
                                    {calculateCloseDate(values.deliveryDate).format('DD/MM/YYYY')} is before your start
                                    date.
                                </Alert>
                            ) : null}
                        </Row>

                        {!update ? (
                            <div className='d-flex flex-row justify-content-end'>
                                <Button type='submit'>Next</Button>
                            </div>
                        ) : null}
                    </Form>
                )}
            </Formik>
        );
    }
);
