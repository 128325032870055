import {IAddress} from '../types/FundraiserTypes.ts';

export function formatAddress(address: IAddress) {
    let addr = '';
    addr += address.addressLine1 + '\n';
    if (address.addressLine2) {
        addr += address.addressLine2 + '\n';
    }

    if (address.addressLine3) {
        addr += address.addressLine3 + '\n';
    }

    if (address.town) {
        addr += address.town + '\n';
    }

    if (address.region) {
        addr += address.region + '\n';
    }

    if (address.postcode) {
        addr += address.postcode + '\n';
    }

    return addr;
}