import {Card, Col, Row} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import {Formik, FormikHelpers} from "formik";
import {LoadingButtonComponent} from "../../components/util/LoadingButtonComponent.tsx";
import * as yup from 'yup';
import {apiAdminUpdateConfiguration} from "../../api.ts";
import {toast} from "react-toastify";
import {parseApiErrors} from '../../helpers/parseApiErrors.ts';

interface IForm {
	currentPassword: string;
	newPassword: string;
	newPasswordRetype: string;
	newEmail: string;
}

export function AdminManageConfiguration() {
	async function onSubmit(values: IForm, { setErrors }: FormikHelpers<IForm>) {
		const response = await apiAdminUpdateConfiguration(values);
		if (!response.success) {
			if (response.errors) {
				const errors = parseApiErrors(response.errors);

				setErrors(errors);
			}
			
			if (response.message) {
				toast.error(response.message);
			}
		} else {
			toast.success('Updated successfully');
		}
	}
	
	const schema = yup.object().shape({
		currentPassword: yup.string().required(),
		newPassword: yup.string().optional(),
		newPasswordRetype: yup.string().optional(),
		newEmail: yup.string().email('Must provide a valid email address').optional()
	});
	
	return (
		<Row>
			<Card>
				<Card.Body>
					<Formik<IForm> initialValues={{ currentPassword: '', newPassword: '', newEmail: '', newPasswordRetype: '' }} onSubmit={onSubmit} validationSchema={schema}>
						{({ handleSubmit, handleChange, values, errors, touched, isSubmitting }) => 
							<Form noValidate onSubmit={handleSubmit}>
								<Form.Group className="mt-4">
									<Form.Label htmlFor='currentPassword'>Current Password</Form.Label>
									<Form.Control type='password' name='currentPassword' id='currentPassword' onChange={handleChange} value={values.currentPassword} isInvalid={touched.currentPassword && !!errors.currentPassword} />
									<Form.Control.Feedback type='invalid'>{errors.currentPassword}</Form.Control.Feedback>
									<Form.Text>The current admin password is required to make any changes</Form.Text>
								</Form.Group>
	
								<Row>
									<Col md={6}>
										<Form.Group className="mt-4">
											<Form.Label htmlFor='newEmail'>New Email</Form.Label>
											<Form.Control type='email' name='newEmail' id='newEmail' onChange={handleChange} value={values.newEmail} isInvalid={touched.newEmail && !!errors.newEmail}/>
											<Form.Control.Feedback type='invalid'>{errors.newEmail}</Form.Control.Feedback>
											<Form.Text>The email address to send any administrative related emails to</Form.Text>
											<Form.Text className='d-block'>Leave blank to keep the same</Form.Text>
										</Form.Group>
									</Col>
									<Col md={6}>
										<Form.Group className="mt-4">
											<Form.Label htmlFor='newPassword'>New Password</Form.Label>
											<Form.Control type='password' name='newPassword' id='newPassword' onChange={handleChange} value={values.newPassword} isInvalid={touched.newPassword && !!errors.newPassword} />
											<Form.Control.Feedback type='invalid'>{errors.newPassword}</Form.Control.Feedback>
											<Form.Text>The password required to access the admin panel</Form.Text>
											<Form.Text className='d-block'>Leave blank to keep the same</Form.Text>
										</Form.Group>
										<Form.Group className="mt-4">
											<Form.Label htmlFor='newPasswordRetype'>Confirm New Password</Form.Label>
											<Form.Control type='password' name='newPasswordRetype' id='newPasswordRetype' onChange={handleChange} value={values.newPasswordRetype} isInvalid={touched.newPasswordRetype && !!errors.newPasswordRetype} />
											<Form.Control.Feedback type='invalid'>{errors.newPasswordRetype}</Form.Control.Feedback>
										</Form.Group>
									</Col>
								</Row>
	
								<LoadingButtonComponent loading={isSubmitting} type='submit'>Update</LoadingButtonComponent>
							</Form>
						}
					</Formik>
					
				</Card.Body>
			</Card>
		</Row>
	)
}