import {ICatalogItem} from "./CatalogTypes.ts";

export type FundraiserWithOrder = IFundraiser & { orders: IOrder[] };

export enum RequestStatus {
    PENDING = 'PENDING',
    DECLINED = 'DECLINED'
}

export interface IFundraiserRequest {
    _id: string;
    name: string;
    contactName: string;
    phoneNumber: string;
    email: string;
    startDate: Date;
    deliveryDate: Date;
    address: IAddress;
    catalog: string;

    pickups: IPickup[];
    paymentMethods: {
        cash: boolean;
        bankTransfer: boolean;
    }
    paymentNotes: {
        cash: string;
        bankTransfer: string;
        bankTransferAccount: string;
    }
    status: RequestStatus;
}

export interface IAddress {
    companyName: string;
    addressLine1: string;
    addressLine2: string;
    addressLine3: string;
    town: string;
    postcode: string;
    region: string;
}

export interface IFundraiser {
    _id: string;
    name: string;
    contactName: string;
    phoneNumber: string;
    slug: string;
    email: string;
    // organisation: IOrganisation;
    // wholesaler: any; // todo
    catalog: string;
    catalogItems: ICatalogItem[];
    startDate: Date;
    endDate: Date;
    deliveryDate: Date;
    // usesParticipant: boolean;
    // participants: any[]; //todo
    //goal: any;
    address: IAddress;
    pickups: (IPickup & { _id: string; })[];
    paymentMethods: {
        cash: boolean;
        bankTransfer: boolean;
    }
    paymentNotes: {
        cash: string;
        bankTransfer: string;
        bankTransferAccount: string;
    }
    closed: boolean;
    locked: boolean;
    commission: number;
    cancelled: boolean;
}

export interface IOrder {
    _id: string;
    fundraiser: string;
    //participant: any // todo
    orderReference: string;
    name: string;
    lastName: string;
    email: string;
    phone: string;
    // address: string;
    paidStatus: boolean;
    paidNotes?: string;
    items: {
        itemId: string;
        amount: number;
    }[]
    refForBank: string;
    notes: string;
    pickup: string;
    cancelled: boolean;
    referral: string;
}

export interface IPickup {
    _id: string;
    location: string;
    startTime: Date;
    endTime: Date;
}