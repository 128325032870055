import {OverlayTrigger, Tooltip as BSTooltip} from 'react-bootstrap';
import {ReactElement} from 'react';

interface IProps {
	text: string;
	children: ReactElement;
}

export function Tooltip({text, children}: IProps) {
	return (
		<OverlayTrigger placement='top' overlay={<BSTooltip>{text}</BSTooltip>}>{children}</OverlayTrigger>
	)
}