import {IAddress, IFundraiserRequest, IPickup} from '../../../types/FundraiserTypes.ts';
import {ICatalog, ICatalogItem} from '../../../types/CatalogTypes.ts';
import {useState} from 'react';
import {apiAdminApproveRequest, apiAdminDeclineRequest} from '../../../api.ts';
import {toast} from 'react-toastify';
import {NavLink, useNavigate} from 'react-router-dom';
import moment from 'moment/moment';
import {Button, Card, Col} from 'react-bootstrap';
import {LoadingButtonComponent} from '../../util/LoadingButtonComponent.tsx';
import {CreationOverviewComponent} from '../../createFundraiser/CreationOverviewComponent.tsx';
import {IError} from '../../../types/ApiTypes.ts';
import {calculateCloseDate} from '../../../helpers/calculateCloseDate.ts';

interface IProps {
	request: IFundraiserRequest;
	catalogs: ICatalog[];
}

interface IForm extends IAddress {
	id: string;
	name: string;
	contactName: string;
	phoneNumber: string;
	startDate: string;
	deliveryDate: string;
	endDate: string;
	catalog: string;
	customPricing: boolean;
	items: ICatalogItem[];
	commission: number;
	email: string;
	cashPayment: boolean;
	transferPayment: boolean;
	cashPaymentNotes: string;
	transferPaymentNotes: string;
	transferPaymentAccount: string;
	pickups: IPickup[];
}

export function AdminManageRequestComponent({ catalogs, ...props }: IProps) {
	const navigate = useNavigate();
	const [ submitting, setSubmitting ] = useState(false);
	const [ errors, setErrors ] = useState<IError[]>();
	const [ request, setRequest ] = useState<IForm>({
		id: props.request._id,
		contactName: props.request.contactName,
		phoneNumber: props.request.phoneNumber,
		name: props.request.name,
		startDate: moment(props.request.startDate).format('YYYY-MM-DD'),
		endDate: calculateCloseDate(moment(props.request.deliveryDate).format('YYYY-MM-DD')).format('YYYY-MM-DD'),
		deliveryDate: moment(props.request.deliveryDate).format('YYYY-MM-DD'),
		catalog: props.request.catalog,
		customPricing: false,
		commission: 0.2,
		items: [],
		...props.request.address,
		email: props.request.email,
		cashPayment: props.request.paymentMethods.cash,
		cashPaymentNotes: props.request.paymentNotes.cash,
		transferPayment: props.request.paymentMethods.bankTransfer,
		transferPaymentNotes: props.request.paymentNotes.bankTransfer,
		pickups: props.request.pickups,
		transferPaymentAccount: props.request.paymentNotes.bankTransferAccount
	});

	async function decline() {
		const response = await apiAdminDeclineRequest(request.id);
		if (!response.success) {
			// const errors = parseApiErrors(response.errors);

			// setErrors(errors);

			if (response.message) {
				toast.error(response.message);
			}
			return;
		}

		toast.warn('Fundraiser declined');
		navigate('/admin/requests');
	}
	
	async function onSubmit() {
		setSubmitting(true);
		try {
			const response = await apiAdminApproveRequest(request);
			if (response.success) {
				toast.success('Fundraiser Accepted!');
				navigate('/admin/requests');
			} else {
				// const errors = parseApiErrors(response.errors);
				const idError = response.errors?.find(x => x.path === 'id');
				if (idError) {
					toast.error(idError.msg);
				}

				setErrors(response.errors);

				if (response.message) {
					toast.error(response.message);
				}
			}
		} finally {
			setSubmitting(false);
		}
	}

	function onStepComplete(data: { [key: string]: any }) {
		setRequest(req => {
			const newReq = { ...req };
			for (let key in data) {
				// @ts-ignore
				newReq[key] = data[key];
			}

			return newReq;
		});
	}
	
	return <div className='index-wrapper d-flex justify-content-center align-items-center'>
		<Col md={6}>
			<NavLink to='/admin/requests'>Back</NavLink>
			<h3>Manage Request</h3>
			<Card>
				<CreationOverviewComponent apiErrors={errors} catalogs={catalogs} request={request} onStepComplete={onStepComplete} onRequestCreated={() => {}} adminData={request} />

				<div className='d-flex flex-row gap-2 my-4 justify-content-end'>
					<Button variant='danger' onClick={decline} className='w-20'>Decline</Button>
					<LoadingButtonComponent loading={submitting} variant='primary' onClick={onSubmit} className='w-20'>Accept</LoadingButtonComponent>
				</div>
			</Card>
		</Col>
	</div>
}