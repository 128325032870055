import { useCallback, useEffect, useState } from 'react';
import { Card, Table } from 'react-bootstrap';
import { IUserList } from '../../../types/ApiTypes';
import { LoadingComponent } from '../../../components/util/LoadingComponent';
import { apiAdminGetUserList, apiAdminSendPasswordReset } from '../../../api';
import { LoadingButtonComponent } from '../../../components/util/LoadingButtonComponent';
import { toast } from 'react-toastify';

export function AdminUsersList() {
    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState<IUserList[]>([]);
    const [sendingTo, setSendingTo] = useState('');

    useEffect(() => {
        apiAdminGetUserList()
            .then((r) => {
                if (r.success) {
                    setUsers(r.data);
                }
            })
            .then(() => setLoading(false));
    }, []);

    const sendPasswordReset = useCallback(async (id: string) => {
        setSendingTo(id);
        try {
            const response = await apiAdminSendPasswordReset(id);
            if (response.success) {
                toast.success('Reset password email sent');
            } else {
                toast.error('Failed to send reset password email');
            }
        } finally {
            setSendingTo('');
        }
    }, []);

    if (loading) {
        return <LoadingComponent />;
    }

    return (
        <>
            <div className='r-header'>
                <h3>Users List</h3>
            </div>

            <Card className='p-4'>
                <Table>
                    <thead>
                        <tr>
                            <th>Fundraiser Name</th>
                            <th>Email</th>
                            <th>Username</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map((user) => (
                            <tr key={user.id}>
                                <td>{user.name}</td>
                                <td>{user.email}</td>
                                <td>{user.username}</td>
                                <td>
                                    <LoadingButtonComponent
                                        loading={sendingTo === user.id}
                                        disabled={!!sendingTo}
                                        onClick={() => sendPasswordReset(user.id)}
                                        style={{ whiteSpace: 'nowrap' }}
                                    >
                                        Send Password Reset
                                    </LoadingButtonComponent>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Card>
        </>
    );
}
