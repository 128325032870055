import {Button} from "react-bootstrap";
import {ButtonProps} from "react-bootstrap/Button";
import {ReactNode} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from '@fortawesome/free-solid-svg-icons';

interface IProps extends ButtonProps {
	loading: boolean;
	children: ReactNode;
}

export function LoadingButtonComponent(props: IProps) {
	const { loading, ...buttonProps } = props;
	return (
		<Button {...buttonProps} disabled={props.disabled || props.loading}>{ props.loading ? <FontAwesomeIcon icon={faSpinner} spin /> : props.children }</Button>
	)
}