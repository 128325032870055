import {createContext, ReactNode, useEffect, useState} from 'react';
import {IString} from '../types/ApiTypes.ts';
import {apiGetStrings} from '../api.ts';

interface IProps {
	children: ReactNode;
}

export interface IStringsContext {
	getString: (key: string) => string;
	setStrings: (strings: IString[]) => any;
	strings: IString[]
}

export const StringsContext = createContext<IStringsContext>({
	getString: (key: string) => `Unknown ${key}`,
	setStrings: (_) => {},
	strings: []
});

export default function StringsProvider({ children }: IProps) {
	const [ strings, setStrings ] = useState<IString[]>([]);
	
	useEffect(() => {
		apiGetStrings().then(response => {
			setStrings(response.data);
		})
	}, []);
	
	function getString(key: string) {
		const str = strings.find(x => x.key === key);
		if (str) {
			return str.value;
		}
		
		return `Unknown ${key}`;
	}
	
	return <StringsContext.Provider value={{ strings, getString, setStrings }}>{children}</StringsContext.Provider>
}