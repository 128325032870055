import {useState} from "react";
import {Button} from "react-bootstrap";
import {LoadingButtonComponent} from "../../util/LoadingButtonComponent.tsx";
import {Formik, FormikHelpers} from "formik";
import * as yup from 'yup';
import Form from "react-bootstrap/Form";
import {parseApiErrors} from '../../../helpers/parseApiErrors.ts';
import {apiAdminUpdateFundraiserEmail, apiUpdateFundraiserEmail} from '../../../api.ts';
import {toast} from 'react-toastify';

interface IProps {
	id: string;
	email: string;
	admin: boolean;
	onEmailChange: (newEmail: string) => any;
	cancelled: boolean;
}

interface IForm {
	id: string;
	email: string;
}

export function ChangeFundraiserEmailComponent({ id, admin, email, onEmailChange}: IProps) {
	const [ changingEmail, setChangingEmail ] = useState(false);

	async function submitForm(values: IForm, { setErrors }: FormikHelpers<IForm>) {
		const response = await (admin ? apiAdminUpdateFundraiserEmail(values) : apiUpdateFundraiserEmail(values.email));

		if (response.success) {
			onEmailChange(values.email);
			setChangingEmail(false);
			toast.success('Email updated');
		} else {
			if (response.errors) {
				const errors = parseApiErrors(response.errors);

				setErrors(errors);
			}
		}
	}

	const schema = yup.object().shape({
		email: yup.string().email('You must provide a valid email address').required('An email is required')
	});

	return <div className='d-flex flex-row justify-content-between align-items-center my-2' style={{ height: 75 }}>
		{ changingEmail ?
			<Formik<IForm> initialValues={{ id: id, email: email }} onSubmit={submitForm} validationSchema={schema}>
				{({ handleSubmit, handleChange, values, touched, errors, isSubmitting }) => (
					<Form noValidate onSubmit={handleSubmit} className='w-100 d-flex flex-row justify-content-between align-items-center my-2'>
						<Form.Group>
							<Form.Control type='email' name='email' value={values.email} onChange={handleChange} isInvalid={touched.email && !!errors.email} />
							<Form.Control.Feedback type='invalid'>{errors.email}</Form.Control.Feedback>
						</Form.Group>
						<div className='d-flex flex-row gap-2'>
							<Button onClick={() => setChangingEmail(false)} variant='danger'>Cancel</Button>
							<LoadingButtonComponent loading={isSubmitting} type='submit'>Update</LoadingButtonComponent>
						</div>
					</Form>
				)}
			</Formik> :
			<>
				<p>{email}</p>
			</>
		}
	</div>
}