import { IFundraiser } from '../../types/FundraiserTypes.ts';
import Form from 'react-bootstrap/Form';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { useContext, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import * as yup from 'yup';
import { FieldArray, Formik, FormikHelpers } from 'formik';
import { LoadingButtonComponent } from '../util/LoadingButtonComponent.tsx';
import { OrderableItemComponent } from './order/OrderableItemComponent.tsx';
import { apiCreateOrder } from '../../api.ts';
import { toast } from 'react-toastify';
import { useTitle } from '../../hooks/useTitle.ts';
import { parseApiErrors } from '../../helpers/parseApiErrors.ts';
import { Tooltip } from '../util/Tooltip.tsx';
import { IStringsContext, StringsContext } from '../../contexts/StringsContext.tsx';
import { NameRegex, PhoneRegex } from '../../util/Regex.ts';
import { formatPickupLocation } from '../../helpers/formatPickupLocation.ts';

interface IProps {
    fundraiser: IFundraiser;
    demo: boolean;
}

interface IForm {
    id: string;
    name: string;
    lastName: string;
    email: string;
    pickup: string;
    phone: string;
    orderRef: string;
    marketing: boolean;
    items: { [key: string]: number };
    island: string;
}

export function FundraiserOrderComponent({ fundraiser, demo }: IProps) {
    const [orderedItems, setOrderedItems] = useState<{ [key: string]: number }>({});
    const [agreedToPay, setAgreedToPay] = useState(false);
    const [orderPlaced, setOrderPlaced] = useState(false);
    const { getString } = useContext<IStringsContext>(StringsContext);
    useTitle(fundraiser.name);

    useEffect(() => {
        const catalogItems = fundraiser.catalogItems;

        const d: { [key: string]: number } = {};
        for (let item of catalogItems) {
            d[item._id] = 0;
        }

        setOrderedItems(d);
    }, [fundraiser.catalogItems]);

    const cost = useMemo(() => {
        let total = 0;

        const catalogItems = fundraiser.catalogItems;
        for (let key in orderedItems) {
            if (!orderedItems.hasOwnProperty(key)) {
                continue;
            }

            const individualCost = catalogItems.find((x) => x._id === key)?.price ?? 0;
            total += individualCost * orderedItems[key];
        }

        return total;
    }, [orderedItems, fundraiser.catalogItems]);

    function setItemAmount(id: string, amount: string | number) {
        const num = parseInt(amount.toString());
        setOrderedItems((old) => {
            return {
                ...old,
                [id]: isNaN(num) ? 0 : num
            };
        });
    }

    async function submit(values: IForm, { setErrors }: FormikHelpers<IForm>) {
        const response = await apiCreateOrder(values, demo);

        if (response.success) {
            setOrderPlaced(true);
        } else {
            if (response.errors) {
                const errors = parseApiErrors(response.errors);
                const idError = response.errors?.find((x) => x.path === 'id');
                if (idError) {
                    toast.error(idError.msg);
                }

                setErrors(errors);
            }

            if (response.message) {
                toast.error(response.message);
            }
        }
    }

    if (orderPlaced) {
        return (
            <>
                <div className='index-wrapper d-flex justify-content-center align-items-center'>
                    <div className='col-lg-4 col-md-8'>
                        <div className='card text-center p-4'>
                            <h4>{fundraiser.name}</h4>
                            <strong>Order successfully placed!</strong>
                            <p>Check your emails for confirmation and a reminder of payment information.</p>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    if (fundraiser.closed) {
        return (
            <>
                <div className='index-wrapper d-flex justify-content-center align-items-center'>
                    <div className='col-lg-4 col-md-8'>
                        <div className='card text-center p-4'>
                            <h4>This fundraiser has closed</h4>
                            <p>This fundraiser has closed and will not be accepting any more orders.</p>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    const schema = yup.object().shape({
        name: yup
            .string()
            .required('Your name is required')
            .matches(NameRegex, 'First names can only contain letters, spaces or hyphens'),
        lastName: yup
            .string()
            .required('Your last name is required')
            .matches(NameRegex, 'Last names can only contain letters, spaces or hyphens'),
        email: yup.string().email('A valid email is required').required('Your email is required'),
        pickup: yup
            .string()
            .oneOf(
                fundraiser.pickups.map((x) => x._id),
                'You must select a pickup from the list provided'
            )
            .required('A pickup location is required'),
        phone: yup
            .string()
            .matches(PhoneRegex, 'You must provide a valid phone number')
            .required('Your phone number is required'),
        orderRef: yup.string().optional(),
        marketing: yup.boolean(),
        items: yup.object().required('You must order at least one item'),
        island: yup
            .string()
            .required('You must select which island you are ordering from')
            .oneOf(['north', 'south'], 'You must select which island you are ordering from')
    });

    const defaultItemValues: { [key: string]: number } = {};
    for (let item of fundraiser.catalogItems) {
        defaultItemValues[item._id] = 0;
    }

    return (
        <>
            <Formik<IForm>
                initialValues={{
                    id: fundraiser._id,
                    name: '',
                    lastName: '',
                    email: '',
                    pickup: fundraiser.pickups[0]._id,
                    phone: '',
                    orderRef: '',
                    marketing: true,
                    items: defaultItemValues,
                    island: ''
                }}
                onSubmit={submit}
                validationSchema={schema}
            >
                {({ handleSubmit, handleChange, values, touched, errors, isSubmitting }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Container>
                            <Row className='justify-content-center'>
                                <Col lg={8} className='p-4 card m-3'>
                                    <h4>{fundraiser.name}</h4>

                                    {/* <div className="cards pt-2 pb-2">
										{fundraiser.paymentMethods.cash ?
											<Badge className='mx-1'>Accepts Cash Payments</Badge> : null}
										{fundraiser.paymentMethods.bankTransfer ?
											<Badge className='mx-1'>Accepts Bank Transfers</Badge> : null}
										<Badge className='mx-1'>Delivers
											approx. {moment(fundraiser.deliveryDate).format('DD-MM-YYYY')}</Badge>
										<Badge className='mx-1'>Closes {moment(fundraiser.endDate).format('DD-MM-YYYY')}</Badge>
									</div> */}

                                    {fundraiser.startDate > new Date() ? (
                                        <div className='mt-4'>
                                            <h6>This fundraiser has not started yet!</h6>
                                            <p>Check back on {moment(fundraiser.startDate).format('DD MMM, YYYY')}</p>
                                        </div>
                                    ) : (
                                        <>
                                            <p>
                                                To place an order for your fresh pasta select a pickup location and date
                                                from the list below and then add the types of pasta you would like to
                                                order.
                                            </p>

                                            <div className='row'>
                                                <Tooltip text={getString('tooltip.order.name')}>
                                                    <Form.Group className='col-sm-6'>
                                                        <Form.Label htmlFor='name'>First Name</Form.Label>
                                                        <Form.Control
                                                            name='name'
                                                            id='name'
                                                            value={values.name}
                                                            onChange={handleChange}
                                                            isInvalid={touched.name && !!errors.name}
                                                        />
                                                        <Form.Control.Feedback type='invalid'>
                                                            {errors.name}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Tooltip>

                                                <Tooltip text={getString('tooltip.order.lastname')}>
                                                    <Form.Group className='col-sm-6'>
                                                        <Form.Label htmlFor='lastName'>Last Name</Form.Label>
                                                        <Form.Control
                                                            name='lastName'
                                                            id='lastName'
                                                            value={values.lastName}
                                                            onChange={handleChange}
                                                            isInvalid={touched.lastName && !!errors.lastName}
                                                        />
                                                        <Form.Control.Feedback type='invalid'>
                                                            {errors.lastName}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Tooltip>
                                            </div>

                                            <Tooltip text={getString('tooltip.order.pickup')}>
                                                <Form.Group>
                                                    <Form.Label htmlFor='pickup'>Pickup</Form.Label>
                                                    <Form.Select
                                                        name='pickup'
                                                        id='pickup'
                                                        value={values.pickup}
                                                        onChange={handleChange}
                                                        isInvalid={touched.pickup && !!errors.pickup}
                                                    >
                                                        <option value=''>Select a pickup location</option>
                                                        {fundraiser.pickups.map((pickup) => (
                                                            <option key={pickup._id} value={pickup._id}>
                                                                {formatPickupLocation(pickup)}
                                                            </option>
                                                        ))}
                                                    </Form.Select>
                                                    <Form.Control.Feedback type='invalid'>
                                                        {errors.pickup}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Tooltip>

                                            <Tooltip text={getString('tooltip.order.email')}>
                                                <Form.Group>
                                                    <Form.Label htmlFor='email'>Email Address</Form.Label>
                                                    <Form.Control
                                                        type='email'
                                                        name='email'
                                                        id='email'
                                                        value={values.email}
                                                        onChange={handleChange}
                                                        isInvalid={touched.email && !!errors.email}
                                                    />
                                                    <Form.Control.Feedback type='invalid'>
                                                        {errors.email}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Tooltip>

                                            <Tooltip text={getString('tooltip.order.phone')}>
                                                <Form.Group>
                                                    <Form.Label htmlFor='phone'>Phone Number</Form.Label>
                                                    <Form.Control
                                                        type='tel'
                                                        name='phone'
                                                        id='phone'
                                                        value={values.phone}
                                                        onChange={handleChange}
                                                        isInvalid={touched.phone && !!errors.phone}
                                                    />
                                                    <Form.Control.Feedback type='invalid'>
                                                        {errors.phone}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Tooltip>

                                            <Tooltip text={getString('tooltip.order.ref')}>
                                                <Form.Group>
                                                    <Form.Label htmlFor='orderRef'>Order Ref.</Form.Label>
                                                    <Form.Control
                                                        type='text'
                                                        name='orderRef'
                                                        id='orderRef'
                                                        value={values.orderRef}
                                                        onChange={handleChange}
                                                        isInvalid={touched.orderRef && !!errors.orderRef}
                                                    />
                                                    <Form.Control.Feedback type='invalid'>
                                                        {errors.orderRef}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Tooltip>

                                            <Tooltip text={getString('tooltip.order.island')}>
                                                <Form.Group>
                                                    <Form.Label htmlFor='island'>Island</Form.Label>
                                                    <Form.Select
                                                        name='island'
                                                        id='island'
                                                        value={values.island}
                                                        onChange={handleChange}
                                                        isInvalid={touched.island && !!errors.island}
                                                    >
                                                        <option value=''>Select which Island</option>
                                                        <option value='north'>North Island</option>
                                                        <option value='south'>South Island</option>
                                                    </Form.Select>
                                                    <Form.Control.Feedback type='invalid'>
                                                        {errors.island}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Tooltip>

                                            <Tooltip text={getString('tooltip.order.marketing')}>
                                                <Form.Check
                                                    name='marketing'
                                                    className='mt-3'
                                                    id='marketing'
                                                    label='I would like to receive email communication about your fundraisers, special offers, new products and news.'
                                                    isInvalid={touched.marketing && !!errors.marketing}
                                                    feedback={errors.marketing}
                                                    checked={values.marketing}
                                                    onChange={handleChange}
                                                />
                                            </Tooltip>
                                            {/*<small>Personal information will not be passed on or sold to any third party, it is for*/}
                                            {/*	the sole use of Pasta Vera Limited.</small>*/}

                                            <hr />
                                            <Container fluid className='mt-4'>
                                                <Row className='g-2'>
                                                    <FieldArray
                                                        name='items'
                                                        render={() =>
                                                            fundraiser.catalogItems.map((item) => (
                                                                <OrderableItemComponent
                                                                    key={item._id}
                                                                    item={item}
                                                                    value={values.items[item._id]}
                                                                    handleChange={(x) => {
                                                                        setItemAmount(item._id, x.target.value);
                                                                        handleChange(x);
                                                                    }}
                                                                    onValueChange={(value) =>
                                                                        setItemAmount(item._id, value)
                                                                    }
                                                                />
                                                            ))
                                                        }
                                                    />
                                                </Row>
                                            </Container>
                                        </>
                                    )}
                                </Col>
                                {fundraiser.startDate < new Date() ? (
                                    <Col lg={3}>
                                        {/*<div className='d-flex flex-column align-items-end'>*/}
                                        <Card className='sticky-top' style={{ top: 20 }}>
                                            <Card.Body>
                                                <Card.Title>Total: ${cost.toFixed(2)}</Card.Title>
                                                <Card.Text>
                                                    {fundraiser.paymentNotes.cash ? (
                                                        <>
                                                            <strong>Cash Payment Information</strong>
                                                            <p>{fundraiser.paymentNotes.cash}</p>
                                                        </>
                                                    ) : null}
                                                    {fundraiser.paymentNotes.bankTransfer ? (
                                                        <>
                                                            <strong>Bank Transfer Account Number</strong>
                                                            <p>{fundraiser.paymentNotes.bankTransferAccount}</p>
                                                            <strong>Bank Transfer Payment Information</strong>
                                                            <p>{fundraiser.paymentNotes.bankTransfer}</p>
                                                        </>
                                                    ) : null}
                                                    <strong>Your order reference will be emailed to you.</strong>
                                                    <Form.Check
                                                        id='agreeToPay'
                                                        label='I agree to pay via any of the available methods, giving my order reference as confirmation'
                                                        checked={agreedToPay}
                                                        onChange={(x) => setAgreedToPay(x.target.checked)}
                                                    />
                                                </Card.Text>
                                                <LoadingButtonComponent
                                                    loading={isSubmitting}
                                                    type='submit'
                                                    variant='primary'
                                                    className='w-100'
                                                    disabled={!agreedToPay}
                                                >
                                                    Place Order
                                                </LoadingButtonComponent>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                ) : null}
                            </Row>
                        </Container>
                    </Form>
                )}
            </Formik>
        </>
    );
}
