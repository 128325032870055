import {ChangeEvent, useRef, useState} from "react";
import {toast} from "react-toastify";
import {apiAdminUploadFile} from "../../api.ts";
import {Button} from "react-bootstrap";

export enum FileType {
	Image = 'Image',
	PDF = 'PDF'
}

interface IProps {
	fileType: FileType;
	onFileUpload: (url: string) => any;
}

export function FileUploadComponent({ onFileUpload, fileType }: IProps) {
	const [ _, setError ] = useState<string>();
	const inputRef = useRef<HTMLInputElement>(null);
	async function onFileChange(e: ChangeEvent<HTMLInputElement>) {
		if (!e.target.files) {
			return;
		}

		const file = e.target.files[0];

		e.target.value = '';
		if ((fileType === FileType.Image && !file.type.match(/image.*/)) || (fileType === FileType.PDF && file.type !== 'application/pdf')) {
			toast.error(`You can only upload ${fileType}s!`);
			return;
		}

		const response = await apiAdminUploadFile(file, fileType);
		if (!response.success && response.message) {
			setError(response.message);
			toast.error(response.message);
		} else if (response.success) {
			onFileUpload(response.data);
		}
	}

	return (
		// <Form.Control type='file' onChange={onFileChange} />
		<>
			<Button variant='secondary' onClick={() => inputRef.current?.click()}>Upload {fileType}</Button>
			<input type='file' className='visually-hidden' ref={inputRef} onChange={onFileChange} />
		</>
	

)
}
