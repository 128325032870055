import {useNavigate} from 'react-router-dom';
import {ICatalog} from '../../../types/CatalogTypes.ts';
import {Button, Card, Table} from 'react-bootstrap';
import {useTitle} from '../../../hooks/useTitle.ts';
import {useEffect, useState} from 'react';
import {ConfirmationModal} from '../../../components/util/ConfirmationModal.tsx';
import {LoadingButtonComponent} from '../../../components/util/LoadingButtonComponent.tsx';
import {apiAdminDeleteCatalog, apiAdminDuplicateCatalog, apiGetCatalogs} from '../../../api.ts';
import {parseApiErrors} from '../../../helpers/parseApiErrors.ts';
import {toast} from 'react-toastify';
import {LoadingComponent} from '../../../components/util/LoadingComponent.tsx';
import update from 'immutability-helper';

export function AdminCatalogsList() {
	// const data = useLoaderData() as { catalogs: ICatalog[] };
	const navigate = useNavigate();
	const [catalogs, setCatalogs] = useState<ICatalog[]>([]);
	const [loading, setLoading] = useState(true);
	const [ deleteId, setDeleteId ] = useState<string>();
	const [ deletingId, setDeletingId ] = useState<string>();
	const [ duplicatingId, setDuplicatingId ] = useState<string>();
	useTitle('Catalogs');
	
	useEffect(() => {
		apiGetCatalogs().then(response => {
			setCatalogs(response.catalogs);
			setLoading(false);
		})
	}, []);
	
	function manage(id: string) {
		navigate(id);	
	}
	
	async function duplicate(id: string) {
		setDuplicatingId(id);
		
		try {
			const response = await apiAdminDuplicateCatalog(id);
			if (!response.success) {
				const errors = parseApiErrors(response.errors);
				toast.error(errors[id] ?? response.message ?? 'Failed to delete catalog');
			} else {
				toast.success('Catalog duplicated');
				setCatalogs([...catalogs, response.data]);
			}
		} finally {
			setDuplicatingId(undefined);
		}
	}
	
	async function deleteCatalog() {
		if (!deleteId) {
			return;
		}
		
		const id = deleteId;
		setDeletingId(id);
		setDeleteId(undefined);
		
		try {
			const response = await apiAdminDeleteCatalog(id);
			if (!response.success) {
				const errors = parseApiErrors(response.errors);
				toast.error(errors[id] ?? response.message ?? 'Failed to delete catalog');
			} else {
				toast.success('Catalog deleted');
				const index = catalogs.findIndex(x => x._id === id);
				setCatalogs(update(catalogs, {
					$splice: [[ index, 1 ]]
				}));
			}
		} finally {
			setDeletingId(undefined);
		}
	}
	
	if (loading) {
		return <LoadingComponent />;
	}
	
	return (
		<>
			<ConfirmationModal
				title='Confirm Catalog Deletion'
				description='Are you sure you want to delete this catalog? Any existing fundraisers will not be affected. Existing fundraiser requests will require a new catalog to be selected before they can be accepted.' 
				confirmText='Delete Catalog' cancelText='Cancel Deletion'
				confirm={deleteCatalog}
				show={!!deleteId}
				cancel={() => setDeleteId(undefined)}
				/>
			<div className='r-header'>
				<h3>Manage Catalogs</h3>
				<Button variant='primary' onClick={() => navigate('create')}>
					Create Catalog
				</Button>
			</div>
			<Card className='p-4'>			
				<Table>
					<thead>
						<tr>
							<th>Name</th>
							<th># Items</th>
							<th colSpan={3} style={{ width: '1%' }}>Actions</th>
						</tr>
					</thead>
					<tbody>
						{ catalogs.map(x => <tr key={x._id}>
							<td>{x.name}</td>
							<td>{ x.items.length }</td>
							<td>
								<LoadingButtonComponent loading={!!duplicatingId} size='sm' onClick={() => duplicate(x._id)} variant='secondary'>Duplicate</LoadingButtonComponent>
							</td>
							<td>
								<Button size='sm' onClick={() => manage(x._id)}>Manage</Button>
							</td>
							<td>
								<LoadingButtonComponent loading={deletingId === x._id} size='sm' onClick={() => setDeleteId(x._id)} variant='danger'>Delete</LoadingButtonComponent>
							</td>
						</tr>) }
					</tbody>
				</Table>
			</Card>
		</>
	);
}