import { ReactNode } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import AuthenticationService from '../../services/AuthenticationService.ts';
import PVLogo from '../../assets/images/pv-logo.png';

interface IProps {
    children: ReactNode;
}

export function AdminNavigationComponent({ children }: IProps) {
    const navigate = useNavigate();

    function logout() {
        AuthenticationService.logoutAdmin();
        navigate('/admin/login');
    }

    return (
        <>
            <div className='r-sidebar-nav position-fixed start-0 top-0 bottom-0 p-3' style={{ width: 320 }}>
                <div className='logo'>
                    <NavLink to='/'>
                        <img src={PVLogo}></img>
                    </NavLink>
                </div>
                <ul className='nav flex-column nav-pills '>
                    <li className='nav-item'>
                        <NavLink to='/admin' className='nav-link' end>
                            Admin Home
                        </NavLink>
                    </li>

                    <hr />

                    <li className='nav-item fw-bold'>Fundraiser Management</li>
                    <li className='nav-item'>
                        <NavLink to='/admin/requests' className='nav-link'>
                            Manage Requests
                        </NavLink>
                    </li>
                    <li className='nav-item'>
                        <NavLink to='/admin/fundraisers' className='nav-link'>
                            Manage Ongoing Fundraisers
                        </NavLink>
                    </li>
                    <li className='nav-item'>
                        <NavLink to='/admin/users' className='nav-link'>
                            Manage Users
                        </NavLink>
                    </li>

                    <hr />
                    <li className='nav-item fw-bold'>Report Generation</li>
                    <li className='nav-item'>
                        <NavLink to='/admin/reports/quantities' className='nav-link'>
                            Quantities Report
                        </NavLink>
                    </li>
                    <li className='nav-item'>
                        <NavLink to='/admin/reports/fundraisers' className='nav-link'>
                            Fundraisers Report
                        </NavLink>
                    </li>

                    <hr />
                    <li className='nav-item fw-bold'>Pasta Management</li>
                    <li className='nav-item'>
                        <NavLink to='/admin/catalogs' className='nav-link'>
                            Manage Catalogs
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to='/admin/configuration' className='nav-link'>
                            Manage Admin Configuration
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to='/admin/strings' className='nav-link'>
                            Manage Strings
                        </NavLink>
                    </li>
                    <li className='pt-3'>
                        <Button variant='link' onClick={logout} className='w-100'>
                            Logout
                        </Button>
                    </li>
                </ul>
            </div>
            {children}
        </>
    );
}
