import {Button, Col, Image, InputGroup} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import {ICatalogItem} from "../../../types/CatalogTypes.ts";
import {ChangeEvent} from "react";
import {useFormikContext} from "formik";
import {apiUrl} from '../../../api.ts';

interface IProps {
	item: ICatalogItem;
	value: number;
	handleChange: (e: ChangeEvent<any>) => any;
	onValueChange: (newValue: number) => any;
}

export function OrderableItemComponent({item, value, handleChange, onValueChange}: IProps) {
	const { setFieldValue } = useFormikContext();
	
	function adjustValue(adjustment: number) {
		const currentValue = parseInt(value.toString());
		const newValue = isNaN(currentValue) ? 1 : currentValue + adjustment;
		setFieldValue(`items[${item._id}]`, newValue);
		onValueChange(newValue)
	}
	
	return (
		<Col md={4}>
			<div
				className='d-flex flex-column rounded-2 shadow-sm p-2 border bg-light h-100'>
				<div
					className='d-flex flex-row p-2 align-items-start justify-content-between gap-2'>
					<strong className='align-self-center'>{item.name}</strong>
					<div
						className='rounded-2 p-2 bg-info-subtle'>${item.price.toFixed(2)}</div>
				</div>
				<div className='d-flex flex-column mb-2'>
					<Image rounded src={ `${item.image.startsWith('http') ? '' : apiUrl}${item.image}` } alt={item.name} className='px-3'/>
					<div className="mt-2 description">{item.description}</div>
				</div>
				<div className='mt-auto d-flex flex-row justify-content-center'>
					<InputGroup className='w-50'>
						<Button variant='outline-secondary'
								onClick={() => adjustValue(-1)}
								disabled={value <= 0}>-</Button>
						<Form.Control
							name={`items[${item._id}]`}
							className='text-center'
							value={value}
							onChange={handleChange}
						/>
						<Button variant='outline-secondary'
								onClick={() => adjustValue(+1)}>+</Button>
					</InputGroup>
				</div>
			</div>
		</Col>
	)
}