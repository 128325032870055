import {Card, Container, Row} from 'react-bootstrap';
import {useRouteError} from 'react-router-dom';

export default function ErrorComponent() {
    const error = useRouteError();
    
    return <Container>
        <Row>
            <Card className='mt-4'>
                <Card.Body>
                    <h1>An error occurred!</h1>
                    {import.meta.env.DEV ? <>
                    {JSON.stringify(error)}
                    </> : <>
                    Sorry that happened. Refresh the page and retry.
                    </>}
                </Card.Body>
            </Card>
        </Row>
    </Container>
}