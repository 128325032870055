import {FundraiserWithOrder} from "../../../types/FundraiserTypes.ts";
import {Button, Modal} from "react-bootstrap";

interface IProps {
	fundraiser: FundraiserWithOrder;
	
	close: () => any;
}

export function AdminFundraiserInfoModal({ fundraiser, close }: IProps) {
	return (
		<Modal show={true}>
			<Modal.Header>
				<Modal.Title>Fundraiser Info</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<h2>{ fundraiser.name }</h2>
				<p>Fundraiser URL: <a href={`/f/${fundraiser.slug}`} target='_blank' rel='noreferrer'>{window.location.hostname}/f/{fundraiser.slug}</a></p>
				<p>{ fundraiser.orders.filter(x => !x.cancelled).length } orders</p>
				<h5>Contact Info</h5>
				<p>{ fundraiser.contactName }</p>
				<p>{ fundraiser.email }</p>
				<p>{ fundraiser.phoneNumber }</p>
			</Modal.Body>
			<Modal.Footer>
				<Button variant='dark' className='w-20' onClick={close}>Close</Button>
			</Modal.Footer>
		</Modal>
	)
}