import {ICatalogItem} from '../../types/CatalogTypes.ts';
import Form from 'react-bootstrap/Form';
import {Button, Image, InputGroup} from 'react-bootstrap';
import {FileType, FileUploadComponent} from '../util/FileUploadComponent.tsx';
import {ChangeEvent} from 'react';
import {FormikErrors, FormikTouched, useFormikContext} from 'formik';
import {apiUrl} from '../../api.ts';

interface IProps {
	index: number;
	// item: ICatalogItem;
	values: ICatalogItem;
	errors?: FormikErrors<ICatalogItem> | string;
	touched?: FormikTouched<ICatalogItem> | string;
	handleChange: (e: ChangeEvent<any>) => any;
	deleteItem: () => any;
}

export function CatalogEditableItemComponent({ index, values, handleChange, deleteItem, errors }: IProps) {	
	const { setFieldValue } = useFormikContext();
	async function updateImage(url: string) {
		await setFieldValue(`items[${index}].image`, url);
	}
	
	const nameError = errors ? (typeof errors === 'string') ? errors : errors.name : undefined;
	const internalNameError = errors ? (typeof errors === 'string') ? errors : errors.internalName : undefined;
	const descError = errors ? (typeof errors === 'string') ? errors : errors.description : undefined;
	const priceError = errors ? (typeof errors === 'string') ? errors : errors.price : undefined;
	const imageError = errors ? (typeof errors === 'string') ? errors : errors.image : undefined;
	
	if (!values) {
		return <></>
	}
	
	return (
		<tr>
			<td>
				<Form.Group>
					<Form.Control name={`items[${index}].internalName`} value={values.internalName} onChange={handleChange} isInvalid={!!internalNameError} />
					<Form.Control.Feedback type='invalid'>{internalNameError}</Form.Control.Feedback>
					<Form.Text>A unique tag for each item in a catalog to enable grouping on reports.</Form.Text>
				</Form.Group>
			</td>
			<td>
				<Form.Group>
					<Form.Control name={`items[${index}].name`} value={values.name} onChange={handleChange} isInvalid={!!nameError} />
					<Form.Control.Feedback type='invalid'>{nameError}</Form.Control.Feedback>
				</Form.Group>
			</td>
			<td>
				<Form.Group>
					<Form.Control as='textarea' rows={8} name={`items[${index}].description`} value={values.description} onChange={handleChange} isInvalid={!!descError} />
					<Form.Control.Feedback type='invalid'>{descError}</Form.Control.Feedback>
				</Form.Group>
			</td>
			<td>
				<div className='d-flex flex-column align-items-center'>
					{ values.image ? <Image style={{ height: 140 }} className='mb-2' rounded src={ `${values.image.startsWith('http') ? '' : apiUrl}${values.image}` } alt={values.name} /> : null }
					<FileUploadComponent onFileUpload={updateImage} fileType={FileType.Image} />
					{ imageError }
				</div>
			</td>
			<td>
				<Form.Group>
					<InputGroup>
						<InputGroup.Text>$</InputGroup.Text>
						<Form.Control min={0} type='number' value={values.price} step={0.1} name={`items[${index}].price`} onChange={handleChange} isInvalid={!!priceError} />
						<Form.Control.Feedback type='invalid'>{priceError}</Form.Control.Feedback>
					</InputGroup>
				</Form.Group>
			</td>
			<td>
				<Button onClick={deleteItem} variant='danger'>Delete</Button>
			</td>
		</tr>
	)
}