import Form from "react-bootstrap/Form";
import {Navigate, Link} from 'react-router-dom';
import {apiForgotFundraiserPassword, apiSetNewFundraiserPassword} from "../../api.ts";
import AuthenticationService from "../../services/AuthenticationService.ts";
import {Button, Card} from "react-bootstrap";
import {Formik, FormikHelpers} from "formik";
import {LoadingButtonComponent} from "../../components/util/LoadingButtonComponent.tsx";
import * as yup from "yup";
import {useTitle} from "../../hooks/useTitle.ts";
import {useState} from "react";
import {useQuery} from "../../hooks/useQuery.ts";
import {parseApiErrors} from '../../helpers/parseApiErrors.ts';

interface IForm {
	username: string;
}

interface INewPasswordForm {
	password: string;
	confirmPassword: string;
}

export function ManageFundraiserForgotPassword() {
	const [ success, setSuccess ] = useState(false);
	const query = useQuery();
	useTitle('Forgot Fundraiser Password');

	if (AuthenticationService.hasValidFundraiserToken()) {
		return <Navigate to={'/fundraiser'} />
	}
	
	const token = query.get('token');

	async function submitForm(values: IForm, { setErrors }: FormikHelpers<IForm>) {
		const response = await apiForgotFundraiserPassword(values.username);

		if (response.success) {
			setSuccess(true);
		} else {
			const errors = parseApiErrors(response.errors);

			if (response.message) {
				errors.username = response.message;
			}

			setErrors(errors);
		}
	}
	
	async function submitPasswordForm(values: INewPasswordForm, { setErrors }: FormikHelpers<INewPasswordForm>) {
		if (!token) {
			return;
		}
		
		const response = await apiSetNewFundraiserPassword(token, values);

		if (response.success) {
			setSuccess(true);
		} else {
			const errors = parseApiErrors(response.errors);

			if (response.message) {
				errors.password = response.message;
			}

			setErrors(errors);
		}
	}

	const schema = yup.object().shape({
		username: yup.string().required('Enter your username')
	});

	return (
		<div className="index-wrapper d-flex justify-content-center align-items-center">
			<div className="col-lg-4 col-md-8">
				<div className="card">
					<h4>Forgot Fundraiser Password</h4>
					{ success ? 
						<Card className='p4'>
							{ !token ? 'An email has been sent to the account linked to your fundraiser.' : <>
								Your password has been reset!
								<Link to='/fundraiser/login'>Click here to login</Link>
							</> }
						</Card> :
						token ? <Formik<INewPasswordForm> initialValues={{ password: '', confirmPassword: '' }} onSubmit={submitPasswordForm}>
							{({ handleSubmit, handleChange, errors, touched, values }) =>
								<Form noValidate onSubmit={handleSubmit}>
									<Form.Text>Set your fundraiser account's new password </Form.Text>
									<Form.Group>
										<Form.Label htmlFor='password'>Password</Form.Label>
										<Form.Control type='password' id='password' name='password' placeholder='Password' value={values.password} onChange={handleChange} isInvalid={touched.password && !!errors.password} autoComplete='new-password' />
										<Form.Control.Feedback type='invalid'>{errors.password}</Form.Control.Feedback>
									</Form.Group>

									<Form.Group>
										<Form.Label htmlFor='confirmPassword'>Confirm Password</Form.Label>
										<Form.Control type='password' id='confirmPassword' name='confirmPassword' placeholder='Confirm Password' value={values.confirmPassword} onChange={handleChange} isInvalid={touched.confirmPassword && !!errors.confirmPassword} autoComplete='new-password' />
										<Form.Control.Feedback type='invalid'>{errors.confirmPassword}</Form.Control.Feedback>
									</Form.Group>
									
									<Button type='submit' className='w-100 mt-4'>Update Password</Button>
								</Form>
							}
							</Formik> :
						<Formik<IForm> initialValues={{ username: '' }} onSubmit={submitForm} validationSchema={schema}>
							{({ handleSubmit, handleChange, values, touched, errors, isSubmitting }) => (
								<Form noValidate onSubmit={handleSubmit}>
									<Form.Text>Provide your fundraiser account's username to request an email to reset your password</Form.Text>
									<Form.Group>
										<Form.Label htmlFor='username'>Username</Form.Label>
										<Form.Control id='username' name='username' placeholder='Username' value={values.username} onChange={handleChange} isInvalid={touched.username && !!errors.username} />
										<Form.Control.Feedback type='invalid'>{errors.username}</Form.Control.Feedback>
									</Form.Group>

									<LoadingButtonComponent className='mt-4 w-100' loading={isSubmitting} type='submit'>Request Reset Email</LoadingButtonComponent>
								</Form>
							)}
						</Formik>
					}
					
				</div>
			</div>
		</div>
	)
}