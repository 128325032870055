import {useTitle} from "../../hooks/useTitle.ts";
import {useEffect, useState} from 'react';
import {IAdminOverviewData} from '../../types/ApiTypes.ts';
import {LoadingComponent} from '../../components/util/LoadingComponent.tsx';
import {apiAdminGetOverview} from '../../api.ts';

export function AdminPanel() {
	useTitle('Admin')
	
	const [ loading, setLoading ] = useState(true);
	const [ data, setData ] = useState<IAdminOverviewData>();
	
	useEffect(() => {
		apiAdminGetOverview().then(response => {
			setData(response);
			setLoading(false);
		});
	}, []);
	
	if (loading) {
		return <LoadingComponent />
	}
	
	if (!data) {
		return;
	}
	
	return (
		<>
		<div className="alert alert-info">
			<h2>Welcome Back 👋</h2>
			There are <strong>{data.requestCount} request{data.requestCount === 1 ? '' : 's'}</strong>, <strong>{data.upcomingFundraisersCount} upcoming</strong> and <strong>{data.ongoingFundraisersCount} ongoing</strong> fundraisers.
		</div>

		{/*<div className="row">*/}
		{/*	<div className="col-md-6">*/}
		{/*		<div className="card">*/}
		{/*			<Button variant='link'><Link to='catalogs'>Manage Catalogs</Link></Button>*/}
		{/*			<Button variant='link'><Link to='requests'>Manage Requests</Link></Button>*/}
		{/*		</div>*/}
		{/*	</div>*/}
		{/*</div>*/}
		</>
	)
}