import {Formik, FormikHelpers, FormikProps} from 'formik';
import Form from 'react-bootstrap/Form';
import {Button} from 'react-bootstrap';
import * as yup from 'yup';
import {IAddress} from '../../types/FundraiserTypes.ts';
import {AddressComponent} from '../AddressComponent.tsx';
import {forwardRef, useImperativeHandle, useRef} from 'react';
import {ICreationStepRef} from './CreationOverviewComponent.tsx';

interface IForm extends IAddress {
}

interface IFormTypes extends IForm {
	[key: string]: any;
}

interface IProps {
	values?: IFormTypes;
	update?: boolean;
	onStepComplete: (values: { [key: string]: any}) => any;
	onPrevious?: (values: { [key: string]: any}) => any;
}

export const CreationStep3Component = forwardRef<ICreationStepRef, IProps>(({ update, values, onStepComplete, onPrevious }, ref) => {
	const formRef = useRef<FormikProps<IForm>>(null);
	useImperativeHandle(ref, () => ({
		onUpdateClick() {
			formRef.current?.submitForm();
		}
	}));
	
	const initialValues: IForm = values ?? {
		companyName: '',
		addressLine1: '',
		addressLine2: '',
		addressLine3: '',
		town: '',
		postcode: '',
		region: '',
	};

	function submit(values: IForm, {}: FormikHelpers<IForm>) {
		onStepComplete(values);
	}

	function onPreviousClick() {
		if (!onPrevious) {
			return;
		}

		const values = formRef.current?.values;
		onPrevious(values ?? {});
	}

	const validation = yup.object().shape({
		companyName: yup.string().default(''),
		addressLine1: yup.string().required('Address line 1 is required'),
		addressLine2: yup.string().default(''),
		addressLine3: yup.string().default(''),
		town: yup.string().required('A town is required'),
		postcode: yup.string().required('A Postcode is required'),
		region: yup.string().required('A Region is required').oneOf(['Auckland', 'Bay of Plenty', 'Canterbury', 'Gisborne', 'Hawke\'s Bay', 'Manawatu-Wanganui', 'Marlborough', 'Nelson', 'Northland', 'Otago', 'Southland', 'Taranaki', 'Tasman', 'Waikato', 'Wellington', 'West Coast'], 'You must pick a valid region'),
	});

	return (
		<Formik<IForm> initialValues={initialValues} onSubmit={submit} validationSchema={validation} innerRef={formRef}>
			{({ handleSubmit, handleChange, values, touched, errors }) =>
				<Form noValidate onSubmit={handleSubmit}>
					<p>Enter the address of where you would like your bulk order to be sent, please make sure there is access for a truck.</p>

					<AddressComponent values={values} errors={errors} touched={touched} formChanged={handleChange} />

					{ !update ? <div className='d-flex flex-row justify-content-between'>
						<Button variant='warning' onClick={onPreviousClick}>Previous</Button>
						<Button type='submit'>Next</Button>
					</div> : null }
				</Form>
			}
		</Formik>
	)
})