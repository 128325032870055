import { Button, Modal, Table } from 'react-bootstrap';
import { FundraiserWithOrder, IOrder } from '../../../types/FundraiserTypes.ts';
import Form from 'react-bootstrap/Form';
import { useRef, useState } from 'react';
import { ICatalogItem } from '../../../types/CatalogTypes.ts';
import { convertToCSV, downloadCSV, printContent } from '../../../helpers/convertToCSV.ts';
import { toast } from 'react-toastify';
import { formatAddress } from '../../../helpers/formatAddress.ts';
import moment from 'moment';

enum Mode {
    ExportOrders,
    TotalItems,
    FullReport
}

interface IProps {
    open: boolean;
    close: () => any;

    fundraiser: FundraiserWithOrder;
}

export function ExportSettingsModalComponent({ open, close, fundraiser }: IProps) {
    const tableRef = useRef<HTMLTableElement>(null);
    const [includeNonPayers, setIncludeNonPayers] = useState(true);
    const [reportMode, setReportMode] = useState<Mode>(Mode.FullReport);

    const relevantOrders = includeNonPayers
        ? fundraiser.orders.filter((x) => !x.cancelled)
        : fundraiser.orders.filter((x) => x.paidStatus && !x.cancelled);

    function countItems(order: IOrder, catalogItem: ICatalogItem) {
        const item = order.items.find((x) => x.itemId === catalogItem._id);
        return item?.amount ?? 0;
    }

    function calculateFundraiserValue() {
        let value = 0;

        for (let order of fundraiser.orders) {
            value += calculateTotalValue(order);
        }

        return value;
    }

    function calculateTotalValue(order: IOrder) {
        let value = 0;
        for (let item of order.items) {
            const cataItem = fundraiser.catalogItems.find((x) => item.itemId === x._id);
            if (!cataItem) {
                continue;
            }

            value += item.amount * cataItem.price;
        }

        return value;
    }

    function getTotalOrdered(item: ICatalogItem) {
        let total = 0;

        for (let order of relevantOrders) {
            let orderedItem = order.items.find((x) => x.itemId === item._id);
            if (orderedItem) {
                total += orderedItem.amount;
            }
        }

        return total;
    }

    function printTable() {
        let name = 'orders';
        switch (reportMode) {
            case Mode.TotalItems:
                name = 'items';
                break;
            case Mode.ExportOrders:
                name = 'orders';
                break;
            case Mode.FullReport:
                name = 'full';
                break;
        }

        if (!tableRef.current || !printContent(`${fundraiser.name}-${name}`, tableRef.current.outerHTML)) {
            toast.error('Failed to print window');
        }
    }

    function downloadTable() {
        if (!tableRef.current) {
            return;
        }

        let csvData = convertToCSV(tableRef.current);

        let name = 'orders';
        switch (reportMode) {
            case Mode.TotalItems:
                name = 'items';
                break;
            case Mode.ExportOrders:
                name = 'orders';
                break;
            case Mode.FullReport:
                let prependData = `Fundraiser Name,${fundraiser.name}\n`;
                prependData += `Fundraiser Contact,${fundraiser.contactName} - ${fundraiser.phoneNumber}\n`;
                prependData += `Delivery Date,${moment(fundraiser.deliveryDate).format('DD/MM/YYYY')}\n`;
                prependData += `Delivery Address,${formatAddress(fundraiser.address).replace(/\n/g, ' ')}\n`;
                prependData += `Fundraiser Value,$${calculateFundraiserValue().toFixed(2)}\n`;
                prependData += `Fundraiser Earned,$${(calculateFundraiserValue() * fundraiser.commission).toFixed(
                    2
                )} (${(fundraiser.commission * 100).toFixed(2)}%)`;
                csvData = `${prependData}\n\n\n${csvData}`;
                name = 'full';
                break;
        }
        downloadCSV(`${fundraiser.name}-${name}`, csvData);
    }

    function generateTable() {
        switch (reportMode) {
            case Mode.ExportOrders:
                return generateExportOrders();

            case Mode.FullReport:
                return generateFullReport();

            case Mode.TotalItems:
                return generateFullItems();

            default:
                return <>Unknown Report</>;
        }
    }

    function generateExportOrders() {
        return (
            <Table ref={tableRef}>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Order Ref</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Paid</th>
                        <th>Notes</th>
                        {fundraiser.catalogItems.map((x) => (
                            <th key={`head-${x._id}`}>{x.name}</th>
                        ))}
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody>
                    {relevantOrders.map((x) => (
                        <tr key={x._id}>
                            <td>{x.orderReference}</td>
                            <td>
                                {x.name} {x.lastName ?? ''}
                            </td>
                            <td>{x.referral}</td>
                            <td>{x.email}</td>
                            <td>{x.phone}</td>
                            <td>{x.paidStatus ? 'Paid' : 'Not Paid'}</td>
                            <td>{x.paidNotes}</td>
                            {fundraiser.catalogItems.map((y) => (
                                <td key={`${x._id}${y._id}`}>{countItems(x, y)}</td>
                            ))}
                            <td>${calculateTotalValue(x).toFixed(2)}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        );
    }

    function generateFullItems(setRef: boolean = true) {
        return (
            <Table ref={setRef ? tableRef : undefined}>
                <thead>
                    <tr>
                        <th>Item Name</th>
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody>
                    {fundraiser.catalogItems.map((x) => (
                        <tr key={x.internalName}>
                            <td>{x.name}</td>
                            <td>{getTotalOrdered(x)}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        );
    }

    function generateFullReport() {
        return (
            <div ref={tableRef}>
                <h5>Name</h5>
                <p>{fundraiser.name}</p>

                <h5>Contact</h5>
                <p>
                    {fundraiser.contactName} - {fundraiser.phoneNumber}
                </p>

                <h5>Delivery Date</h5>
                <p>{moment(fundraiser.deliveryDate).format('DD/MM/YYYY')}</p>

                <h5>Delivery Address</h5>
                <p>{formatAddress(fundraiser.address)}</p>

                <h5>Fundraiser Value</h5>
                <p>
                    ${calculateFundraiserValue().toFixed(2)}
                    <br />
                    Fundraiser Earned ${(calculateFundraiserValue() * fundraiser.commission).toFixed(2)} (
                    {(fundraiser.commission * 100).toFixed(2)}%)
                </p>

                {generateFullItems(false)}
            </div>
        );
    }

    return (
        <Modal show={open} size='xl'>
            <Modal.Header>
                <Modal.Title>Report Generation Options</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='d-flex flex-row align-items-start gap-4 align-content-start'>
                    <div>
                        <Form.Group>
                            <Form.Label>Export Mode</Form.Label>
                            <Form.Check
                                type='radio'
                                label='Full Report'
                                id='full'
                                checked={reportMode === Mode.FullReport}
                                onChange={() => setReportMode(Mode.FullReport)}
                            />
                            <Form.Check
                                type='radio'
                                label='Orders'
                                id='orders'
                                checked={reportMode === Mode.ExportOrders}
                                onChange={() => setReportMode(Mode.ExportOrders)}
                            />
                            <Form.Check
                                type='radio'
                                label='Total Items'
                                id='items'
                                checked={reportMode === Mode.TotalItems}
                                onChange={() => setReportMode(Mode.TotalItems)}
                            />
                        </Form.Group>
                    </div>

                    <div>
                        <Form.Label>Options</Form.Label>
                        <Form.Group>
                            <Form.Check
                                label='Include non payers'
                                id='nonPayers'
                                checked={includeNonPayers}
                                onChange={(x) => setIncludeNonPayers(x.target.checked)}
                            />
                        </Form.Group>
                    </div>
                </div>
                <div className='my-4 d-flex gap-2'>
                    <Button variant='primary' onClick={downloadTable}>
                        Download Report
                    </Button>
                    <Button variant='primary' onClick={printTable}>
                        Print Report
                    </Button>
                </div>

                <div className='overflow-x-scroll'>{generateTable()}</div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='danger' onClick={close}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
