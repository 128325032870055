import {useNavigate, useParams} from "react-router-dom";
import {IFundraiserRequest} from "../../../types/FundraiserTypes.ts";
import {useEffect, useState} from "react";
import {ICatalog} from "../../../types/CatalogTypes.ts";
import {apiAdminGetRequest, apiGetCatalogs} from "../../../api.ts";
import {toast} from "react-toastify";
import {useTitle} from "../../../hooks/useTitle.ts";
import {AdminManageRequestComponent} from "../../../components/admin/requests/AdminManageRequestComponent.tsx";
import {LoadingComponent} from "../../../components/util/LoadingComponent.tsx";

export function AdminManageRequest() {
	const navigate = useNavigate();
	useTitle("Manage Request");
	const [request, setRequest] = useState<IFundraiserRequest>();
	const [catalogs, setCatalogs] = useState<ICatalog[]>([]);
	const [loading, setLoading] = useState(true);
	const { id } = useParams();
	
	useEffect(() => {
		Promise.all([
			apiAdminGetRequest(id!).then(response => {
				if (response.success) {
					setRequest(response.data);
				} else {
					if (response.message) {
						toast.error(response.message);
					} else {
						toast.error('Failed to load fundraiser request');
					}
					
					navigate('/admin/requests')
				}
			}),
			apiGetCatalogs().then(response => {
				setCatalogs(response.catalogs);
			})	
		]).then(() => {
			setLoading(false);
		});
	}, []);
	
	if (loading) {
		return <LoadingComponent />
	}
	
	if (!request) {
		return 'Invalid request';
	}
	
	return <AdminManageRequestComponent request={request} catalogs={catalogs} />
}