import {IOrder} from "../../../types/FundraiserTypes.ts";
import {ICatalogItem} from "../../../types/CatalogTypes.ts";
import {Button, Col, Modal, Row, Table} from 'react-bootstrap';
import Form from "react-bootstrap/Form";
import {FieldArray, Formik, FormikHelpers} from "formik";
import * as yup from "yup";
import {
	apiAdminCancelFundraiserOrder,
	apiAdminUpdateFundraiserOrder,
	apiCancelFundraiserOrder,
	apiUpdateFundraiserOrder
} from "../../../api.ts";
import {toast} from "react-toastify";
import {LoadingButtonComponent} from "../../util/LoadingButtonComponent.tsx";
import {useState} from "react";
import {ConfirmationModal} from "../../util/ConfirmationModal.tsx";
import {parseApiErrors} from '../../../helpers/parseApiErrors.ts';
import {NameRegex, PhoneRegex} from '../../../util/Regex.ts';

interface IProps {
	order: IOrder;
	catalogItems: ICatalogItem[];
	admin: boolean;
	locked: boolean;
	
	show: boolean;
	close: () => any;
	
	updateOrder: (order: IOrder) => any;
}

interface IForm {
	id: string;
	items: {
		itemId: string;
		amount: number;
	}[];
	paid: boolean;
	notes: string;
	name: string;
	lastName: string;
	email: string;
	phone: string;
}

export function ManageOrderModalComponent({ order, catalogItems, show, close, updateOrder, admin, locked }: IProps) {
	const [saving, setSaving] = useState(false);
	const [cancelling, setCancelling] = useState(false);
	const [ cancellationId, setCancellationId ] = useState<string>();
	
	const itemsOrder = [ ...catalogItems ].sort((a, b) => {
		const aOrder = order.items.find(x => x.itemId === a._id);
		const bOrder = order.items.find(x => x.itemId === b._id);
		
		return (bOrder?.amount ?? 0) - (aOrder?.amount ?? 0);
	});
	
	const schema = yup.object().shape(admin ? {
		items: yup.array().of(yup.object().shape({
			itemId: yup.string().oneOf(catalogItems.map(x => x._id)).required(),
			amount: yup.number().min(0).required()
		})),
		paid: yup.boolean(),
		notes: yup.string(),
		name: yup.string().required('A first name is required').matches(NameRegex, 'Names can only contain letters, spaces or hyphens'),
		lastName: yup.string().optional().matches(NameRegex, 'Names can only contain letters, spaces or hyphens'),
		email: yup.string().email('A valid email is required').required('Your email is required'),
		phone: yup.string().matches(PhoneRegex, 'You must provide a valid phone number').required('Your phone number is required'),
	} : {
		items: yup.array().of(yup.object().shape({
			itemId: yup.string().oneOf(catalogItems.map(x => x._id)).required(),
			amount: yup.number().min(0).required()
		})),
		paid: yup.boolean(),
		notes: yup.string()
	});
	
	async function onSubmit(values: IForm, {setErrors}: FormikHelpers<IForm>) {
		setSaving(true);
		const response = !admin ? await apiUpdateFundraiserOrder(values) : await apiAdminUpdateFundraiserOrder(values);
		
		if (response.success) {
			close();
			if (admin) {
				updateOrder({...order, items: values.items, paidNotes: values.notes, paidStatus: values.paid, name: values.name, lastName: values.lastName, email: values.email, phone: values.phone });
			} else {
				updateOrder({ ...order, items: values.items, paidNotes: values.notes, paidStatus: values.paid });
			}
			
			toast.success('Order successfully updated');
		} else {
			const errors = parseApiErrors(response.errors);

			setErrors(errors);
		}
		
		setSaving(false);
	}
	
	async function cancelOrder() {
		setCancellationId(undefined);
		setCancelling(true);
		const response = !admin ? await apiCancelFundraiserOrder(order._id) : await apiAdminCancelFundraiserOrder(order._id, order.fundraiser);
		
		if (response.success) {
			close();
			updateOrder({ ...order, cancelled: true });
			toast.success('Order cancelled')
		} else {
			toast.error(response.message ?? 'Failed to cancel order');
		}
		
		// setSavingAction(false);
	}
	
	function calculateCost(items: { itemId: string, amount: number }[]) {
		let cost = 0;
		for (let item of items) {
			const catalogItem = catalogItems.find(x => x._id === item.itemId);
			
			if (catalogItem) {
				cost += catalogItem.price * item.amount;
			}
		}
		
		return cost;
	}
	
	function sumItemCount(items: {itemId: string, amount: number }[]) {
		let count = 0;
		
		for (let item of items) {
			const numericValue = parseInt(item.amount.toString());
			count += isNaN(numericValue) ? 0 : numericValue;
		}
		
		return count;
	}
	
	const items: { itemId: string, amount: number }[] = [];
	
	for (let item of itemsOrder) {
		const ordered = order.items.find(x => x.itemId === item._id);
		items.push({ itemId: item._id, amount: ordered?.amount ?? 0 });
	}
	
	return (<>
		<Modal show={show && !cancellationId} size="lg">
			<Modal.Header>
				<Modal.Title>Manage Order</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{ locked && !admin ? <p>No changes can be made to this order now</p> : null }
				{ admin ? <Row>
					<Col md='4'>
						<h6>Order ID</h6>
						{order.orderReference}
					</Col>
					<Col md='4'>
						<h6>Order Ref</h6>
						{order.referral ?? 'N/A'}
					</Col>
				</Row> :
				<Row>
					<Col md='4'>
						<h6>Order ID</h6>
						{order.orderReference}
					</Col>
					<Col md='4'>
						<h6>Name</h6>
						{order.name}
					</Col>
					<Col md='4'>
						<h6>Contact Info</h6>
						{order.email} <br />
						{order.phone}
					</Col>
					<Col md='4'>
						<h6>Order Ref</h6>
						{order.referral ?? 'N/A'}
					</Col>
				</Row>
				}
				
				<Formik<IForm> initialValues={{ id: order._id, items: items, paid: order.paidStatus, notes: order.paidNotes ?? '', name: order.name, lastName: order.lastName, email: order.email, phone: order.phone }} onSubmit={onSubmit} validationSchema={schema}>
					{({handleSubmit, handleChange, values, errors, touched}) => <Form noValidate onSubmit={handleSubmit} id="orderForm" className='mt-2'>
						{ admin ? <div className='mb-4'>
							<div className='row'>
								<Form.Group className='col-sm-6'>
									<Form.Label>First Name</Form.Label>
									<Form.Control name='name' value={values.name} disabled={!admin} onChange={handleChange} isInvalid={touched.name && !!errors.name} />
									<Form.Control.Feedback type='invalid'>{errors.name}</Form.Control.Feedback>
								</Form.Group>
								<Form.Group className='col-sm-6'>
									<Form.Label>Last Name</Form.Label>
									<Form.Control name='lastName' value={values.lastName} disabled={!admin} onChange={handleChange} isInvalid={touched.lastName && !!errors.lastName} />
									<Form.Control.Feedback type='invalid'>{errors.lastName}</Form.Control.Feedback>
								</Form.Group>
							</div>
							<Form.Group className='mt-2'>
								<Form.Label>Contact Email</Form.Label>
								<Form.Control name='email' value={values.email} disabled={!admin} onChange={handleChange} isInvalid={touched.email && !!errors.email} />
								<Form.Control.Feedback type='invalid'>{errors.email}</Form.Control.Feedback>
							</Form.Group>
							<Form.Group className='mt-2'>
								<Form.Label>Contact Phone Number</Form.Label>
								<Form.Control name='phone' value={values.phone} disabled={!admin} onChange={handleChange} isInvalid={touched.phone && !!errors.phone} />
								<Form.Control.Feedback type='invalid'>{errors.phone}</Form.Control.Feedback>
							</Form.Group>
						</div> : null }
						<Table>
							<thead>
							<tr>
								<th>Item Name</th>
								<th>Amount</th>
								<th>Cost</th>
							</tr>
							</thead>
							<tbody>
							<FieldArray name="items" render={() => values.items.map((item, index) => {
								const catalogItem = catalogItems.find(x => x._id === item.itemId);
								return  <tr>
									<td>{catalogItem?.name ?? "Unknown Item"}</td>
									<td>
										<Form.Control name={`items.${index}.amount`} value={item.amount} disabled={!admin} onChange={handleChange} isInvalid={!!errors.items && !!errors.items[index]} />
									</td>
									<td>${((catalogItem?.price ?? 0) * item.amount).toFixed(2)}</td>
								</tr>;
							})} />
							<tr>
								<td><strong>Total</strong></td>
								<td>{ sumItemCount(values.items) }</td>
								<td><strong>${calculateCost(values.items).toFixed(2)}</strong></td>
							</tr>
							</tbody>
						</Table>
						<div className='d-flex'>
							<LoadingButtonComponent loading={cancelling} variant='danger' onClick={() => setCancellationId(order._id)} className='w-25 ms-auto'>Cancel Order</LoadingButtonComponent>
						</div>
						{/*<div className='text-end'>*/}
						{/*	Total <strong>${calculateCost(values.items).toFixed(2)}</strong>*/}
						{/*</div>*/}
						<hr />
						<Form.Check id="paid" label="Paid" checked={values.paid} onChange={handleChange} />
						<Form.Label>Paid Notes</Form.Label>
						<Form.Control as="textarea" name="notes" value={values.notes} onChange={handleChange} />
					</Form>}
				</Formik>
			</Modal.Body>
			<Modal.Footer>
				<Button variant='dark' onClick={close} className='w-25'>Discard Changes</Button>
				<LoadingButtonComponent loading={saving} variant='primary' type='submit' form='orderForm' className='w-25' disabled={locked && !admin}>Save</LoadingButtonComponent>
			</Modal.Footer>
		</Modal>
		{ cancellationId ? <ConfirmationModal 
			title={'Confirm Order Cancellation'} 
			description={'Confirm you are wanting to cancel this order. Cancelled orders cannot be reinstated.'} 
			confirm={cancelOrder} 
			cancel={() => setCancellationId(undefined)} 
			cancelText={'Keep Order'}
			confirmText={'Cancel Order'}
		/> : null }
		</>
	);
}