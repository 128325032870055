import {Button, Card, Table} from 'react-bootstrap';
import {Formik, FormikHelpers} from 'formik';
import Form from 'react-bootstrap/Form';
import moment from 'moment';
import {useRef, useState} from 'react';
import {LoadingComponent} from '../../../components/util/LoadingComponent.tsx';
import {apiAdminRunQuantitiesReport} from '../../../api.ts';
import {parseApiErrors} from '../../../helpers/parseApiErrors.ts';
import {toast} from 'react-toastify';
import {IQuantitiesReport} from '../../../types/ApiTypes.ts';
import {convertToCSV, downloadCSV, printContent} from '../../../helpers/convertToCSV.ts';
import {useTitle} from '../../../hooks/useTitle.ts';

interface IForm {
	startDate: string;
	endDate: string;
	includeOpen: boolean;
	includeClosed: boolean;
}

export function AdminQuantitiesReport() {
	useTitle('Quantities Reports');
	
	const [loading, setLoading] = useState(false);
	const [ data, setData ] = useState<IQuantitiesReport>();
	const cardRef = useRef<HTMLDivElement>(null);
	const [ submittedStartDate, setSubmittedStartDate ] = useState('');
	const [ submittedEndDate, setSubmittedEndDate ] = useState('');
	
	async function runReport(values: IForm, {setErrors}: FormikHelpers<IForm>) {
		setLoading(true);
		
		try {
			const response = await apiAdminRunQuantitiesReport(values);
			if (!response.success) {
				const errors = parseApiErrors(response.errors);
				setErrors(errors);
				toast.error(response.message ?? 'Failed to generate report');
			} else {
				setSubmittedStartDate(values.startDate);
				setSubmittedEndDate(values.endDate);
				setData(response.data);
			}
		}
		finally {
			setLoading(false);
		}
	}
	
	function printTable() {
		const startDate = moment(submittedStartDate).format('DD/MM/YYYY');
		const endDate = moment(submittedEndDate).format('DD/MM/YYYY');
		
		if (!cardRef.current || !printContent(`Order Quantities ${startDate} - ${endDate}`, cardRef.current.innerHTML)) {
			toast.error('Failed to print window');
			return;
		}
	}

	function onDownloadClick() {
		const table = cardRef.current?.querySelector('table');
		if (!table) {
			return;
		}

		const csvData = convertToCSV(table);
		downloadCSV(`quantities-${moment(submittedStartDate).format('DD/MM/YYYY')}-${moment(submittedEndDate).format('DD/MM/YYYY')}`, csvData);
	}
	
	function sumFundraiserTotal(id: string) {
		if (!data) {
			return 0;
		}
		
		let total = 0;
		
		const keys = Object.keys(data);
		for (let productName of keys) {
			let product = data[productName];
			const relative = product.fundraisers.filter(x => x.id === id);
			for (let fundraiser of relative) {
				total += fundraiser.value;
			}
		}
		
		return total;
	}
	
	const initialValues: IForm = {
		startDate: moment().format('YYYY-MM-DD'),
		endDate: moment().add(14, 'days').format('YYYY-MM-DD'),
		includeOpen: true,
		includeClosed: true
	};
	
	return (
		<>
			<div className='r-header'>
				<h3>Quantities Report</h3>
			</div>
			<Card className='p-4'>
				<Formik<IForm> onSubmit={runReport} initialValues={initialValues}>
					{({ handleSubmit, handleChange, values, touched, errors }) => <Form noValidate onSubmit={handleSubmit}>
						<Form.Label>End Date</Form.Label>
						<div className='d-flex flex-row gap-2 align-items-center'>
							<Form.Group>
								<Form.Label>From</Form.Label>
								<Form.Control type='date' name='startDate' value={values.startDate} isInvalid={touched.startDate && !!errors.startDate} onChange={handleChange} />
								<Form.Control.Feedback type='invalid'>{errors.startDate}</Form.Control.Feedback>
							</Form.Group>
							<Form.Group>
								<Form.Label>To</Form.Label>
								<Form.Control type='date' name='endDate' value={values.endDate} isInvalid={touched.endDate && !!errors.endDate} onChange={handleChange} />
								<Form.Control.Feedback type='invalid'>{errors.endDate}</Form.Control.Feedback>
							</Form.Group>
						</div>
						<div className='d-flex flex-row gap-4 mt-2'>
							<Form.Check checked={values.includeOpen} name='includeOpen' onChange={handleChange} label='Include Open Fundraisers' id='includeOpen' />
							<Form.Check checked={values.includeClosed} name='includeClosed' onChange={handleChange} label='Include Closed Fundraisers' id='includeClosed' />
						</div>
						<div className='w-25 mt-4 d-flex gap-2'>
							<Button variant='outline-success' type='submit' style={{ minWidth: 'fit-content' }}>Generate Report</Button>
							{ data && Object.keys(data).length > 0 ? <>
								<Button onClick={printTable} style={{ minWidth: 'fit-content' }}>Print Report</Button>
								<Button onClick={onDownloadClick} style={{ minWidth: 'fit-content' }}>Download Report as CSV</Button>
							</>: null }
						</div>
					</Form> }
				</Formik>
			</Card>
			{ loading ? <LoadingComponent /> : null }
			{ data ? Object.keys(data).length === 0 ? 'No results' :  <Card className='mt-4 overflow-x-scroll p-4' ref={cardRef}>
				<Table>
					<thead>
						<tr>
							<th>Internal Name</th>
							<th>Product Name</th>
							{ Object.values(data)[0].fundraisers.map(y => 
								<th>{ y.name }<br /><small>({moment(y.endDate).format('DD/MM/YYYY')})</small></th>
							) }
							<th>Total</th>
						</tr>
					</thead>
					<tbody>
						{ Object.values(data).map(x => <tr key={x.internalName}>
							<td>{x.internalName}</td>
							<td>{x.name}</td>
							{ x.fundraisers.map(y => <td key={`${x.internalName}${y.id}`}>{y.amount}</td>) }
							<td>{x.total}</td>
						</tr>) }
					<tr>
						<td></td>
						<td>Total $</td>
						{ Object.values(data)[0].fundraisers.map(y =>
							<td>${ sumFundraiserTotal(y.id).toFixed(2) }</td>
						) }
					</tr>
					</tbody>
				</Table>
			</Card> : null }
		</>
	)
}