import {IFundraiser} from '../../../types/FundraiserTypes.ts';
import moment from 'moment/moment';
import {Button, Card, Col, Row} from 'react-bootstrap';
import {Formik, FormikHelpers} from 'formik';
import {useState} from 'react';
import Form from 'react-bootstrap/Form';
import {LoadingButtonComponent} from '../../util/LoadingButtonComponent.tsx';
import * as yup from 'yup';
import {apiAdminUpdateFundraiserDates} from '../../../api.ts';
import {toast} from 'react-toastify';
import {parseApiErrors} from '../../../helpers/parseApiErrors.ts';

interface IProps {
	fundraiser: IFundraiser;
	
	updateDates: (startDate: Date, endDate: Date, deliveryDate: Date) => any;
}

interface IForm {
	id: string;
	startDate: string;
	endDate: string;
	deliveryDate: string;
}

export function ManageFundraiserDatesComponent({fundraiser, updateDates}: IProps) {
	const [modifying, setModifying] = useState(false);

	async function onSubmit(values: IForm, {setErrors}: FormikHelpers<IForm>) {
		const response = await apiAdminUpdateFundraiserDates(values);
		
		if (!response.success) {
			const errors = parseApiErrors(response.errors);
			
			if (response.message) {
				toast.error(response.message);
			}
			
			setErrors(errors);
		} else {
			setModifying(false);
			toast.success('Fundraiser dates updated');
			updateDates(new Date(values.startDate), new Date(values.endDate), new Date(values.deliveryDate));
		}
	}
	
	const initialValues: IForm = {
		id: fundraiser._id,
		startDate: moment(fundraiser.startDate).format('YYYY-MM-DD'),
		endDate: moment(fundraiser.endDate).format('YYYY-MM-DD'),
		deliveryDate: moment(fundraiser.deliveryDate).format('YYYY-MM-DD')
	};

	const validation = yup.object().shape({
		startDate: yup.date().required('A start date is required'),
		endDate: yup.date().required('A end date is required').when('startDate', (startDate, yup) => startDate && yup.min(startDate[0], 'End date cannot be before start date')),
		deliveryDate: yup.date().required('A delivery date is required').when('endDate', (endDate, yup) => endDate && yup.min(endDate[0], 'Delivery date cannot be before end date')),
		// 	.when('endDate', (endDate, yup) => {
		// 	if (!endDate) {
		// 		return yup;
		// 	}
		//	
		// 	const minDeliveryDate = moment(endDate[0]).add(12, 'days');
		// 	return yup.min(minDeliveryDate.toDate(), 'Delivery date must be at least 10 working days past the end date');
		// })
	});
	
	return (
		<Formik<IForm> initialValues={initialValues} onSubmit={onSubmit} validationSchema={validation}>
			{({ resetForm, values, errors, handleSubmit, handleChange, isSubmitting}) =>
				<Form noValidate onSubmit={handleSubmit}>
					<Row className='mt-4'>
						<Col md={3}>
							<Card className='p-4 d-flex flex-row justify-content-between align-items-center'>
								<div className='w-100'>
									<h4>Start Date</h4>
									{!modifying ? moment(fundraiser.startDate).format('DD MMM, YYYY') :
										<Form.Group>
											<Form.Control type='date' value={values.startDate} name='startDate'
														  onChange={handleChange}
														  isInvalid={!!errors.startDate}/>
											<Form.Control.Feedback type='invalid'>{errors.startDate}</Form.Control.Feedback>
										</Form.Group>}
								</div>

							</Card>
						</Col>

						<Col md={3}>
							<Card className='p-4 d-flex flex-row justify-content-between align-items-center'>
								<div className='w-100'>
									<h4>Close Date</h4>
									{!modifying ? moment(fundraiser.endDate).format('DD MMM, YYYY') :
										<Form.Group>
											<Form.Control type='date' value={values.endDate} name='endDate'
														  onChange={handleChange}
														  isInvalid={!!errors.endDate}/>
											<Form.Control.Feedback type='invalid'>{errors.endDate}</Form.Control.Feedback>
										</Form.Group>}
								</div>
							</Card>
						</Col>

						<Col md={3}>
							<Card className='p-4 d-flex flex-row justify-content-between align-items-center'>
								<div className='w-100'>
									<h4>Delivery Date</h4>
									{!modifying ? moment(fundraiser.deliveryDate).format('DD MMM, YYYY') :
										<Form.Group>
											<Form.Control type='date' value={values.deliveryDate} name='deliveryDate'
														  onChange={handleChange}
														  isInvalid={!!errors.deliveryDate}/>
											<Form.Control.Feedback type='invalid'>{errors.deliveryDate}</Form.Control.Feedback>
										</Form.Group>}
								</div>
							</Card>
						</Col>
					</Row>
					{ modifying ? <div className='w-100 d-flex flex-row justify-content-end gap-2 mt-2'>
						<Button variant='danger' className='w-20' onClick={() => { setModifying(false); resetForm(); }}>Cancel</Button>
						<LoadingButtonComponent className='w-20' loading={isSubmitting} variant='primary' type='submit'>Save</LoadingButtonComponent>
					</div> : null }
				</Form>}
		</Formik>
	);
}