import Form from 'react-bootstrap/Form';
import {FieldArray, FormikErrors, FormikTouched} from 'formik';
import {ArrayHelpers} from 'formik/dist/FieldArray';
import {Button, InputGroup} from 'react-bootstrap';
import {ICatalogAutoAssignConfig} from '../../../types/CatalogTypes.ts';
import {ChangeEvent} from 'react';

interface IProps {
	index: number;
	type: string;
	config: ICatalogAutoAssignConfig;
	handleChange: (e: ChangeEvent<any>) => any;
	errors?: FormikErrors<ICatalogAutoAssignConfig>[] | string[] | string;
	touched?: FormikTouched<ICatalogAutoAssignConfig>[] | string[] | string;
}


export function CatalogAutoAssignValuesComponent({ index: configIndex, config, handleChange, type }: IProps) {
	function generateSelect(value: string, index: number) {
		const regions = ['Auckland', 'Bay of Plenty', 'Canterbury', 'Gisborne', 'Hawke\'s Bay', 'Manawatu-Wanganui', 'Marlborough', 'Nelson', 'Northland', 'Otago', 'Southland', 'Taranaki', 'Tasman', 'Waikato', 'Wellington', 'West Coast']
			.filter(x => !config.values.includes(x));
		
		return <Form.Select value={value} name={`autoAssignConfig[${configIndex}].values[${index}]`} onChange={handleChange}>
			<option value={value}>{ value ? value : 'Select an Option' }</option>
			{ regions.map(region => <option key={region} value={region}>{region}</option>) }
		</Form.Select>
	}
	
	return (
		<>
			<Form.Label>Options</Form.Label>
			<FieldArray name={`autoAssignConfig[${configIndex}].values`} render={(valuesHelpers: ArrayHelpers) => <>
				{config.values.map((value, index) =>
					<InputGroup key={`configValue${index}`} className='mb-2'>
						{ type === 'region' ? generateSelect(value, index) :  <Form.Control name={`autoAssignConfig[${configIndex}].values[${index}]`} value={value} onChange={handleChange} placeholder='A place name...' /> }
						<Button variant='outline-danger' onClick={() => valuesHelpers.remove(index)} disabled={config.values.length <= 1}>Remove</Button>
					</InputGroup>
				)}

				<Button onClick={() => valuesHelpers.push<string>('')}>Add Option</Button>
			</>} />
		</>
	)
}