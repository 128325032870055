import {Outlet, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import AuthenticationService from "../../services/AuthenticationService.ts";
import {FundraiserNavigationComponent} from "../../components/fundraiser/FundraiserNavigationComponent.tsx";

export function ManageFundraiserAuthCheck() {
	const navigate = useNavigate();
	const [ready, setReady] = useState(false);

	useEffect(() => {
		if (!AuthenticationService.hasValidFundraiserToken()) {
			navigate('/fundraiser/login');
			return;
		}

		setReady(true);
	}, []);

	return ready ? <><FundraiserNavigationComponent /><Outlet /></> : null;
}