import {apiAdminRefreshToken, apiFundraiserRefreshToken} from "../api.ts";

export interface ITokenData {
    token: string;
    expires: Date;
}

class AuthenticationService {
    private _adminTokenData?: ITokenData;
    private _fundraiserTokenData?: ITokenData;
    
    constructor() {
        const currentDate = new Date();
        const adminTokenString = localStorage.getItem('adminToken');
        if (adminTokenString) {
            const adminToken = JSON.parse(adminTokenString);
            this._adminTokenData = { token: adminToken.token, expires: new Date(adminToken.expires) };
            if (this._adminTokenData.expires < currentDate) {
                this._adminTokenData = undefined;
                localStorage.removeItem('adminToken');
            }
        }

        const fundraiserTokenString = localStorage.getItem('fundraiserToken');
        if (fundraiserTokenString) {
            const fundraiserToken = JSON.parse(fundraiserTokenString);
            this._fundraiserTokenData = { token: fundraiserToken.token, expires: new Date(fundraiserToken.expires) };
            if (this._fundraiserTokenData.expires < currentDate) {
                this._fundraiserTokenData = undefined;
                localStorage.removeItem('fundraiserToken');
            }
        }
        
        this.checkTokenExpiry();
        setInterval(this.checkTokenExpiry.bind(this), 60000);
    }
    
    checkTokenExpiry() {
        const now = Date.now();
        if (this._adminTokenData) {
            const diff = this._adminTokenData.expires.getTime() - now;
            if (diff <= 0) {
                this.logoutAdmin();
            } else if (diff < 300000) {
                apiAdminRefreshToken().then(response => {
                    this.storeAdminToken(response);
                }).catch(() => {
                    this.logoutAdmin();
                })
            }
        }
        
        if (this._fundraiserTokenData) {
            const diff = this._fundraiserTokenData.expires.getTime() - now;
            if (diff <= 0) {
                this.logoutFundraiser();
            } else if (diff < 300000) {
                apiFundraiserRefreshToken().then(response => {
                    this.storeFundraiserToken(response);
                }).catch(() => {
                    this.logoutFundraiser();
                })
            }
        }
    }
    
    storeAdminToken(data: ITokenData) {
        localStorage.setItem('adminToken', JSON.stringify(data));
        this._adminTokenData = data;
    }
    
    storeFundraiserToken(data: ITokenData) {
        localStorage.setItem('fundraiserToken', JSON.stringify(data));
        this._fundraiserTokenData = data;
    }
    
    hasValidAdminToken(): boolean {
        return this._adminTokenData !== undefined && this._adminTokenData.expires > new Date();
    }

    hasValidFundraiserToken(): boolean {
        return this._fundraiserTokenData !== undefined && this._fundraiserTokenData.expires > new Date();
    }
    
    getAdminToken(): string | undefined {
        return this._adminTokenData?.token
    }
    
    getFundraiserToken(): string | undefined {
        return this._fundraiserTokenData?.token;
    }
    
    logoutAdmin() {
        localStorage.removeItem('adminToken');
        this._adminTokenData = undefined;
    }

    logoutFundraiser() {
        localStorage.removeItem('fundraiserToken');
        this._fundraiserTokenData = undefined;
    }
}

export default new AuthenticationService();