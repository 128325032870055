import Form from 'react-bootstrap/Form';
import {Badge, Button, Col, Modal, Row} from 'react-bootstrap';
import {useEffect, useRef, useState} from 'react';
import {CreationStep1Component} from './CreationStep1Component.tsx';
import {CreationStep2Component} from './CreationStep2Component.tsx';
import {CreationStep3Component} from './CreationStep3Component.tsx';
import {ICatalog} from '../../types/CatalogTypes.ts';
import moment from 'moment';
import {formatAddress} from '../../helpers/formatAddress.ts';
import {IAdminFundraiserRequest, ISubmissionForm} from '../../routes/CreateFundraiserRequest.tsx';
import {apiCreateFundraisingRequest} from '../../api.ts';
import {IError} from '../../types/ApiTypes.ts';
import {toast} from 'react-toastify';
import {LoadingButtonComponent} from '../util/LoadingButtonComponent.tsx';
import {CreationAdminComponent} from './CreationAdminComponent.tsx';

interface IProps {
	catalogs: ICatalog[];
	request: ISubmissionForm;
	adminData?: IAdminFundraiserRequest;
	onStepComplete: (values: { [key: string]: any}) => any;
	onRequestCreated: () => any;
	onPrevious?: (values: { [key: string]: any}) => any;
	apiErrors?: IError[];
}

export interface ICreationStepRef {
	onUpdateClick: () => void
}

export function CreationOverviewComponent({ catalogs, request, onStepComplete, onRequestCreated, onPrevious, adminData, apiErrors }: IProps) {
	const [modifyStep, setModifyStep] = useState<number>();
	const [ errors, setErrors ] = useState<IError[]>();
	const [submitting, setSubmitting] = useState(false);
	const ref = useRef<ICreationStepRef>(null);
	
	useEffect(() => {
		setModifyStep(undefined);
	}, [request]);
	
	useEffect(() => {
		setErrors(apiErrors);
	}, [apiErrors]);
	
	function getModifyComponent() {
		switch (modifyStep) {
			case 1:
				return <CreationStep1Component onStepComplete={onStepComplete} values={request} ref={ref} update={true} />;
				
			case 2:
				return <CreationStep2Component catalogs={catalogs} onStepComplete={onStep2Complete} values={request} ref={ref} update={true} isAdmin={!!adminData} deliveryDate={request.deliveryDate} />
			
			case 3:
				return <CreationStep3Component onStepComplete={onStepComplete} values={request} ref={ref} update={true} />
			
			case 4:
				return <CreationAdminComponent onStepComplete={onStepComplete} values={adminData ? {...adminData, startDate: request.startDate, deliveryDate: request.deliveryDate} : undefined} ref={ref} catalogs={catalogs} />
		}
	}
	
	async function createRequest() {
		setSubmitting(true);
		
		try {
			const response = await apiCreateFundraisingRequest(request);
			if (!response.success) {
				setErrors(response.errors);
				if (response.message) {
					toast.error(response.message);
				} else {
					toast.error('Some issues were found with your request. Review your data and try again.')
				}
			} else {
				onRequestCreated();
			}
		}
		finally {
			setSubmitting(false);
		}
	}

	function onPreviousClick() {
		if (!onPrevious) {
			return;
		}
		
		onPrevious({});
	}
	
	function generateErrorFor(path: string) {
		const error = errors?.find(x => x.path === path);
		
		if (error) {
			return <Badge bg='danger'>{ error.msg }</Badge>
		}
	}
	
	function onStep2Complete(values: { [key: string]: any}) {
		if (values['catalog'] !== request.catalog && adminData) {
			values['items'] = [];
			values['customPricing'] = false;
		}
		
		onStepComplete(values);
	}
	
	const catalog = catalogs.find(x => x._id === request.catalog);
	
	return (
		<>
			<Modal show={!!modifyStep} size={modifyStep === 4 ? 'xl' : 'lg'}>
				<Modal.Body>{ getModifyComponent() }</Modal.Body>
				<Modal.Footer>
					<Button variant='danger' onClick={() => setModifyStep(undefined)}>Cancel</Button>
					<Button variant='success' onClick={() => ref.current?.onUpdateClick()}>Update</Button>
				</Modal.Footer>
			</Modal>
			<div className='d-flex flex-row justify-content-between'>
				<h4>Basic Info</h4>
				<Button variant='link' onClick={() => setModifyStep(1)}>Modify</Button>
			</div>
			
			<Form.Label className="mt-3 mb-0 font-weight-bold">Fundraiser Name</Form.Label>
			<small>{ request.name }</small>
			{ generateErrorFor('name') }

			<Form.Label className="mt-3 mb-0 font-weight-bold">Contact Name</Form.Label>
			<small>{ request.contactName }</small>
			{ generateErrorFor('contactName') }

			<Form.Label className="mt-3 mb-0 font-weight-bold">Contact Phone Number</Form.Label>
			<small>{ request.phoneNumber }</small>
			{ generateErrorFor('phoneNumber') }
			
			<Form.Label className="mt-3 mb-0 font-weight-bold">Contact Email</Form.Label>
			<small>{request.email}</small>
			{ generateErrorFor('email') }
			
			<Row>
				<Col md={6}>
					<Form.Label className="mt-3 mb-0 font-weight-bold d-block">Start Date</Form.Label>
					<small>{ moment(request.startDate).format('DD MMM, YYYY') }</small>
					{ generateErrorFor('startDate') }
				</Col>
				<Col md={6}>
					<Form.Label className="mt-3 mb-0 font-weight-bold d-block">Delivery Date</Form.Label>
					<small>{ moment(request.deliveryDate).format('DD MMM, YYYY') }</small>
					{ generateErrorFor('deliveryDate') }
				</Col>
			</Row>
			
			<hr />

			<div className='d-flex flex-row justify-content-between'>
				<h4>Fundraiser Order Info</h4>
				<Button variant='link' onClick={() => setModifyStep(2)}>Modify</Button>
			</div>

			{ catalog || adminData ? <> <Form.Label className="mt-3 mb-0 font-weight-bold">{adminData ? 'Catalog' : 'Location'}</Form.Label>
				<small>{ catalog?.name ?? 'No Catalog Set' }</small>
				<Form.Label className="mt-3 mb-0 font-weight-bold">Delivery Information</Form.Label>
				<small style={{ whiteSpace: 'pre-line' }}>{catalog?.deliveryInfo}</small>
			{ generateErrorFor('catalog') }
			</> : null }

			<Form.Label className="mt-3 mb-0 font-weight-bold">Pickups</Form.Label>
			{ request.pickups.map(x => <div key={x.startTime.toString() + x.endTime.toString()}>
				{x.location}: {moment(x.startTime).format('DD/MM/YYYY [at] HH:mm')} - {moment(x.endTime).format('DD/MM/YYYY [at] HH:mm')}
			</div>) }
			{ generateErrorFor('pickups') }

			<Row>
				<Col md={6}>
					<Form.Label className="mt-3 mb-0 font-weight-bold d-block">Bank Transfers</Form.Label>
					<small>{ request.transferPayment ? 'Accepted' : 'Not Accepted' }</small>
					{ generateErrorFor('transferPayment') }

					{ request.transferPayment ? <>
						<Form.Label className="mt-3 mb-0 font-weight-bold d-block">Account Number</Form.Label>
						<small>{ request.transferPaymentAccount }</small>
						{ generateErrorFor('transferPaymentAccount') }

						<Form.Label className="mt-3 mb-0 font-weight-bold d-block">Notes</Form.Label>
						<small>{ request.transferPaymentNotes }</small>
						{ generateErrorFor('transferPaymentNotes') }
					</> : null }
				</Col>
				<Col md={6}>
					<Form.Label className="mt-3 mb-0 font-weight-bold d-block">Cash Payments</Form.Label>
					<small>{ request.cashPayment ? 'Accepted' : 'Not Accepted' }</small>
					{ generateErrorFor('cashPayment') }

					{ request.cashPayment ? <>
						<Form.Label className="mt-3 mb-0 font-weight-bold d-block">Notes</Form.Label>
						<small>{ request.cashPaymentNotes }</small>
						{ generateErrorFor('cashPaymentNotes') }
					</> : null }
				</Col>
			</Row>
			<hr />
			<div className='d-flex flex-row justify-content-between'>
				<h4>Delivery Info</h4>
				<Button variant='link' onClick={() => setModifyStep(3)}>Modify</Button>
			</div>
			<Form.Label className="mt-3 mb-0 font-weight-bold">Delivery Address</Form.Label>
			<small style={{ whiteSpace: 'pre' }}>
				{ formatAddress(request) }
			</small>
			{ generateErrorFor('address') }

			{ adminData ? <>
				<hr />
				<div className='d-flex flex-row justify-content-between'>
					<h4>Fundraiser Configuration</h4>
					<Button variant='link' onClick={() => setModifyStep(4)}>Modify</Button>
				</div>
				<Form.Label className="mt-3 mb-0 font-weight-bold d-block">Commission</Form.Label>
				<small>{ (adminData.commission * 100).toFixed(0) }%</small>
				{ generateErrorFor('commission') }
				
				<Form.Label className="mt-3 mb-0 font-weight-bold d-block">Custom Pricing</Form.Label>
				<small>{ adminData.customPricing ? 'Enabled' : 'Disabled' }</small>

				{ adminData.customPricing ? <>
					<Form.Label className="mt-3 mb-0 font-weight-bold d-block">Custom Prices</Form.Label>
					{ adminData.items.map(item => <li key={item._id}>{item.name} - ${item.price.toFixed(2)}</li>) }
				</> : null }
				{ generateErrorFor('items') }

				<Form.Label className="mt-3 mb-0 font-weight-bold d-block">Fundraiser Close Date</Form.Label>
				<small>{ !adminData.endDate ? 'Not Set' : moment(adminData.endDate).format('DD/MM/YYYY') }</small>
				{ generateErrorFor('endDate') }
			</> : null }

			{ !adminData ? <div className='d-flex flex-row justify-content-between mt-3'>
				<Button variant='warning' onClick={onPreviousClick}>Previous</Button>
				<LoadingButtonComponent loading={submitting} variant='success' onClick={createRequest}>Create Request</LoadingButtonComponent>
			</div> : null }
		</>
	);
}