import {ICatalog, ICatalogAutoAssignConfig, ICatalogItem} from '../../../types/CatalogTypes.ts';
import {useNavigate, useParams} from 'react-router-dom';
import {apiAdminCreateCatalog, apiAdminGetCatalog, apiAdminUpdateCatalog, apiUrl} from '../../../api.ts';
import {IApiError, IApiSuccess} from '../../../types/ApiTypes.ts';
import {toast} from 'react-toastify';
import Form from 'react-bootstrap/Form';
import {useTitle} from '../../../hooks/useTitle.ts';
import {CatalogItemsConfigTableComponent} from '../../../components/admin/catalog/CatalogItemsConfigTableComponent.tsx';
import {Formik, FormikHelpers, FormikProps} from 'formik';
import {LoadingButtonComponent} from '../../../components/util/LoadingButtonComponent.tsx';
import * as yup from 'yup';
import {parseApiErrors} from '../../../helpers/parseApiErrors.ts';
import {useEffect, useRef, useState} from 'react';
import {LoadingComponent} from '../../../components/util/LoadingComponent.tsx';
import {CatalogAutoAssignConfigComponent} from '../../../components/admin/catalog/CatalogAutoAssignConfigComponent.tsx';
import {FileType, FileUploadComponent} from '../../../components/util/FileUploadComponent.tsx';
import {Button} from 'react-bootstrap';

interface IProps {
	creation: boolean;
}

interface IForm {
	id: string;
	name: string;
	items: ICatalogItem[];
	autoAssignEnabled: boolean;
	autoAssignConfig: ICatalogAutoAssignConfig[];
	deliveryInfo: string;
	pdf?: string;
}

export function AdminManageCatalog(props: IProps) {
	const { id } = useParams();
	const formRef = useRef<FormikProps<IForm>>(null);
	const [ loading, setLoading ] = useState(!props.creation);
	const [ catalog, setCatalog ] = useState<ICatalog>({
		_id: '',
		name: '',
		items: [],
		autoAssignEnabled: false,
		autoAssignConfig: [],
		deliveryInfo: '',
		priority: 0
	})
	
	useTitle('Manage Catalog');
	
	useEffect(() => {
		if (props.creation || !id) {
			return;
		}
		
		apiAdminGetCatalog(id).then(response => {
			if (!response.success) {
				const errors = parseApiErrors(response.errors);
				toast.error(errors.id ?? response.message ?? 'Failed to load catalog');
				navigate('/admin/catalogs');
			} else {
				setCatalog(response.data);
				setLoading(false);
			}
		})
	}, []);
	
	const navigate = useNavigate();
	
	async function onSubmit(values: IForm, {setErrors}: FormikHelpers<IForm>) {
		let response: IApiError | IApiSuccess;
		if (props.creation) {
			response = await apiAdminCreateCatalog(values);
		} else {
			response = await apiAdminUpdateCatalog(values);
		}

		if (response.success) {
			toast.success(`Catalog successfully ${props.creation ? 'created' : 'updated'}`);
			navigate('/admin/catalogs');
		} else {
			const errors = parseApiErrors(response.errors);
			
			setErrors(errors);
			
			if (response.message) {
				toast.error(response.message);
			}
		}
	}	
	
	if (loading) {
		return <LoadingComponent />
	}
	
	const defaultValues: IForm = {
		id: catalog._id,
		name: catalog.name,
		items: catalog.items,
		autoAssignEnabled: catalog.autoAssignEnabled,
		autoAssignConfig: catalog.autoAssignConfig,
		deliveryInfo: catalog.deliveryInfo,
		pdf: catalog.pdf
	};

	const schema = yup.object().shape({
		name: yup.string().required('A catalog name is required').min(5, 'Catalog names must be at least 5 characters'),
		items: yup.array().of(yup.object().shape({
			name: yup.string().required('Item name is required'),
			description: yup.string().required('Item description is required'),
			price: yup.number().required('Item price is required').min(0, 'Price cannot be less than 0'),
			image: yup.string().required('An item image is required'), //.url('Item image must be a URL')
			internalName: yup.string().required('An internal name is required')
		})),//.test('unique', 'Internal names must be unique', value => value ? value.find()),
		autoAssignEnabled: yup.bool().required(''),
		autoAssignConfig: yup.array().of(yup.object({
			field: yup.string().required('A field type is required').oneOf([ 'town', 'region' ], 'Must be town or region'),
			values: yup.array().of(yup.string())
		})),
		deliveryInfo: yup.string(),
		pdf: yup.string().optional()
	});
	
	function onFileUpload(url: string) {
		formRef.current?.setFieldValue('pdf', url);
	}
	
	function getPDFName(str: string) {		
		return str.replace('uploads/', '');
	}
	
	return (
		<>
		<div className="r-header">
			<h3>{ props.creation ? 'Create Catalog' : 'Manage Catalog' }</h3>
		</div>
		<div className="card p-4 mb-4">
			<Formik<IForm> initialValues={defaultValues} onSubmit={onSubmit} validationSchema={schema} innerRef={formRef}>
				{({handleSubmit, handleChange, values, errors, touched, isSubmitting, setFieldValue}) => <Form noValidate onSubmit={handleSubmit}>
					<Form.Group className='mb-2'>
						<Form.Label htmlFor='catalogName'>Catalog Name</Form.Label>
						<Form.Control id='catalogName' name='name' value={values.name} onChange={handleChange} isInvalid={touched.name && !!errors.name} />
						<Form.Control.Feedback type='invalid'>{ errors.name }</Form.Control.Feedback>
					</Form.Group>

					<CatalogItemsConfigTableComponent items={catalog.items} values={values.items} handleChange={handleChange} errors={errors.items} touched={touched.items} />
					<CatalogAutoAssignConfigComponent values={values.autoAssignConfig} handleChange={handleChange} errors={errors.autoAssignConfig} touched={touched.autoAssignConfig} enabled={values.autoAssignEnabled} />
					
					<Form.Group className='mt-4'>
						<Form.Label htmlFor='deliveryInfo'>Delivery Info</Form.Label>
						<Form.Control as='textarea' id='deliveryInfo' name='deliveryInfo' value={values.deliveryInfo} onChange={handleChange} isInvalid={touched.deliveryInfo && !!errors.deliveryInfo} />
						<Form.Control.Feedback type='invalid'>{errors.deliveryInfo}</Form.Control.Feedback>
						<Form.Text>Delivery information is shown to the fundraiser creator when making their request (if a catalog is selected for them), and when their fundraiser has been accepted.</Form.Text>
					</Form.Group>
					
					<Form.Group className='mt-4'>
						<Form.Label className='d-block'>PDF Flyer</Form.Label>
						
						{ values.pdf ? <div className='d-flex flex-row gap-2 align-items-center'>
							{ getPDFName(values.pdf) }
							<a href={`${apiUrl}${values.pdf}`} target='_blank' rel='noreferrer' className='btn btn-outline-primary'>View PDF</a>
							<Button variant='outline-danger' onClick={() => setFieldValue('pdf', undefined)}>Remove PDF</Button>
						</div> : <FileUploadComponent fileType={FileType.PDF} onFileUpload={onFileUpload} /> }
						
					</Form.Group>
					
					<LoadingButtonComponent loading={isSubmitting} type='submit' className='mt-4 w-10 float-end'>Save</LoadingButtonComponent>
				</Form>}
			</Formik>
		</div>
		</>
	)
}